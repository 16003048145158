import React from 'react';

import { Modal, Button } from 'semantic-ui-react';

import { Success } from '../';

const ContentTicketSubmitted = ({
  ticketId,
  handleGoToMyTickets,
  handleGoToMyTicketDetails,
  handleGoToTicketDetails,
  handleHideTicketCreateModal,
  isSparkUser,
  selectedClientId,
  corsicaId,
}) => (
  <>
    <Modal.Content className="submitted">
      <Success />
      <h3>Ticket {ticketId} Created Successfully!</h3>
      {isSparkUser ? (
        selectedClientId === corsicaId ? (
          <p>
            Your support request is being routed to a Corsica support team
            member. Click View Ticket below to view this ticket.
          </p>
        ) : (
          <p>
            Your internal ticket has been created successfully. Click View
            Ticket below to view and manage this ticket.
          </p>
        )
      ) : (
        <p>
          {
            'Your support request is being routed to a member of our support team. You can view updates and progress for your ticket in your '
          }
          <span className="simulated-link" onClick={handleGoToMyTickets}>
            My Tickets
          </span>
          {' view.'}
        </p>
      )}
    </Modal.Content>
    <Modal.Actions>
      <Button
        primary
        onClick={
          isSparkUser ? handleGoToTicketDetails : handleGoToMyTicketDetails
        }>
        View Ticket
      </Button>
      <Button className="ghost secondary" onClick={handleHideTicketCreateModal}>
        Close
      </Button>
    </Modal.Actions>
  </>
);

export default ContentTicketSubmitted;
