import React from 'react';
import { Card } from '../../components/';
import { colors } from '../../styles/utils';
import { roundPercent } from '../../utils/utils';
import {
  RadialBarChart,
  RadialBar,
  PolarAngleAxis,
  PolarRadiusAxis,
  Label,
} from 'recharts';

import './LifeCycleStat.scss';

const LifeCycleStat = props => {
  const {
    title,
    subtitle,
    data,
    color,
    description,
    width,
    height,
    isLoading,
    className,
  } = props;
  const fillColor = colors[color];

  return (
    <div className={`LifeCycleStat ${className ? className : ''}`}>
      <Card title={title} subtitle={subtitle} isLoading={isLoading}>
        <div className="chartContainer">
          <RadialBarChart
            width={width || 100}
            height={height || 100}
            startAngle={90}
            endAngle={450}
            data={[{ uv: data.active, fill: fillColor }]}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            innerRadius="60%"
            outerRadius="100%">
            <PolarAngleAxis
              type="number"
              domain={[0, data.total]}
              tick={false}
            />
            <PolarRadiusAxis tick={false} axisLine={false}>
              <Label
                position="center"
                offset={0}
                style={{ fill: fillColor, fontSize: '2em', fontWeight: 600 }}>
                {data.total && data.active
                  ? roundPercent((data.active / data.total) * 100) + '%'
                  : '0%'}
              </Label>
            </PolarRadiusAxis>
            <RadialBar dataKey="uv" background />
          </RadialBarChart>
        </div>
        <div className="textContainer">
          <span className="value">
            <em style={{ color: fillColor }}>{data.active}</em> /{data.total}{' '}
          </span>
          <span>{description}</span>
        </div>
      </Card>
    </div>
  );
};

export default LifeCycleStat;
