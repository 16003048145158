import React from 'react';
import { Button, Modal, Loader } from 'semantic-ui-react';
import format from 'date-fns/format';
import addMonths from 'date-fns/add_months';

const NotificationModal = ({
  names,
  handleCloseModal,
  handleContact,
  isVisible,
  isLoading,
  emailSent,
}) => (
  <Modal open={isVisible} className="eol-modal" onClose={handleCloseModal}>
    <Modal.Header>End of Support Notice</Modal.Header>
    {!isLoading && !emailSent && (
      <Modal.Content style={{ fontSize: '1.1rem' }}>
        <p>
          Microsoft stopped releasing critical security patches for{' '}
          {names.join(' and ')} on January 14, 2020.{' '}
          <strong>
            As a result, any device running one of these operating systems{' '}
            <span style={{ textDecoration: 'underline' }}>WILL NOT</span> be
            covered under your existing support contract and any support
            required will be billed on a T&M basis.
          </strong>
        </p>
        <h4>Here&apos;s what you need to know:</h4>
        <ol style={{ paddingLeft: '16px' }}>
          <li>
            Due to the large number of devices that are being refreshed
            world-wide, <strong>orders could take months to ship.</strong>
          </li>
          <li>
            Corsica is{' '}
            <strong>
              currently booked out until{' '}
              {format(addMonths(new Date(), 2), 'MMMM YYYY')}
            </strong>{' '}
            on PC refreshes.
          </li>
          <li>
            Any security incidents relating to one of the affected devices that
            occur after January 14, 2020{' '}
            <strong>will be billed on a T&M basis.</strong>
          </li>
        </ol>
        <p>
          We know this is a big deal. If you would like to discuss the best path
          forward with one of our consultants, please reach out at (260)
          432-1364 or click the link below to have someone from our team contact
          you.
        </p>
      </Modal.Content>
    )}
    {isLoading && (
      <Modal.Content style={{ fontSize: '1.1rem' }}>
        <Loader active />
      </Modal.Content>
    )}
    {emailSent && (
      <Modal.Content style={{ fontSize: '1.1rem' }}>
        <p>
          Thank you for your inquiry. Someone from our team will contact you
          shortly.
        </p>
      </Modal.Content>
    )}
    <Modal.Actions>
      {!isLoading && !emailSent && (
        <Button
          primary
          onClick={handleContact}
          content="Contact Me About This Topic"
        />
      )}
      {emailSent && (
        <Button primary onClick={handleCloseModal}>
          OK
        </Button>
      )}
    </Modal.Actions>
  </Modal>
);

export default NotificationModal;
