/* eslint-disable */
import React from 'react';

import { Icon, Modal, Button, Checkbox } from 'semantic-ui-react';
import { AppContext } from '../../AppContext';
import { environment } from '../../environment';
import { DevToolsAPI, Clients } from '../../api';
import { jwtDecode } from '../../utils/jwt';
import isEqual from 'lodash/isEqual';
import { getLocalKey, setLocalKey, localKeys } from '../../ManageCache';
import './DevTools.scss';

const defaultToken = {
  user_id: 1234,
  multi_account_access: false,
  active_features: [
    'active_directory',
    'compromises',
    'configuration_changes',
    'dashboard',
    'devices_overview',
    'dns_activity',
    'security_awareness_training',
    'endpoint_protection',
    'failed_logins',
    'internet_utilization',
    'my_tickets',
    'network_threats',
    'support_overview',
    'patching_v2',
    'reports',
    'security_remediations',
    'security_incidents',
    'technology_roadmap',
    'ticket_details',
    'tickets',
    'utilization',
    'vpn_activity',
    'vulnerabilities',
  ],
  upsell_features: [],
  imitate_client: false,
  security: 'verified',
  exp: 1990176818,
};

const base64url = source => {
  // Encode in classical base64
  let encodedSource = window.btoa(source);

  // Remove padding equal characters
  encodedSource = encodedSource.replace(/=+$/, '');

  // Replace characters according to base64url specifications
  encodedSource = encodedSource.replace(/\+/g, '-');
  encodedSource = encodedSource.replace(/\//g, '_');

  return encodedSource;
};

const createJWT = data => {
  const header = { alg: 'HS256', typ: 'JWT' };

  const stringifiedHeader = JSON.stringify(header);
  const encodedHeader = base64url(stringifiedHeader);

  const stringifiedData = JSON.stringify(data);
  const encodedData = base64url(stringifiedData);

  return `${encodedHeader}.${encodedData}`;
};

class DevTools extends React.Component {
  _isMounted = false;

  constructor() {
    super();

    this.state = {
      modalOpen: false,
      isShrunk: true,
      selectedFeatures: [],
      features: defaultToken.active_features,
      token: null,
      activeIndex: null,
      clientOptions: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetchClients();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetchClients = async () => {
    const res = await Clients.get();
    if (res.body) {
      this.safeSetState({
        clientOptions: res.body
          .map(c => {
            return (
              c.name &&
              !c.parent_id && // filter out anything with a parent id
              c.id && {
                text: c.name,
                value: c.id,
                key: c.id,
              }
            );
          })
          // filter out the nulls
          .filter(f => !!f),
      });
    }
  };

  getToken = () =>
    this.safeSetState({ token: jwtDecode(getLocalKey(localKeys.jwt_token)) });

  handleOpen = () =>
    this.safeSetState({ modalOpen: true }, () => this.getToken());
  handleClose = () => this.safeSetState({ modalOpen: false });
  shrink = () => this.safeSetState({ isShrunk: true });
  unShrink = () => this.safeSetState({ isShrunk: false });

  updateUser = opts =>
    DevToolsAPI.updateUser(opts).then(() => {
      this.props.setProfile({ ...this.props.userProfile, ...opts });
    });

  updateTokenProps = async newProps => {
    //const combinedProps = { ...defaultToken, ...newProps };
    const newToken = createJWT({
      ...defaultToken,
      ...newProps,
    });
    await this.updateToken(newToken);
  };

  updateToken = async newToken => {
    setLocalKey(localKeys.jwt_token, JSON.stringify(newToken));
    await DevToolsAPI.updateToken({
      token: newToken,
    });
  };

  handleUpdateToken = () => {
    this.updateToken(document.getElementById('tokenInput').value);
  };

  handleAccordionToggle = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.safeSetState({ activeIndex: newIndex });
  };

  handleTestAutoLogout = () => {
    this.props.testAutoLogout();
    this.handleClose();
  };

  render() {
    const {
      modalOpen,
      isShrunk,
      token,
      activeIndex,
      clientOptions,
    } = this.state;
    const { imitateClientId, userProfile } = this.props;
    return (
      <Modal
        size="small"
        open={modalOpen}
        onClose={this.handleClose}
        trigger={
          <div
            className={[
              'DevTools',
              imitateClientId && 'hidden',
              isShrunk && 'shrunkDevTools',
            ]
              .filter(f => !!f)
              .join(' ')}
            onClick={this.handleOpen}
            onMouseEnter={this.unShrink}
            onMouseLeave={this.shrink}>
            <Icon name="setting" />
            <span>Portal Demo Features</span>
          </div>
        }>
        <div className="DevToolsModal">
          <h3>Portal demo Features</h3>
          <Features updateToken={this.updateTokenProps} token={token} />
          {environment.current === 'e2eTesting' && (
            <Button onClick={this.handleTestAutoLogout}>
              Test Auto Logout
            </Button>
          )}
        </div>
      </Modal>
    );
  }
}

class Features extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFeatures: props?.token?.active_features || [],
      features: defaultToken.active_features,
      hasAnythingChanged: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.token, this.props.token)) {
      this.setState({ selectedFeatures: this.props.token.active_features });
    }
  }

  featureSelect = feature =>
    this.setState(
      {
        selectedFeatures: [...this.state.selectedFeatures, feature],
        hasAnythingChanged: true,
      },
      () =>
        this.props.updateToken({ active_features: this.state.selectedFeatures })
    );

  featureDeselect = feature =>
    this.setState(
      {
        hasAnythingChanged: true,
        selectedFeatures: [
          ...this.state.selectedFeatures.filter(f => f !== feature),
        ],
      },
      () =>
        this.props.updateToken({ active_features: this.state.selectedFeatures })
    );

  handleAddFeature = () =>
    this.featureAdd(document.getElementById('addFeature').value);
  featureAdd = feature =>
    this.setState(
      {
        selectedFeatures: [...this.state.selectedFeatures, feature],
        features: [...this.state.features, feature],
      },
      () =>
        this.props.updateToken({ active_features: this.state.selectedFeatures })
    );

  enableAll = () =>
    this.setState(
      { hasAnythingChanged: true, selectedFeatures: [...this.state.features] },
      () =>
        this.props.updateToken({ active_features: this.state.selectedFeatures })
    );

  disableAll = () =>
    this.setState({ hasAnythingChanged: true, selectedFeatures: [] }, () =>
      this.props.updateToken({ active_features: this.state.selectedFeatures })
    );

  applyChanges = () => (window.location.href = window.location.href);

  render() {
    const { features, selectedFeatures, hasAnythingChanged } = this.state;

    return (
      <div className="Features">
        <Button onClick={this.enableAll}>Enable All</Button>
        <Button onClick={this.disableAll}>Disable All</Button>
        {environment.current === 'e2eTesting' && (
          <label>
            Add Feature
            <input id="addFeature" />
            <Button onClick={this.handleAddFeature}>Add Feature</Button>
          </label>
        )}
        <ul>
          {features &&
            features.map((feature, i) => (
              <li
                key={`feature-${i}`}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingTop: '8px',
                }}>
                {feature}
                <Checkbox
                  toggle
                  checked={!!selectedFeatures.includes(feature)}
                  onChange={() => {
                    selectedFeatures.includes(feature)
                      ? this.featureDeselect(feature)
                      : this.featureSelect(feature);
                  }}
                />
              </li>
            ))}
        </ul>
        <Button disabled={!hasAnythingChanged} onClick={this.applyChanges}>
          Apply Changes
        </Button>
      </div>
    );
  }
}

const WrappedDevTools = props => (
  <AppContext.Consumer>
    {({ userProfile, imitateClientId, setProfile }) => (
      <DevTools
        {...props}
        setProfile={setProfile}
        imitateClientId={imitateClientId}
        userProfile={userProfile}
      />
    )}
  </AppContext.Consumer>
);

export default WrappedDevTools;
