import superagent from 'superagent';
import { API_ROOT, getToken } from './api';

const tokenPlugin = req => {
  const token = getToken();
  if (token) {
    req.set('Authorization', `Bearer ${token}`);
  }
};
const post = url => superagent.post(`${API_ROOT}${url}`).use(tokenPlugin);
const put = url => superagent.put(`${API_ROOT}${url}`).use(tokenPlugin);
const postWithoutToken = url => superagent.post(`${API_ROOT}${url}`);

/**********************************************************************
 * Analytics
 **********************************************************************/
export const Analytics = {
  /**
   * POST
   * Creates a new usage session, PRE-login
   * returns the session id that will be used in the postLogin route to finalize adding the session
   */
  preLogin: payload =>
    postWithoutToken('/v1/usage_stats/create_session').send(payload),
  /**
   * PUT
   * Updates a new usage session, POST-login
   */
  postLogin: payload => put('/v1/usage_stats/update_session').send(payload),
  /**
   * POST
   * Creates a new usage session
   * body: {app_version, app_environment, start_time, end_time, ip_address, user_agent, viewport_size, user_id}
   */
  createSession: payload =>
    post('/v1/usage_stats/create_session').send(payload),

  /**
   * POST
   * Update the end time for a usage session
   */
  sessionEnd: payload => post(`/v1/usage_stats/end_time`).send(payload),

  /**
   * POST
   * Creates a new usage event
   * body: {current_view, event_time, event_action, event_detail, session_id}
   */
  createEvent: payload => post('/v1/usage_stats/event').send(payload),

  /**
   * POST
   * Creates a new windowClosed event
   * required body: { session_id, event_time }
   */
  windowClosed: payload => post('/v1/usage_stats/window_closed').send(payload),

  /**
   * Returns JSON of user's location based on IP requested with
   * NOTE: this request does **not** use the standard API base URL.
   */
  geo: () => superagent.get('https://geoip.app.splx.co/json/'),
};
