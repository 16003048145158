import React from 'react';

import './NotificationContentRow.scss';

export const NotificationContentRow = ({ message, color, ...otherProps }) => {
  return (
    <div {...otherProps} className={`Notification ${color}`}>
      {message}
    </div>
  );
};

export default NotificationContentRow;
