import React, { Component } from 'react';

import { CSAT, ErrorHandler } from '../../api';
import { AppContext } from '../../AppContext';

import { Card, Emoji } from '../';
import { Button, Checkbox, Icon, Popup } from 'semantic-ui-react';

import './CSATFeedback.scss';

const QuoteMarks = `${process.env.REACT_APP_CDN_URL}/assets/images/quote-mark.svg`;

class CSATFeedback extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    const selected =
      props.selected &&
      ['great', 'acceptable', 'bad', 'good'].includes(props.selected)
        ? props.selected
        : null;

    this.state = {
      selected,
      csatComment: props.csatComment,
      isUpdating: false,
      isEditing: false,
      isLoadingCSAT: false,
      marketingConsent: this.props.marketingConsent,
      source: 'portal',
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  handleSubmit = async () => {
    this.safeSetState({ isLoadingCSAT: true });

    const {
      isUpdating,
      selected,
      csatComment,
      source,
      marketingConsent,
    } = this.state;

    const action = isUpdating ? CSAT.update : CSAT.create;

    let res;
    try {
      res = await action({
        csatName: selected,
        csatComment,
        csatId: this.props.csatId,
        ticketId: this.props.ticketId,
        contactId: this.props.contactId,
        accountId: this.props.accountId,
        marketingConsent,
        source,
      });
    } catch (err) {
      ErrorHandler.error(err);
      this.props.showErrorToast(
        'Your feedback could not be sent at this time. Please try again.'
      );
    }
    if (res) {
      this.props.showSuccessToast('Your feedback has been received');
    }
    this.safeSetState(
      {
        isLoadingCSAT: false,
      },
      () => this.props.reload()
    );
  };

  handleConsentCheckbox = () => {
    this.setState(prevState => ({
      marketingConsent: !prevState.marketingConsent,
    }));
  };

  handleUpdateSelection = selected => {
    if (this.props.canModify) {
      this.setState({ selected, isEditing: true });
    }
  };
  handleNoteChange = e => this.setState({ csatComment: e.target.value });

  handleCancel = () =>
    this.setState({
      selected: this.props.selected,
      csatComment: this.props.csatComment,
      isEditing: false,
      isUpdating: false,
      marketingConsent: this.props.marketingConsent,
    });

  handleChangeFeedback = () =>
    this.setState({ isEditing: true, isUpdating: true });

  render() {
    const {
      selected,
      csatComment,
      isEditing,
      isUpdating,
      isLoadingCSAT,
      marketingConsent,
    } = this.state;
    const { canModify } = this.props;

    // if nothing is selected, or some incorrect value is selected, AND the user can't modify it, then dont show it
    const hideEverything = !canModify && !selected;

    let heading = '';
    let subHeading = '';
    const isNoFeedback = !selected;
    const isRatingSelected = !isNoFeedback;
    const isNotQuote = isEditing || isNoFeedback;

    if (isNotQuote) {
      if (isUpdating) {
        heading = 'Update Ticket Rating & Feedback';
        subHeading =
          'You have already provided a rating. Update your rating or feedback below if you would like to change it.';
      } else {
        heading = 'How did we do on this ticket?';
        subHeading =
          'Click an icon below to let us know how you feel about the services you received.';
      }
    } else {
      heading = 'Thank you for your feedback!';
    }

    return hideEverything ? null : (
      <div className="CSATFeedback">
        {!isNotQuote && <img className="quote-marks" src={QuoteMarks} alt="" />}
        <Card title={heading}>
          {isEditing || isNoFeedback ? (
            <div className="feedback-container">
              <p>{subHeading}</p>
              <div className="csat-icon-options">
                <Emoji
                  withHeading
                  selected={selected === 'great'}
                  rating="great"
                  handleUpdateSelection={this.handleUpdateSelection}
                />
                <Emoji
                  withHeading
                  selected={selected === 'acceptable' || selected === 'good'}
                  rating={selected === 'acceptable' ? 'acceptable' : 'good'}
                  handleUpdateSelection={this.handleUpdateSelection}
                />
                <Emoji
                  withHeading
                  selected={selected === 'bad'}
                  rating="bad"
                  handleUpdateSelection={this.handleUpdateSelection}
                />
              </div>
              {isRatingSelected && (
                <>
                  <textarea
                    className="csat-comment"
                    value={csatComment}
                    onChange={this.handleNoteChange}
                    rows="5"
                    placeholder="Additional comments or feedback..."
                  />
                  <span>
                    <Checkbox
                      className="csat-consent-checkbox"
                      label="Allow use in marketing materials."
                      checked={marketingConsent}
                      onChange={this.handleConsentCheckbox}
                    />
                    <Popup
                      content={
                        'I give Corsica Technologies permission to use my name and feedback in public marketing materials.'
                      }
                      position="top right"
                      trigger={
                        <Icon name="info circle" style={{ opacity: '.5' }} />
                      }
                    />
                  </span>
                  <div className="controls">
                    <Button
                      primary
                      onClick={this.handleSubmit}
                      loading={isLoadingCSAT}>
                      {isUpdating ? 'Update Feedback' : 'Send Feedback'}
                    </Button>
                    <Button
                      secondary
                      className="ghost"
                      onClick={this.handleCancel}>
                      Cancel
                    </Button>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="static-comment">
              <Emoji withHeading selected rating={selected} />
              <p>
                {csatComment ? (
                  csatComment
                ) : (
                  <span className="soft">No feedback comments provided.</span>
                )}
              </p>
            </div>
          )}
        </Card>
        {canModify && !isEditing && !isNoFeedback && (
          <div className="change-feedback">
            <button className="link" onClick={this.handleChangeFeedback}>
              Want to change your feedback? Click Here
            </button>
          </div>
        )}
      </div>
    );
  }
}

const WrappedCSATFeedback = props => (
  <AppContext.Consumer>
    {({ addToast, userProfile }) => (
      <CSATFeedback
        {...props}
        contactId={userProfile?.external_details?.connectwise?.contact_id}
        accountId={userProfile?.external_details?.connectwise?.account_id}
        showSuccessToast={msg => addToast({ type: 'success', message: msg })}
        showErrorToast={msg => addToast({ type: 'error', message: msg })}
      />
    )}
  </AppContext.Consumer>
);

export default WrappedCSATFeedback;
