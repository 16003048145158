import React, { useState, useEffect } from 'react';
import { Transition, Icon } from 'semantic-ui-react';
import { Admin, usePromise } from '../../api';
import { AppContext } from '../../AppContext';
import { isBefore } from 'date-fns';

import './AutomatedNotification.scss';

const transitionTime = 500;

const AutomatedNotification = ({ selectedCustomer }) => {
  const { response } = usePromise(Admin.notifications);
  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
    const userRegion = String(selectedCustomer?.region).toLowerCase();
    const today = new Date();
    const data = response?.body || [];

    // filter the notifications to any that actually apply to this user
    const filteredData = data.filter(note => {
      // IF there are regions listed, they must match, else it applies.
      if (
        note.regions?.length !== 0 &&
        !note.regions.map(r => String(r).toLowerCase()).includes(userRegion)
      )
        return false;

      // IF there is a start date, it must be after that start date to apply
      if (note.start && !isBefore(note.start, today)) return false;

      // IF there is an end date, it must be before that end date to apply
      if (note.end && !isBefore(today, note.end)) return false;

      return true;
    });

    const shouldShow = filteredData.length > 0;

    setDisplayData(filteredData);
    setIsVisible(shouldShow);
  }, [response, selectedCustomer]);

  const [isVisible, setIsVisible] = useState(false);

  const handleHideNotification = () => {
    setIsVisible(false);
  };

  return (
    <Transition
      visible={isVisible}
      animation="fade"
      unmountOnHide={true}
      duration={transitionTime}>
      <div className="AutomatedNotification info-lite">
        <div className="wrapper">
          <div className="icon-box">
            <Icon name="info" />
            <Icon name="circle" />
          </div>
          {displayData.length === 1 && <p>{displayData[0].message}</p>}
          {displayData.length > 1 && (
            <ul>
              {displayData.map((note, i) => (
                <li key={i}>
                  <p>{displayData[i].message}</p>
                </li>
              ))}
            </ul>
          )}
          <button className="close-btn" onClick={handleHideNotification}>
            <Icon name="close" />
          </button>
        </div>
      </div>
    </Transition>
  );
};

const WrappedNotification = props => (
  <AppContext.Consumer>
    {({ selectedCustomer }) => (
      <AutomatedNotification {...props} selectedCustomer={selectedCustomer} />
    )}
  </AppContext.Consumer>
);

export default WrappedNotification;
