import React, { Component } from 'react';
import { history } from './store';
import {
  Auth,
  checkIsVerified,
  ErrorHandler,
  setImitateClientIdAPI,
} from './api';
import {
  updateSession,
  createSession,
  endSession,
  createEvent,
  eventTypes,
} from './utils/';
import { bugsnagClient } from './utils/bugsnag';
import mixpanel from './utils/mixpanel';
import { environment } from './environment';
import {
  clearAuthCache,
  getLocalKey,
  getSessionKey,
  localKeys,
  sessionKeys,
} from './ManageCache';

const defaultState = {
  userProfile: null,
  isSecureLogin: false,
  selectedCustomer: {},
  redirectTo: null,
  postLoginRedirectUrl: '/dashboard',
  secureAuthRequested: false,
  secureLoginFailed: false,
  secureLoginSuccess: false,
  secureLoginFailedMessage: '',
  bannerIsVisible: false,
  bannerMessage: '',
  bannerType: null,
  bannerDelay: null,
  isPending: false,
  errorMessage: null,
  notificationIsVisible: false,
  eolWindowsCount: 0,
  eolServerCount: 0,
  toasts: [],
  searchInput: {},
  imitateClientId: null,
  userTableReloadKey: 1,
  eventTypes,
  ErrorHandler,
  createEvent,
  isCorsicaUser: false,
  isViewAsClient: false,
};

const AppContext = React.createContext({
  ...defaultState,
  /* Actions */
  setLoginSecureRequested: () => {},
  setLoginSecureFail: () => {},
  setLoginSecureCancel: () => {},
  setLoginSecureSuccess: () => {},
  setUserProfile: () => {},
  setProfile: () => {},
  setProfileWithCallback: () => {},
  setCustomer: () => {},
  setRedirect: () => {},
  setPostLoginUrl: () => {},
  redirectThenClear: () => {},
  clearRedirect: () => {},
  showBanner: () => {},
  hideBanner: () => {},
  timeout: () => {},
  logout: () => {},
  setLoginErrorMessage: () => {},
  setLoginPending: () => {},
  setLoginSuccess: () => {},
  setLoginFail: () => {},
  showNotification: () => {},
  hideNotification: () => {},
  addToast: () => {},
  removeToast: () => {},
  redirectToLoginWithRedirectAfter: () => {},
  login: () => {},
  loginWithoutRedirect: () => {},
  confirmMFA: () => {},
  confirmMFAWithRedirect: () => {},
  generateSMSToken: () => {},
  loginSecure: () => {},
  redirectToLoginWithError: () => {},
  getUserProfile: () => {},
  searchInputFunction: () => {},
  setImitateClientId: () => {},
  dispatchAndStoreProfile: () => {},
  setUserTableReloadKey: () => {},
  isAuthorized: () => {},
  isUpsell: () => {},
});

class WithAppContext extends Component {
  toastId = 0;

  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
      setLoginSecureRequested: this.setLoginSecureRequested,
      setLoginSecureFail: this.setLoginSecureFail,
      setLoginSecureCancel: this.setLoginSecureCancel,
      setLoginSecureSuccess: this.setLoginSecureSuccess,
      setUserProfile: this.setUserProfile,
      setProfile: this.setProfile,
      setProfileWithCallback: this.setProfileWithCallback,
      setCustomer: this.setCustomer,
      setRedirect: this.setRedirect,
      setPostLoginUrl: this.setPostLoginUrl,
      redirectThenClear: this.redirectThenClear,
      clearRedirect: this.clearRedirect,
      showBanner: this.showBanner,
      hideBanner: this.hideBanner,
      timeout: this.timeout,
      logout: this.logout,
      setLoginErrorMessage: this.setLoginErrorMessage,
      setLoginPending: this.setLoginPending,
      setLoginSuccess: this.setLoginSuccess,
      setLoginFail: this.setLoginFail,
      showNotification: this.showNotification,
      hideNotification: this.hideNotification,
      addToast: this.addToast,
      removeToast: this.removeToast,
      redirectToLoginWithRedirectAfter: this.redirectToLoginWithRedirectAfter,
      login: this.login,
      loginWithoutRedirect: this.loginWithoutRedirect,
      confirmMFA: this.confirmMFA,
      confirmMFAWithRedirect: this.confirmMFAWithRedirect,
      generateSMSToken: this.generateSMSToken,
      loginSecure: this.loginSecure,
      redirectToLoginWithError: this.redirectToLoginWithError,
      getUserProfile: this.getUserProfile,
      searchInputFunction: this.searchInputFunction,
      setImitateClientId: this.setImitateClientId,
      dispatchAndStoreProfile: this.dispatchAndStoreProfile,
      setUserTableReloadKey: this.setUserTableReloadKey,
      isAuthorized: this.isAuthorized,
      isUpsell: this.isUpsell,
    };
  }

  isAuthorized = feature => {
    const { selectedCustomer } = this.state || {};
    const { active_features } = selectedCustomer || {};
    return !!active_features && !!active_features.find(f => f.name === feature);
  };

  isUpsell = feature => {
    const { selectedCustomer } = this.state || {};
    const { active_features } = selectedCustomer || {};
    const featureDetails =
      active_features && active_features.find(f => f.name === feature);
    return !!featureDetails && !!featureDetails.isUpsell;
  };

  setLoginSecureRequested = () => this.setState({ secureAuthRequested: true });

  setLoginSecureFail = msg =>
    this.setState({ secureLoginFailed: true, secureLoginFailedMessage: msg });

  setLoginSecureCancel = () => this.setState({ secureAuthRequested: false });
  setLoginSecureSuccess = () =>
    this.setState({
      secureAuthRequested: false,
      isSecureLogin: true,
      secureLoginSuccess: true,
      secureLoginFailed: false,
    });

  fetchUserProfile = async () => {
    let res;
    try {
      res = await Auth.refreshToken();
    } catch (err) {
      return null;
    }
    return res?.body;
  };

  getUserProfile = async () => {
    this.setState({ isPendingProfile: true });

    let _userProfile = this.state.userProfile || null;

    if (!_userProfile) {
      _userProfile = await this.fetchUserProfile();
    }

    if (_userProfile) {
      window.localStorage.setItem('userProfile', JSON.stringify(_userProfile));
      this.setState({
        userProfile: _userProfile,
        isPendingProfile: false,
        isCorsicaUser:
          _userProfile.client_id === environment.current.corsicaCustomerId,
      });
    } else {
      // user profile is undefined, logout user
      endSession();
      clearAuthCache();
      Auth.logout();
      history.replace('/login', {
        message: 'An error has occurred. Please login again.',
      });
      this.setState({ isPendingProfile: false });
    }

    return _userProfile;
  };

  setUserProfile = profile => {
    this.setState({ userProfile: profile });
  };

  setProfile = profile => {
    this.setProfileWithCallback(profile, () => null);
  };

  setProfileWithCallback = (profile, callback) => {
    const isCorsicaUser =
      profile.client_id === environment.current.corsicaCustomerId;

    mixpanel.identify(profile?.uuid);

    mixpanel.register_once({
      'First Login Date': new Date().toISOString(),
    });

    const userPofilePropertiesToInclude = [
      'active_in_org',
      'ad_user',
      'account_id',
      'contact_id',
      'is_primary_account_contact',
      'resource_id',
      'client_id',
      'client_name',
      'first_name',
      'id',
      'is_outside_contractor',
      'is_spark_user',
      'last_name',
      'last_sign_in',
      'mfa_type',
      'mobile_phone',
      'multi_account_access',
      'prodpad_contact_id',
      'role',
      'status',
    ];

    const userDetails = {};

    userPofilePropertiesToInclude.forEach(key => {
      userDetails[key] = profile[key] || null;
    });

    mixpanel.people.set(userDetails);

    // set "reserved" fields
    // see: https://help.mixpanel.com/hc/en-us/articles/115004708186-Profile-Properties#reserved-properties-for-user-profiles
    mixpanel.people.set({
      $email: profile?.email,
    });

    if (profile.client_name) {
      mixpanel.set_group('Client', profile.client_name);
    }
    mixpanel.set_group('Role', profile.role);

    this.setState({ userProfile: profile, isCorsicaUser }, () =>
      callback(this.state)
    );
  };

  setCustomer = customer => this.setState({ selectedCustomer: customer });

  setRedirect = path => this.setState({ redirectTo: path });

  setPostLoginUrl = path => this.setState({ postLoginRedirectUrl: path });

  redirectThenClear = path => {
    this.clearRedirect();
    history.push(path);
  };

  clearRedirect = () => this.setState({ redirectTo: null });

  showBanner = ({ message, type, delay }) =>
    this.setState({
      bannerIsVisible: true,
      bannerMessage: message,
      bannerType: type,
      bannerDelay: delay,
    });

  hideBanner = () =>
    this.setState({
      bannerIsVisible: false,
      bannerMessage: '',
      bannerType: null,
      bannerDelay: null,
    });

  timeout = () => {
    // Send logout event
    createEvent({
      route: window.location.pathname,
      action: eventTypes.TIME_OUT,
    });

    // Send endSession beacon
    endSession();
    clearAuthCache();
    Auth.logout();
  };

  logout = () => {
    // Send logout event
    createEvent({
      route: window.location.pathname,
      action: eventTypes.SIGN_OUT,
    });

    // Send endSession beacon
    endSession();
    clearAuthCache();

    Auth.logout();

    history.push('/login');
  };

  /* formerly "Login" reducer */

  setLoginErrorMessage = msg => this.setState({ errorMessage: msg });

  setLoginPending = path =>
    this.setState({
      errorMessage: null,
      isPending: true,
      redirectTo: path,
    });

  setLoginSuccess = () =>
    this.setState({
      isPending: false,
    });

  setLoginFail = response => {
    let errorMessage =
      'Something went wrong with your request. Please try again';
    if (response && response.status && response.status === 401) {
      errorMessage = 'Your username or password is incorrect.';
    }
    this.setState({
      isPending: false,
      errorMessage,
    });
  };

  /* formerly "Notification" reducer */
  // SHOW
  showNotification = ({ serverCount, windowsCount }) => {
    const temporarilyHidden = getSessionKey(sessionKeys.hide_eol_notification);
    const permanentlyHidden = getLocalKey(localKeys.has_requested_eol_contact);
    if (
      (temporarilyHidden && temporarilyHidden === 'true') ||
      (permanentlyHidden && permanentlyHidden === 'true')
    ) {
      // do nothing
    } else {
      this.setState({
        notificationIsVisible: true,
        eolServerCount: serverCount || 0,
        eolWindowsCount: windowsCount || 0,
      });
    }
  };

  // HIDE
  hideNotification = () =>
    this.setState({
      notificationIsVisible: false,
      eolServerCount: 0,
      eolWindowsCount: 0,
    });

  /* formerly "Toasts" reducer */
  addToast = ({ type = 'info', duration, message, id = ++this.toastId }) => {
    this.setState({
      toasts: [
        ...this.state.toasts,
        {
          type,
          duration: duration || type === 'success' ? 5000 : 8000,
          message,
          id,
        },
      ],
    });
  };

  removeToast = toastId =>
    this.setState({
      toasts: this.state.toasts.filter(t => t.id !== toastId),
    });

  /* formerly "Auth" actions */
  redirectToLoginWithRedirectAfter = postLoginRedirectUrl => {
    if (!postLoginRedirectUrl || postLoginRedirectUrl === '/login') {
      postLoginRedirectUrl = '/dashboard';
    }
    this.setPostLoginUrl(postLoginRedirectUrl);
    this.clearRedirect();
    history.push('/login');
  };

  dispatchAndStoreProfile = ({ userProfile, sessionId }) => {
    if (sessionId) {
      //Update usage session
      updateSession({ userId: userProfile.id, sessionId })
        .then(() => {
          // Send login event
          createEvent({
            route: window.location.pathname,
            action: eventTypes.SIGN_IN,
          });
        })
        .catch(err =>
          ErrorHandler.error(err, {
            title: 'dispatchAndStoreProfile::createSession',
            body: { userProfile },
          })
        );
    } else {
      // Create usage session
      createSession(userProfile.id)
        .then(() => {
          // Send login event
          createEvent({
            route: window.location.pathname,
            action: eventTypes.SIGN_IN,
          });
        })
        .catch(err =>
          ErrorHandler.error(err, {
            title: 'dispatchAndStoreProfile::createSession',
            body: { userProfile },
          })
        );
    }

    this.setLoginSuccess();
    this.setProfile(userProfile);
  };

  setBugsnagUser = userProfile => {
    bugsnagClient.user = {
      id: userProfile.id,
      uuid: userProfile.uuid,
      firstName: userProfile.first_name,
      lastName: userProfile.last_name,
      email: userProfile.email,
      customerId: userProfile.client_id,
      customerName: userProfile.client_name,
    };
  };

  login = (username, password, postLoginRedirect = '/dashboard') => {
    if (postLoginRedirect === '/login') {
      postLoginRedirect = '/dashboard';
    }

    this.setLoginPending();

    const sessionId = getSessionKey(sessionKeys.session_id);

    Auth.login(username, password).then(
      res => {
        const userProfile = res.body;
        if (
          userProfile?.role === 'user' &&
          postLoginRedirect === '/dashboard'
        ) {
          postLoginRedirect = '/my-tickets';
        }
        this.dispatchAndStoreProfile({ userProfile, sessionId });
        this.setBugsnagUser(userProfile);

        const mfaType = userProfile && userProfile.mfa_type;
        let methodLink;

        const isVerified = checkIsVerified();
        // If the user has verified=true, then skip the MFA process and go
        // wherever they were planning to go
        if (isVerified) {
          methodLink = postLoginRedirect;
        } else {
          // If the user was not verified, redirect to MFA process,
          // they will redirect at the end of that
          switch (mfaType) {
            case 'sms':
              methodLink = '/login/sms';
              break;
            case 'totp':
              methodLink = '/login/otp';
              break;
            default:
              // if its null, aka they dont have a type, send them to mfa setup
              methodLink = '/login/mfa-setup';
          }
        }

        this.setRedirect(methodLink);
      },
      err => {
        this.setLoginFail(err && err.response);
        if (err && err.response && err.response.status !== 401) {
          bugsnagClient.notify(err);
        }
      }
    );
  };

  loginWithoutRedirect = (username, password) => {
    this.setLoginPending();

    const sessionId = getSessionKey(sessionKeys.session_id);

    Auth.login(username, password).then(
      res => {
        const userProfile = res.body;
        this.dispatchAndStoreProfile({ userProfile, sessionId });
        this.setBugsnagUser(userProfile);
      },
      err => {
        this.setLoginFail(err && err.response);
        if (err && err.response && err.response.status !== 401) {
          bugsnagClient.notify(err);
        }
      }
    );
  };

  confirmMFA = (postLoginRedirect = '/dashboard', withRedirect = false) => {
    if (postLoginRedirect === '/login') {
      postLoginRedirect = '/dashboard';
    }
    Auth.refreshToken()
      .then(() => {
        if (withRedirect) {
          this.setRedirect(postLoginRedirect);
        }
      })
      .catch(err => bugsnagClient.notify(err));
  };

  confirmMFAWithRedirect = (postLoginRedirect = '/dashboard') => () =>
    this.confirmMFA(postLoginRedirect, true);

  generateSMSToken = async () => {
    // TODO:: NOT SURE ABOUT THIS ONE!!
    await Auth.secureLogin();
  };

  loginSecure = code => {
    Auth.secureLogin(code)
      .then(res => {
        if (res && res.body) {
          // login secure success
          this.setLoginSecureSuccess();
        }
      })
      .catch(err => {
        let errorMessage =
          'Your authentication request could not be completed at this time. Please try again later.';
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          errorMessage =
            'Your authentication code was incorrect. Please try again.';
        }
        this.setLoginSecureFail(errorMessage);
      });
  };

  redirectToLoginWithError = (msg, email) => {
    history.replace('/login', {
      message: msg,
      email,
    });
  };

  searchInputFunction = (searchTerm, tableName) => {
    this.setState(() => ({
      searchInput: {
        searchTerm: searchTerm,
        tableName: tableName,
      },
    }));
  };

  setImitateClientId = async clientId => {
    setImitateClientIdAPI(clientId);
    try {
      await Auth.refreshToken();
    } catch (err) {
      ErrorHandler.error(err);
    }
    this.setState({
      imitateClientId: clientId || null,
      isViewAsClient: !!clientId,
    });
  };

  // This is used to check to see if the user table needs to re-fetch data if the key changes
  // allows a disjoined distant child component to force the parent to re-fetch
  setUserTableReloadKey = id => this.setState({ userTableReloadKey: id });

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export { WithAppContext, AppContext };
