import React from 'react';
import { Modal, Message, Button } from 'semantic-ui-react';
import { FormInput, FormFileUpload, FormTextArea, FormSelect } from '../';

const ContentSparkCorsicaCreate = ({
  isError,
  title,
  isErrorSubject,
  description,
  isErrorIssue,
  isSubmitting,
  handleSubmit,
  handleSoftCancel,
  assignedTo,
  userProfile,
  corsicaId,
  selectedClientId,
  handleSelectClient,
  optionsAssignedTo,
  isLoadingAttributes,
  handleFieldChange,
  /* file upload fields */
  handleFiles,
  isUploading,
  uploadProgress,
  isErrorFiles,
}) => (
  <>
    <Modal.Header style={{ borderBottom: 'none' }}>
      <h2>Create New Support Ticket</h2>
    </Modal.Header>
    <Modal.Content>
      {isError && (
        <Message negative>There was an error creating the ticket</Message>
      )}
      <FormSelect
        style={{ width: '100%', marginBottom: '15px' }}
        label="Who will be working this ticket?"
        options={[
          { text: 'Corsica Technologies', value: corsicaId },
          { text: userProfile.client_name, value: userProfile.client_id },
        ]}
        value={selectedClientId}
        onChange={(e, opt) =>
          handleSelectClient({
            selectedClientId: opt?.value,
            selectedClientName: opt?.text,
            alsoUpdateState: true,
          })
        }
      />
      <FormSelect
        style={{ width: '100%', marginBottom: '15px' }}
        options={optionsAssignedTo.map(opt => ({
          text: opt.name,
          value: opt.contact_id,
        }))}
        label="Who is the contact for this ticket?"
        onChange={(e, opt) => handleFieldChange('assignedTo', opt?.value)}
        value={assignedTo}
        isLoading={isLoadingAttributes}
      />
      <FormInput
        style={{ width: '100%', marginBottom: '15px' }}
        label="Subject for ticket"
        onChange={e => handleFieldChange('title', e?.target?.value)}
        value={title}
        error={isErrorSubject}
        errorMessage={'A subject is required'}
        maxLength={100}
      />
      {!(title.length < 100) && (
        <span className="subject-length-warning">
          Please limit the subject to 100 characters.
        </span>
      )}
      <FormTextArea
        placeholder="Briefly describe the issue you are experiencing..."
        rows={10}
        onChange={e => handleFieldChange('description', e?.target?.value)}
        value={description}
        style={{ width: '100%', marginBottom: '15px' }}
        label="What can we help you with?"
        error={isErrorIssue}
      />
      <FormFileUpload
        multiple={true}
        style={{ width: '100%' }}
        onChange={handleFiles}
        label="Add screenshots if needed (JPG, PNG, PDF, CSV, DOC, DOCX, XLS, XLSX allowed - max file size 2MB)"
        isUploading={isUploading}
        uploadProgress={uploadProgress}
        error={isErrorFiles}
        errorMessage={'There was a problem uploading your file'}
      />
    </Modal.Content>
    <Modal.Actions>
      <Button
        primary
        loading={isSubmitting || isUploading}
        onClick={handleSubmit}
        disabled={!title || !description}>
        Create Ticket
      </Button>
      <Button className="ghost secondary" onClick={handleSoftCancel}>
        Cancel
      </Button>
    </Modal.Actions>
  </>
);

export default ContentSparkCorsicaCreate;
