import React from 'react';

import { Ticket, ErrorHandler } from '../../api';
import { Modal, Button } from 'semantic-ui-react';
import { FormTextArea } from '../';

export default class ContentEntry extends React.Component {
  _isMounted = false;
  state = {
    feedbackValue: '',
    loading: false,
  };

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  handleFeedbackChange = (e, { value }) => {
    this.safeSetState({ feedbackValue: value });
  };

  handlePost = () => {
    const { feedbackValue } = this.state;
    if (!feedbackValue) {
      return;
    }
    // render loading icon
    this.setState({
      loading: true,
    });
    const { userProfile } = this.props;

    const contactId = userProfile?.external_details?.connectwise?.contact_id;

    Ticket.createFeedback({
      feedback: feedbackValue,
      contactId,
    })
      .then(async res => {
        // go to the success page
        this.props.onGoToSuccess();
        this.setState({ loading: false });
      })
      .catch(err => {
        ErrorHandler.error(err);
      });
  };

  handleShowCreateTicketModal = () => {
    this.props.onClose();
    this.props.onGoToCreateTicket();
  };

  render() {
    const { feedbackValue } = this.state;
    const { firstName } = this.props;
    return (
      <Modal.Content className="ContentEntry">
        <h3>
          Hey {firstName}! How can we make the portal more useful for you?
        </h3>
        <p>
          Looking for help?&nbsp;
          <button
            className="link primary"
            onClick={this.handleShowCreateTicketModal}>
            Create a support ticket here.
          </button>
        </p>
        <FormTextArea
          //disabled={isSubmitting}
          style={{ width: '100%', marginBottom: '15px' }}
          rows={8}
          className="feedbackEntry"
          placeholder="It would be awesome if..."
          value={feedbackValue}
          onChange={this.handleFeedbackChange}
        />
        <div className="button-box">
          <Button
            className="primary"
            onClick={this.handlePost}
            disabled={!feedbackValue}
            loading={this.state.loading}>
            Submit Portal Feedback
          </Button>
          <Button basic onClick={this.props.onClose}>
            Cancel
          </Button>
        </div>
      </Modal.Content>
    );
  }
}
