import React from 'react';
import { Card } from '../../components';
import { formatPrecision } from '../../utils/utils';

import './WidgetDialGaugeGraph.scss';

export default function WidgetDialGaugeGraph({
  percent,
  className,
  graphSubtitle,
  nullMessage,
  ...otherProps
}) {
  let displayPercent = isNaN(percent) ? 0 : percent;
  displayPercent = displayPercent > 100 ? 100 : displayPercent;
  displayPercent = displayPercent < 0 ? 0 : displayPercent;

  const isNull = !percent && percent !== 0;

  // since we are only showing half the circle, and that area should be 100%
  // multiply the gap by 2
  const gap = (100 - displayPercent) / 2;

  return (
    <Card
      className={[className, 'WidgetDialGaugeGraph'].filter(f => !!f).join(' ')}
      isEmpty={isNull}
      emptyStateMessage={nullMessage}
      {...otherProps}>
      <div
        style={{
          height: '192px',
          width: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}>
        <div style={{ height: '375px', width: '100%', position: 'relative' }}>
          <svg width="100%" height="100%" viewBox="0 0 42 41" className="donut">
            <circle
              className="donut-hole"
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill="#fff"></circle>
            <circle
              className="donut-ring"
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill="transparent"
              stroke="#f5f5f5"
              strokeWidth="3"></circle>

            <circle
              className="donut-segment"
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill="transparent"
              stroke="#2b5597"
              strokeDasharray={`${100 - gap}`}
              strokeWidth="3"></circle>
          </svg>
        </div>
      </div>
      <div className="end-caps">
        <span>0%</span>
        <span>100%</span>
      </div>
      <div className="center-content">
        {/* <Emoji rating={percent >= 79.95 ? 'great' : 'average'} selected /> */}
        <div className="display-percent">
          {formatPrecision(displayPercent, 1, true)}%
        </div>
        <small>{graphSubtitle}</small>
      </div>
    </Card>
  );
}
