import React from 'react';

import { Button, Modal } from 'semantic-ui-react';

import './ScheduleHelpButton.scss';
const urlToLoad =
  'https://outlook.office365.com/owa/calendar/CorsicaCafe@corsicatech.com/bookings/';

export default function ScheduleHelpButton({
  userId,
  customerId,
  imitateClientId,
  ...otherProps
}) {
  const [open, setOpen] = React.useState(false);
  return (
    <Modal
      className="ScheduleHelpButton"
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="large"
      trigger={
        <Button primary inverted {...otherProps}>
          {'Schedule Help'}
        </Button>
      }>
      <Modal.Content>
        <iframe title="Schedule Help" src={urlToLoad}></iframe>
      </Modal.Content>
    </Modal>
  );
}
