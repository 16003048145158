import { history } from '../store';

/**
 * Usage:
 *
 * import params from './queryParams.js';
 *
 * <Link to={{ pathname: '/the/path', search: params.stringify({ your: 'query' }) }}>click me</Link>
 *
 * params.getAll();   // {your: 'params', returned: 'here}
 *
 * params.get('date') // {date: '4/12/2016'}
 */

class Params {
  /**
   * Accepts an array of objects or an object of queryparams, and returns a url safe encoded query string
   * @param {string of objects or object} params
   */
  stringify = params => {
    if (!params) {
      return '';
    }
    if (Array.isArray(params)) {
      params = Object.assign({}, ...params);
    }
    params = this.stripEmpties(params);
    return !this.isEmptyObject(params)
      ? `${Object.entries(params)
          .map(pair => `${pair[0]}=${encodeURIComponent(pair[1])}`)
          .join('&')}`
      : '';
  };

  isEmptyObject(obj) {
    return typeof obj === 'object' && Object.keys(obj).length === 0;
  }

  stripEmpties(obj) {
    let newObj = {};
    Object.keys(obj).forEach(o => {
      if (obj[o] !== null && obj[o] !== undefined && obj[o] !== '') {
        newObj[o] = obj[o];
      }
    });
    return newObj;
  }

  dateFiltersToParams = (dateFilters, currentParams) => {
    const params = currentParams || {};
    dateFilters.forEach(f => {
      const key = Object.keys(f)[0];
      params[key] = f[key];
    });
    return params;
  };

  filtersToParams = (filters, currentParams) => {
    const params = currentParams || {};
    filters.forEach(f => {
      if (params[f.name]) {
        // if there is already this category, just add the values
        params[f.name] += `|${f.values.join('|')}`;
      } else {
        params[f.name] = f.values.join('|');
      }
    });
    return params;
  };

  getAllAsFilters = () => {
    const qs = this.parse(history.location.search);
    return Object.keys(qs).map(k => {
      let values = qs[k];
      values = values.split('|').filter(f => !!f);
      return { name: k, values };
    });
  };

  getAll = () => {
    const qs = history.location.search;
    return this.parse(qs);
  };

  parse = search => {
    const params = {};
    if (search && search.length > 1) {
      let pairs = search.split('?');
      pairs = pairs.length > 1 ? pairs[1].split('&') : pairs[0].split('&');
      pairs.map(pair => {
        pair = pair.split('=');
        if (pair.length === 2) {
          return (params[pair[0]] = decodeURIComponent(pair[1]));
        } else {
          return (params[pair[0]] = '');
        }
      });
    }

    return params;
  };

  get = param => this.getAll()[param];

  set = search => history.replace({ search });

  setSingle = pair => {
    // accepts a key value pair, and adds it to the current set of params
    const currentParams = this.getAll();
    history.replace({
      search: `?${this.stringify({ ...currentParams, ...pair })}`,
    });
  };

  /* Get the page filters from the URL Query Params, form it similar to the previously used redux filters */
  getPageFilters = ({ queryParams, filterCategories }) => {
    const res = [];
    const dateFilters = [];
    filterCategories.forEach(fc => {
      const applicableFilter = queryParams[fc.name];
      if (applicableFilter) {
        res.push({ name: fc.name, values: applicableFilter.split('|') });
      }
    });
    const possibleDateFilters = ['date', 'startDate', 'endDate'];
    possibleDateFilters.forEach(df => {
      const dfv = queryParams[df];
      if (dfv) {
        dateFilters.push({ [df]: dfv });
      }
    });
    return { filters: res, dateFilters };
  };
}

export default new Params();
