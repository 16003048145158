import React from 'react';

import './Emoji.scss';

const imgurl = `${process.env.REACT_APP_CDN_URL}/assets/images`;

const CSATGreat = `${imgurl}/great_emoji.svg`;
const CSATAcceptable = `${imgurl}/acceptable_emoji.svg`;
const CSATGood = `${imgurl}/good_emoji.svg`;
const CSATBad = `${imgurl}/bad_emoji.svg`;

const BWCSATGreat = `${imgurl}/icon_csat_great_bwoutline.svg`;
const BWCSATGood = `${imgurl}/icon_csat_good_bwoutline.svg`;
const BWCSATAcceptable = `${imgurl}/icon_csat_acceptable_bwoutline.svg`;
const BWCSATBad = `${imgurl}/icon_csat_bad_bwoutline.svg`;

const csatLookup = csat => {
  let icon;
  let iconBw;
  let name;
  csat = csat ? csat.toLowerCase() : csat;
  switch (csat) {
    case 'great':
    case 'happy':
      icon = CSATGreat;
      iconBw = BWCSATGreat;
      name = 'Positive';
      break;

    case 'good':
      name = 'Neutral';
      icon = CSATGood;
      iconBw = BWCSATGood;
      break;

    case 'acceptable':
    case 'average':
    case 'neutral':
      icon = CSATAcceptable;
      iconBw = BWCSATAcceptable;
      name = 'Neutral';
      break;

    case 'bad':
    case 'negative':
    case 'sad':
    default:
      icon = CSATBad;
      iconBw = BWCSATBad;
      name = 'Negative';
  }

  return { icon, iconBw, name };
};

export default function Emoji({
  withHeading,
  selected,
  rating,
  handleUpdateSelection,
}) {
  const csatObj = csatLookup(rating);
  const canModify = !!handleUpdateSelection;
  return (
    <div
      className={['Emoji', canModify && 'can-modify']
        .filter(f => !!f)
        .join(' ')}
      onClick={() => (canModify ? handleUpdateSelection(rating) : null)}>
      {withHeading && <span>{rating}</span>}
      <img src={selected ? csatObj.icon : csatObj.iconBw} alt={csatObj.name} />
    </div>
  );
}
