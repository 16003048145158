import React, { PureComponent } from 'react';
import { AppContext } from '../AppContext';
import { ErrorHandler } from '../api';

import { Modal, Message, Icon, Form, Button, Loader } from 'semantic-ui-react';
import { FormInput } from './';

class ModalSecureAuth extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
      inputLoading: false,
      errorMessage: '',
      newCodeStatus: null,
    };
  }
  onCodeInput = value => {
    if (value.length === 6) {
      this.setState({
        inputLoading: true,
      });

      // submit
      this.props.submitCode(value);

      // then clear
      this.setState({ code: '' });
    } else {
      if (value) {
        this.setState({ code: value });
      } else {
        this.setState({ code: '' });
      }
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if we are in a loading state and failed, reset
    if (this.state.inputLoading && this.props.secureLoginFailed) {
      this.setState({
        inputLoading: false,
        code: '',
        errorMessage: this.props.errorMessage,
      });
    }
  }

  requestNewCode = async e => {
    this.setState({
      newCodeStatus: 'pending',
    });

    e.preventDefault();

    try {
      const response = await this.props.requestNewCode();

      if (response.status === 241 || response.status === 200) {
        this.setState({
          newCodeStatus: 'success',
        });
      }
    } catch (err) {
      ErrorHandler.error(err);
    }
  };

  handleCancel = () => {
    this.setState({ errorMessage: '', inputLoading: false, code: '' }, () => {
      this.props.handleCancel();
    });
  };

  render() {
    const { userPhone, secureAuthRequested } = this.props;
    const { newCodeStatus, errorMessage, inputLoading } = this.state;
    let lastFour =
      userPhone && userPhone.length >= 4 && userPhone.substr(-4, 4);

    return (
      <Modal size="tiny" open={secureAuthRequested}>
        <Modal.Header>
          This request requires additional authentication
        </Modal.Header>

        <Modal.Content style={{ minHeight: 195, textAlign: 'center' }}>
          {inputLoading ? (
            <Loader active />
          ) : (
            <div>
              <div style={{ display: 'block', textAlign: 'center' }}>
                {!errorMessage ? (
                  <Message compact info icon>
                    <Icon name="info circle" />
                    <Message.Content style={{ textAlign: 'left' }}>
                      A security code has been sent to your phone ending in{' '}
                      <strong className="monospace">{lastFour}</strong> via a
                      text message. To continue, please enter the security code
                      below.
                    </Message.Content>
                  </Message>
                ) : (
                  <Message compact error content={errorMessage} />
                )}
              </div>

              <Form method="POST">
                <FormInput
                  autocomplete="off"
                  maxLength="6"
                  value={this.state.code}
                  style={{ width: 100, textAlign: 'center', marginTop: '2rem' }}
                  onChange={e => this.onCodeInput(e.target.value)}
                />
              </Form>
            </div>
          )}
        </Modal.Content>

        <Modal.Actions style={{ textAlign: 'left' }}>
          <Button onClick={this.handleCancel}>Cancel</Button>
          <small style={{ marginLeft: '1em' }}>
            Didn't get our text?{' '}
            <strong>
              <button
                href=""
                onClick={this.requestNewCode}
                style={{
                  color: 'black',
                  outline: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  margin: 0,
                  padding: 0,
                  fontWeight: 'bold',
                }}>
                Send it again.
              </button>
            </strong>
            <span style={{ marginLeft: '.25em', verticalAlign: 'text-top' }}>
              {newCodeStatus === 'success' && (
                <Icon name="check circle outline" color="green" size="large" />
              )}
              {newCodeStatus === 'pending' && (
                <Loader inline active size="tiny" />
              )}
            </span>
          </small>
        </Modal.Actions>
      </Modal>
    );
  }
}

const WrappedModalSecureAuth = props => (
  <AppContext.Consumer>
    {({
      userProfile,
      secureAuthRequested,
      secureLoginFailed,
      secureLoginFailedMessage,
      loginSecure,
      generateSMSToken,
      setLoginSecureCancel,
    }) => (
      <ModalSecureAuth
        {...props}
        userPhone={userProfile && userProfile.mobile_phone}
        secureAuthRequested={secureAuthRequested}
        secureLoginFailed={secureLoginFailed}
        errorMessage={secureLoginFailedMessage}
        submitCode={loginSecure}
        requestNewCode={generateSMSToken}
        handleCancel={setLoginSecureCancel}
      />
    )}
  </AppContext.Consumer>
);

export default WrappedModalSecureAuth;
