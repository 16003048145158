import React from 'react';
import { NavLink } from 'react-router-dom';

import './SectionNav.scss';

export function SectionNav(props) {
  if (props.links) {
    return (
      <nav className="SectionNav">
        {props.links.map((link, i) =>
          link.isVisible !== false ? (
            <NavLink key={i} to={link.path}>
              {link.name}
            </NavLink>
          ) : null
        )}
      </nav>
    );
  }
  return null;
}

export function SectionTabs({ links, activeTab, onSelect }) {
  if (links) {
    return (
      <nav className="SectionNav">
        {links.map(link =>
          link.isVisible !== false ? (
            <span
              key={link.id}
              className={`tab-link${link.id === activeTab ? ' active' : ''}`}
              onClick={() => onSelect(link.id)}>
              {link.name}
            </span>
          ) : null
        )}
      </nav>
    );
  }
  return null;
}
