import React from 'react';
import bowser from 'bowser';
import { Message, Icon } from 'semantic-ui-react';

import './BaseEntry.scss';

const BaseEntry = ({ children }) => {
  let isRecommendedBrowser = true;
  if (!bowser.chrome && !bowser.chromium && !bowser.firefox && !bowser.msedge) {
    isRecommendedBrowser = false;
  }
  return (
    <div className="BaseEntry">
      <div className="viewContainer">
        <img
          height="88"
          width="200"
          alt="Corsica Technologies logo"
          className="brand"
          src="https://cdn.app.corsicatech.com/assets/images/corsicatech_color_medium.svg"
        />
        <div className="content">
          {children}
          {!isRecommendedBrowser && (
            <Message className="recommended-browsers">
              <Icon name="info circle" />
              <p>
                We recommend using{' '}
                <a
                  className="vendor-link"
                  href="https://google.com/chrome"
                  rel="noopener noreferrer">
                  Google Chrome
                </a>
                {', '}
                <a
                  className="vendor-link"
                  href="https://getfirefox.com"
                  rel="noopener noreferrer">
                  Firefox
                </a>
                {' or '}
                <a
                  className="vendor-link"
                  href="https://www.microsoft.com/en-us/edge"
                  rel="noopener noreferrer">
                  Microsoft Edge
                </a>{' '}
                for the best experience.
              </p>
            </Message>
          )}
        </div>
      </div>
    </div>
  );
};
export default BaseEntry;
