import React from 'react';

import BaseEntry from '../BaseEntry/BaseEntry';

const BugsnagFallback = () => {
  return (
    <BaseEntry>
      <div className="viewContainer">
        <h1>An error has occurred.</h1>
        <p>
          Unfortunately, an error has occurred. The Corsica team has been
          notified.
        </p>
      </div>
    </BaseEntry>
  );
};

export default BugsnagFallback;
