import React from 'react';
import { WarnIdleUserDialog } from './WarnIdleUserDialog/WarnIdleUserDialog';
import { endSession, createEvent, eventTypes, jwtDecode } from '../utils/';
import { getToken, Auth } from '../api';
import { history } from '../store';
import { clearAuthCache, getSessionKey, setSessionKey } from '../ManageCache';

const MINUTES_UNTIL_LOGOUT = 90;
const STORE_KEY = 'lastAction';

/** AutoLogout HOC
 * usage example:
 * <Route path="/test" component={AutoLogout(component)} />
 */
class AutoLogout extends React.Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      showWarning: false,
      signoutTime: 1000 * 60 * MINUTES_UNTIL_LOGOUT,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('click', this.resetTimeout);
    this.setTimeout();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.destroy();
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  get lastAction() {
    return parseInt(getSessionKey(STORE_KEY), 10);
  }

  set lastAction(value) {
    setSessionKey(STORE_KEY, JSON.stringify(value));
  }

  getTokenExpiration = () => {
    // return Date.now() + 65000; // for testing. enable this and it will trigger it 30s after login
    const token = getToken();
    if (!token || typeof token === 'undefined' || token === 'undefined') {
      return Date.now() + 1000;
    }
    const decoded = jwtDecode(token);
    return decoded.exp * 1000;
  };

  clearTimeoutFunc = () => {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  };

  setTimeout = () => {
    this.lastAction = Date.now();
    // tokenExpirationTime is the time at which the token expires
    const tokenExpirationTime = this.getTokenExpiration();
    this.tokenExpirationTime = tokenExpirationTime;
    // tokenExpiration is how many miliseconds until token expires
    const tokenExpiration = tokenExpirationTime - Date.now();
    let inactivityPeriod = this.state.signoutTime;
    // if the amount of time before the token expires is less than the
    // amount of inactivity required to prompt, then use the time left
    // on the token as the time to prompt.
    inactivityPeriod =
      inactivityPeriod > tokenExpiration ? tokenExpiration : inactivityPeriod;
    const warningTime = inactivityPeriod - 60 * 1000;

    this.warnTimeout = setTimeout(this.warn, warningTime);
    this.logoutTimeout = setTimeout(this.logout, inactivityPeriod);
  };

  resetTimeout = () => {
    this.safeSetState({ showWarning: false });
    this.clearTimeoutFunc();
    this.setTimeout();
  };

  warn = () => {
    this.safeSetState({ showWarning: true });
  };

  test = () => {
    this.clearTimeoutFunc();
    window.removeEventListener('click', this.resetTimeout);
    this.warnTimeout = setTimeout(this.warn, 1000);
    this.logoutTimeout = setTimeout(this.logout, 61000);
  };

  logout = () => {
    this.destroy();

    createEvent({
      action: eventTypes.SIGN_OUT,
    });

    this.clearSessionAndRedirect();
  };

  timeout = () => {
    createEvent({
      route: window.location.pathname,
      action: eventTypes.TIME_OUT,
    });

    this.clearSessionAndRedirect();
  };

  clearSessionAndRedirect = () => {
    endSession();
    clearAuthCache();

    Auth.timeout();

    const postLoginRedirect = `${window.location.pathname}${window.location.search}`;
    history.push(`/timeout?plr=${encodeURIComponent(postLoginRedirect)}`);
  };

  destroy = () => {
    this.clearTimeoutFunc();
    window.removeEventListener('click', this.resetTimeout);
    this.safeSetState({ showWarning: false });
  };

  refreshToken = () => {
    this.resetTimeout();
    Auth.refreshToken().end();
  };

  render() {
    return (
      <React.Fragment>
        <WarnIdleUserDialog
          open={this.state.showWarning}
          onClose={this.logout}
          onLogout={this.logout}
          onDontLogout={this.refreshToken}
        />
      </React.Fragment>
    );
  }
}

export default AutoLogout;
