import React, { useState, useEffect } from 'react';
import { Card } from '..';
import { numberWithCommas } from '../../utils/utils';
import { Dashboard, ErrorHandler } from '../../api';
import { history } from '../../store';

import './WidgetIncidentsResolved.scss';

const goToTickets = () => {
  history.push(
    '/my-organization/tickets?tab=resolved&ticket_category=Incident'
  );
};

function WidgetIncidentsResolved({ timePeriod, className, subtitle }) {
  const [count, setCount] = useState();
  const [percent, setPercent] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // need to prevent attempting to set state on unmounted components
    let unmounted = false;

    const fetchData = async () => {
      let period = '';
      switch (timePeriod) {
        case '24hours':
          period = 'day';
          break;
        case '7days':
          period = 'week';
          break;
        case '90days':
          break;
        default:
          break;
      }

      let res;
      try {
        res = await Dashboard.incidentsResolved({ filters: { period } });
      } catch (err) {
        ErrorHandler.error(err);
      }

      if (res?.body?.data && !unmounted) {
        const { data } = res.body;

        setCount(data.total_count);
        setPercent(data.total_percentage);
      }
      if (!unmounted) {
        setLoading(false);
      }
    };
    fetchData();

    // on dismount this return callback gets called
    return () => {
      unmounted = true;
    };
  }, [timePeriod]);

  return (
    <Card
      className={['WidgetIncidentsResolved', className]
        .filter(f => !!f)
        .join(' ')}
      onClick={timePeriod === '90days' ? goToTickets : null}
      title="Incidents Resolved"
      isLoading={loading}
      subtitle={subtitle}>
      <div className="count">
        {timePeriod === '90days' ? numberWithCommas(count) : `${percent || 0}%`}
      </div>
    </Card>
  );
}

export default WidgetIncidentsResolved;
