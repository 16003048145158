import React from 'react';

import { Icon } from 'semantic-ui-react';

import './HeaderBanner.scss';

const HeaderBanner = ({ message, className, ...otherProps }) => (
  <div
    className={['HeaderBanner', className].filter(f => !!f).join(' ')}
    {...otherProps}>
    <span className="icon-box">
      <Icon name="warning sign" inverted />
    </span>
    <div className="message-area">{message}</div>
  </div>
);

export default HeaderBanner;
