import React, { useState, useEffect, useRef } from 'react';
import withQueryParams from '../../queryParamHOC';

import { FormSelect } from '../';
import { AppContext } from '../../AppContext';

import isEqual from 'lodash/isEqual';

import './SiteSelector.scss';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const SiteSelector = ({
  sites,
  className,
  style,
  onChange,
  queryParams,
  setQueryParams,
  isLoading,
}) => {
  const _sites = sites || [];

  const allOption = {
    text: 'All Sites',
    value: '',
  };

  const sortByName = (siteA, siteB) => {
    const nameA = siteA?.name?.toUpperCase?.();
    const nameB = siteB?.name?.toUpperCase?.();
    // typical javascript sort function.
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  };

  let options = [];

  const setOptions = () => {
    options = [
      allOption,
      ..._sites
        .sort(sortByName)
        .filter(site => site.name !== 'unassigned')
        .map(site => ({ text: site.name, value: site.id })),
    ];
  };
  setOptions();

  const [siteId, setSiteId] = useState('');
  const prevProps = usePrevious({ sites });

  const setSelectedSite = value => {
    value = value || '';

    setOptions();

    let selectedSite = value && options.find(site => site.value === value);
    if (!selectedSite) {
      selectedSite = allOption;
    }

    typeof onChange === 'function' &&
      onChange({ siteId: value, siteName: selectedSite?.text });

    setSiteId(value);
  };

  const setSiteAndQP = value => {
    setQueryParams({ site: value });
    setSelectedSite(value);
  };

  const handleChange = (e, { value }) => {
    setSiteAndQP(value);
  };

  // this is basically componentDidMount
  useEffect(() => {
    const qpSite = queryParams.site || '';
    if (qpSite && options.find(site => site.value === qpSite)) {
      setSelectedSite(qpSite);
    } else {
      setSiteAndQP('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // this one is called every time sites changes
  useEffect(() => {
    // if there were previous props, ie we were loaded before, and sites changes
    if (prevProps?.sites && !isEqual(prevProps?.sites, sites)) {
      setSiteAndQP('');
    }
    // explore the effects of vvvvvvv
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites]);

  return (
    _sites?.length > 1 && (
      <FormSelect
        className={['SiteSelector', className].filter(f => !!f).join(' ')}
        style={style}
        onChange={handleChange}
        label="Site"
        options={options}
        isLoading={isLoading}
        value={siteId}
        disabled={isLoading}
      />
    )
  );
};

const QPSiteSelector = withQueryParams({
  stripUnknownKeys: false,
  keys: {
    site: {
      default: undefined,
      validate: () => true,
    },
  },
})(SiteSelector);

const WrappedSiteSelector = props => (
  <AppContext.Consumer>
    {({ selectedCustomer }) => (
      <QPSiteSelector {...props} sites={selectedCustomer.sites} />
    )}
  </AppContext.Consumer>
);

export default WrappedSiteSelector;
