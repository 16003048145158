import React, { useState, useEffect } from 'react';
import { FormSelect, ListWidget } from '..';
import { Dashboard, ErrorHandler } from '../../api';
import { widgetClick } from '../../utils/analytics';

const defaultOptions = [{ text: 'All Types', value: '' }];

export default function WidgetTopIncidentContacts({
  className,
  ...otherProps
}) {
  const [options, setOptions] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    // need to prevent attempting to set state on unmounted components
    let unmounted = false;

    const fetchData = async () => {
      if (!unmounted) {
        setIsLoading(true);
      }
      let res;
      try {
        res = await Dashboard.topIncidentContacts({
          filters: { type: selectedType },
        });
      } catch (err) {
        ErrorHandler.error(err);
      }
      if (res?.body?.data && !unmounted) {
        const { data, meta } = res.body;
        if (options.length === 0) {
          setOptions([
            ...defaultOptions,
            ...meta.top_types.map(type => ({ text: type, value: type })),
          ]);
        }

        setRows([
          ...data.map(r => ({
            text: r.name,
            value: `${r.percentage}%`,
            onClick: () => {
              widgetClick({
                link: `/my-organization/tickets?tab=resolved&contact=${escape(
                  r.name
                )}&ticket_category=Incident${
                  selectedType === ''
                    ? ''
                    : `&ticket_type=${escape(selectedType)}`
                }`,
                name: 'WidgetTopIncidentContacts',
              });
            },
          })),
        ]);
      }
      if (!unmounted) {
        setIsLoading(false);
        setIsLoadingOptions(false);
      }
    };
    fetchData();

    // on dismount this return callback gets called
    return () => {
      unmounted = true;
    };
  }, [selectedType, options]);

  return (
    <ListWidget
      rows={5}
      columns={1}
      data={rows}
      title="Top Incident Contacts"
      subtitle="resolved in last 90 days"
      className="WidgetTopIncidentContacts"
      isLoading={isLoading}
      headerActions={
        <FormSelect
          style={{ width: '150px' }}
          isLoading={isLoadingOptions}
          options={options}
          value={selectedType}
          onChange={(e, { value }) => setSelectedType(value)}
        />
      }
    />
  );
}
