import HeaderBanner from './HeaderBanner/HeaderBanner';
import React from 'react';
import { AppContext } from '../AppContext';
import withQueryParams from '../queryParamHOC';

import AddTicketModal from './AddTicketModal/AddTicketModal';
import AssigneeSelect from './AssigneeSelect/AssigneeSelect';
import AutoLogout from './AutoLogout';
import AutomatedNotification from './Notification/AutomatedNotification';
import BaseWarningPage from './BaseWarningPage/BaseWarningPage';
import CSATFeedback from './CSATFeedback/CSATFeedback';
import CardWithTabsWithRouting from './CardWithTabs/CardWithTabs';
import ChatBot from './ChatBot/ChatBot';
import ConfirmModal from './ConfirmModal/ConfirmModal';
import CountDown from './CountDown/CountDown';
import CreateTicketButton from './CreateTicketButton/CreateTicketButton';
import CreateTicketLink from './CreateTicketLink/CreateTicketLink';
import CreateTicketWrapper from './CreateTicketWrapper/CreateTicketWrapper';
import CustomToolTipMalware from './CustomToolTipMalware/CustomToolTipMalware';
import CustomerSelect from './CustomerSelect';
import DatePopup from './DatePopup/DatePopup';
import DateRangeSelectors from './DateRangeSelectors/';
import DateSelector from './DateSelector/';
import DetailCard from './DetailCard/DetailCard';
import DetailRowItem from './DetailRowItem/DetailRowItem';
import DevTools from './DevTools/DevTools';
import EOLNotification from './Notification/EOLNotification';
import Emoji from './Emoji/Emoji';
import FeatureBadge from './FeatureBadge';
import Feedback from './Feedback/Feedback';
import HubSpotCTA from './HubSpotCTA/HubSpotCTA';
import IEWarning from './IEWarning/IEWarning';
import IPBlockedWarning from './IPBlockedWarning/IPBlockedWarning';
import Interfaces from './Interfaces/Interfaces';
import LastUpdated from './LastUpdated';
import LifeCycleStat from './LifeCycleStat/LifeCycleStat';
import MFAButton from './MFAButton/MFAButton';
import ModalSecureAuth from './ModalSecureAuth';
import ModalUserEdit from './ModalUserEdit/ModalUserEdit';
import NothingFound from './NothingFound';
import NotificationContentRow from './NotificationContentRow/NotificationContentRow';
import RDPNotification from './Notification/RDPNotification';
import RegistrationBreadcrumb from './RegistrationBreadcrumb/RegistrationBreadcrumb';
import RelativeTimeStamp from './RelativeTimeStamp/RelativeTimeStamp';
import SVGCircle from './SVGCircle/SVGCircle';
import SatisfactionWidget from './SatisfactionWidget/SatisfactionWidget';
import ScheduleHelpButton from './ScheduleHelpButton/ScheduleHelpButton';
import SectionHeader from './SectionHeader/';
import Sidebar from './Sidebar/Sidebar';
import SiteSelector from './SiteSelector/SiteSelector';
import StatsBarWithGap from './StatsBarWithGap/StatsBarWithGap';
import StatusBanner from './StatusBanner/StatusBanner';
import Success from './Success/Success';
import TableRowOptionPopup from './TableRowOptionPopup/TableRowOptionPopup';
import TabularList from './TabularList/TabularList';
import ThumbsUpPanel from './ThumbsUpPanel';
import TotalTicketsWidget from './TotalTicketsWidget/TotalTicketsWidget';
import TransitionInOut from './TransitionInOut';
import UserAccessSelect from './UserAccessSelect/UserAccessSelect';
import UserProfileModal from './UserProfileModal/UserProfileModal';
import UserRoleSelect from './UserRoleSelect/UserRoleSelect';
import WarnIdleUserDialog from './WarnIdleUserDialog/WarnIdleUserDialog';
import WidgetBlockedDNS from './WidgetBlockedDNS/WidgetBlockedDNS';
import WidgetClientTeam from './WidgetClientTeam/WidgetClientTeam';
import WidgetDialGaugeGraph from './WidgetDialGaugeGraph/WidgetDialGaugeGraph';
import WidgetEmailSecurity from './WidgetEmailSecurity/WidgetEmailSecurity';
import WidgetEndpointProtection from './WidgetEndpointProtection/WidgetEndpointProtection';
import WidgetEndpointProtectionStatus from './WidgetEndpointProtectionStatus/WidgetEndpointProtectionStatus';
import WidgetHighestRiskRecipients from './WidgetHighestRiskRecipients/WidgetHighestRiskRecipients';
import WidgetIncidentTypes from './WidgetIncidentTypes/WidgetIncidentTypes';
import WidgetIncidents from './WidgetIncidents/WidgetIncidents';
import WidgetIncidentsResolved from './WidgetIncidentsResolved/WidgetIncidentsResolved';
import WidgetInternetUtilization from './WidgetInternetUtilization/WidgetInternetUtilization';
import WidgetMalware from './WidgetMalware/WidgetMalware';
import WidgetOverallCsat from './WidgetOverallCsat/WidgetOverallCsat';
import WidgetPatchingStatus from './WidgetPatchingStatus/WidgetPatchingStatus';
import WidgetPatchingV2 from './WidgetPatchingV2/WidgetPatching';
import WidgetPhishTestingOverview from './WidgetPhishTestingOverview/WidgetPhishTestingOverview';
import WidgetProgressRows from './WidgetProgressRows/WidgetProgressRows';
import WidgetRefreshes from './WidgetRefreshes/WidgetRefreshes';
import WidgetRequests from './WidgetRequests/WidgetRequests';
import WidgetSeats from './WidgetSeats/WidgetSeats';
import WidgetSecurityIncidents from './WidgetSecurityIncidents/WidgetSecurityIncidents';
import WidgetServiceTickets from './WidgetServiceTickets/WidgetServiceTickets';
import WidgetTicketCategories from './WidgetTicketCategories/WidgetTicketCategories';
import WidgetTicketSatisfactionRating from './WidgetTicketSatisfactionRating/WidgetTicketSatisfactionRating';
import WidgetTopIncidentContacts from './WidgetTopIncidentContacts/WidgetTopIncidentContacts';
import WidgetTopSupportDevices from './WidgetTopSupportDevices/WidgetTopSupportDevices';
import WidgetVulnerabilities from './WidgetVulnerabilities/WidgetVulnerabilities';
import WidgetWorkstationCount from './WidgetWorkstationCount/WidgetWorkstationCount';
import WidgetOnboardingProgress from './WidgetOnboardingProgress/WidgetOnboardingProgress';

import { SectionNav, SectionTabs } from './SectionNav/';

import {
  AlertLevelWidget,
  Badge,
  Banner,
  BaseDateSelector,
  BaseDateSelectorWithDropdowns,
  BaseMonthSelector,
  BaseWeekSelector,
  CSVExport,
  Calendar,
  Card,
  CardWithTabs,
  CoverageStat,
  CustomTooltip as CustomToolTip, // TODO: fix this
  CustomTooltipV2,
  CustomTooltipWithLines,
  DataTableLite,
  DataTableV4 as UnwrappedDataTableV4,
  DisplayField,
  DonutChart,
  DonutWidget,
  Dot,
  FilterBoxDropdown,
  FormDate,
  FormField,
  FormFileUpload,
  FormInput,
  FormSelect as UnwrappedFormSelect,
  FormTextArea,
  HeaderCountWidget,
  HeaderInfoTooltip,
  HeaderPercentWidget,
  HeaderWidget,
  ListTableMultiColumn,
  ListWidget,
  ModifiedFormSelect,
  Overlay,
  Pill,
  ScoreBox,
  SearchInput,
  StatsBar,
  StatsBarVertical,
  StatusIcon,
  StatusPill,
  TablePagination,
  Tag,
} from 'corsica-component-library';

// Passing data layer to FormSelect
const FormSelect = props => (
  <AppContext.Consumer>
    {({ createEvent, eventTypes }) => (
      <UnwrappedFormSelect
        createEvent={createEvent}
        eventTypes={eventTypes}
        {...props}
      />
    )}
  </AppContext.Consumer>
);

// DataTableV4 needs to be wired up to our data layer via AppContext. The library sends it unwrapped, but ready
const DataTableFirstWrapV4 = props => (
  <AppContext.Consumer>
    {({
      searchInput,
      searchInputFunction,
      eventTypes,
      createEvent,
      ErrorHandler,
    }) => (
      <UnwrappedDataTableV4
        minTablePixelWidth={
          1600 /* 
          this min width is intentionally before the props spread so that users can overwrite it 
          this prop is important to set how wide the scrollable width of the table will be.
            */
        }
        {...props}
        preventScrollId="appContent"
        searchInput={searchInput}
        searchInputFunction={searchInputFunction}
        createEvent={createEvent}
        ErrorHandler={ErrorHandler}
        eventTypes={eventTypes}
        offsets={[
          { className: 'hasNotification', offset: 60 },
          { className: 'hasStickyHeader', offset: 78 },
        ]}
        scrollIds={{ horizontal: 'LayoutDefault', vertical: 'appContent' }}
      />
    )}
  </AppContext.Consumer>
);

const DataTable = withQueryParams({
  stripUnknownKeys: false,
  keys: {
    s: {
      default: undefined,
      validate: () => true,
    },
  },
})(DataTableFirstWrapV4);

export {
  AddTicketModal,
  AlertLevelWidget,
  AssigneeSelect,
  AutoLogout,
  AutomatedNotification,
  Badge,
  Banner,
  BaseDateSelector,
  BaseDateSelectorWithDropdowns,
  BaseMonthSelector,
  BaseWeekSelector,
  BaseWarningPage,
  CSATFeedback,
  CSVExport,
  Calendar,
  Card,
  CardWithTabs,
  CardWithTabsWithRouting,
  ChatBot,
  ConfirmModal,
  CountDown,
  CoverageStat,
  CreateTicketButton,
  CreateTicketLink,
  CreateTicketWrapper,
  CustomToolTip,
  CustomToolTipMalware,
  CustomTooltipV2,
  CustomTooltipWithLines,
  CustomerSelect,
  DataTable,
  DataTableLite,
  DatePopup,
  DateRangeSelectors,
  DateSelector,
  DetailCard,
  DetailRowItem,
  DevTools,
  DisplayField,
  DonutChart,
  DonutWidget,
  Dot,
  EOLNotification,
  Emoji,
  FeatureBadge,
  Feedback,
  FilterBoxDropdown,
  FormDate,
  FormField,
  FormFileUpload,
  FormInput,
  FormSelect,
  FormTextArea,
  HeaderBanner,
  HeaderCountWidget,
  HeaderInfoTooltip,
  HeaderPercentWidget,
  HeaderWidget,
  HubSpotCTA,
  IEWarning,
  IPBlockedWarning,
  Interfaces,
  LastUpdated,
  LifeCycleStat,
  ListTableMultiColumn,
  ListWidget,
  MFAButton,
  ModalSecureAuth,
  ModalUserEdit,
  ModifiedFormSelect,
  NothingFound,
  NotificationContentRow,
  Overlay,
  Pill,
  RDPNotification,
  RegistrationBreadcrumb,
  RelativeTimeStamp,
  SVGCircle,
  SatisfactionWidget,
  ScheduleHelpButton,
  ScoreBox,
  SearchInput,
  SectionHeader,
  SectionNav,
  SectionTabs,
  Sidebar,
  SiteSelector,
  StatsBar,
  StatsBarVertical,
  StatsBarWithGap,
  StatusBanner,
  StatusIcon,
  StatusPill,
  Success,
  TablePagination,
  TableRowOptionPopup,
  TabularList,
  Tag,
  ThumbsUpPanel,
  TotalTicketsWidget,
  TransitionInOut,
  UserAccessSelect,
  UserProfileModal,
  UserRoleSelect,
  WarnIdleUserDialog,
  WidgetBlockedDNS,
  WidgetClientTeam,
  WidgetDialGaugeGraph,
  WidgetEmailSecurity,
  WidgetEndpointProtection,
  WidgetEndpointProtectionStatus,
  WidgetHighestRiskRecipients,
  WidgetIncidentTypes,
  WidgetIncidents,
  WidgetIncidentsResolved,
  WidgetInternetUtilization,
  WidgetMalware,
  WidgetOverallCsat,
  WidgetPatchingStatus,
  WidgetPatchingV2,
  WidgetPhishTestingOverview,
  WidgetProgressRows,
  WidgetRefreshes,
  WidgetRequests,
  WidgetSeats,
  WidgetSecurityIncidents,
  WidgetServiceTickets,
  WidgetTicketCategories,
  WidgetTicketSatisfactionRating,
  WidgetTopIncidentContacts,
  WidgetTopSupportDevices,
  WidgetVulnerabilities,
  WidgetWorkstationCount,
  WidgetOnboardingProgress,
};

export * from './DeviceTick';
export { Toast, ToastContainer } from './Toast';
