import React from 'react';

import { format } from 'date-fns';
import { Popup } from 'semantic-ui-react';

const DatePopup = ({ time, displayFormat, popupFormat }) => (
  <Popup
    content={popupFormat ? format(time, popupFormat) : time}
    trigger={
      <span>{`${displayFormat ? format(time, displayFormat) : time}`}</span>
    }
    position="top center"
    size="mini"
  />
);

export default DatePopup;
