import React from 'react';
import { Message } from 'semantic-ui-react';

import './BaseWarningPage.scss';

const BaseWarningPage = ({
  title,
  body,
  className,
  children,
  ...otherProps
}) => {
  return (
    <div
      {...otherProps}
      className={['warningContainer', className].filter(f => !!f).join(' ')}>
      <div className="viewContainer">
        <img
          height="88"
          width="200"
          alt="Corsica Technologies logo"
          className="brand"
          src={`${process.env.REACT_APP_CDN_URL}/assets/images/corsicatech_color_medium.svg`}
        />
        <div className="content">
          <h3 className="headline">
            Welcome to the
            <br />
            Corsica Client Portal
          </h3>
          <Message className="recommended-browsers">
            <h5>{title}</h5>
            <p>{body}</p>
          </Message>
        </div>
      </div>
    </div>
  );
};
export default BaseWarningPage;
