import React from 'react';
import PropTypes from 'prop-types';

const ThumbsUpPanel = ({ eventName }) => {
  return (
    <div
      className="ThumbsUpPanel"
      style={{
        position: 'relative',
        padding: '1em',
        alignSelf: 'center',
      }}>
      <div style={{ textAlign: 'center' }}>
        <p
          style={{
            textAlign: 'center',
            display: 'block',
          }}>
          There were no {eventName} events recorded during the selected time
          period.
        </p>
      </div>
    </div>
  );
};

ThumbsUpPanel.propTypes = {
  eventName: PropTypes.string,
};

export default ThumbsUpPanel;
