import React from 'react';

import { Icon, Modal } from 'semantic-ui-react';
import ContentEntry from './ContentEntry';
import ContentSubmitted from './ContentSubmitted';
import { AppContext } from '../../AppContext';
import CreateTicketWrapper from '../CreateTicketWrapper/CreateTicketWrapper';

import './Feedback.scss';

class Feedback extends React.Component {
  _isMounted = false;
  state = {
    modalOpen: false,
    isEntryViewShown: true,
  };

  shrink = () => {
    document.querySelector('.Feedback').classList.add('shrunkFeedback');
  };

  unShrink = () => {
    document.querySelector('.Feedback').classList.remove('shrunkFeedback');
  };

  componentDidMount() {
    this._isMounted = true;
    // bring the button up shortly after mounting
    setTimeout(() => {
      if (this._isMounted === true) {
        document.querySelector('.Feedback').style.bottom = '15px';
      }
    }, 3000);
    // shrink button after 60 seconds
    setTimeout(() => {
      if (this._isMounted === true) {
        this.shrink();
      }
    }, 20000);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () =>
    this.setState({ modalOpen: false, isEntryViewShown: true });
  handleGoToSuccess = () => this.setState({ isEntryViewShown: false });
  handleGoToEntry = () => this.setState({ isEntryViewShown: true });

  render() {
    const { modalOpen, isEntryViewShown } = this.state;
    const { userProfile } = this.props;

    return (
      <CreateTicketWrapper>
        {({ onClick }) => (
          <Modal
            size="small"
            open={modalOpen}
            onClose={this.handleClose}
            trigger={
              <div
                className={`Feedback`}
                onClick={this.handleOpen}
                onMouseEnter={this.unShrink}
                onMouseLeave={this.shrink}>
                <Icon name="comments outline" />
                <span>Have Feedback?</span>
              </div>
            }>
            {isEntryViewShown && (
              <ContentEntry
                onGoToCreateTicket={onClick}
                onClose={this.handleClose}
                onGoToSuccess={this.handleGoToSuccess}
                userProfile={userProfile}
              />
            )}
            {!isEntryViewShown && (
              <ContentSubmitted
                onClose={this.handleClose}
                onGoToEntry={this.handleGoToEntry}
                firstName={userProfile.first_name}
              />
            )}
          </Modal>
        )}
      </CreateTicketWrapper>
    );
  }
}

const WrappedFeedback = props => (
  <AppContext.Consumer>
    {({ userProfile, imitateClientId }) => (
      <Feedback
        {...props}
        imitateClientId={imitateClientId}
        userProfile={userProfile}
      />
    )}
  </AppContext.Consumer>
);

export default WrappedFeedback;
