const CORSICA_CUSTOMER_ID = '6f8289d9-b7cd-434f-bb80-6e40e8cddc1b';

const envStages = {
  staging: {
    name: 'staging',
    apiBase: 'https://portal.staging.corsicatech.com/api',
    appBase: 'https://portal.staging.corsicatech.com',
    production: true,
    corsicaCustomerId: CORSICA_CUSTOMER_ID,
  },
  demo: {
    name: 'demo',
    apiBase: 'https://portal.demo.corsicatech.com/api',
    appBase: 'https://portal.demo.corsicatech.com',
    production: false,
    corsicaCustomerId: CORSICA_CUSTOMER_ID,
  },
  production: {
    name: 'production',
    apiBase: 'https://portal.corsicatech.com/api',
    appBase: 'https://portal.corsicatech.com',
    production: true,
    corsicaCustomerId: CORSICA_CUSTOMER_ID,
  },
  development: {
    name: 'development',
    apiBase: 'https://portal.dev.corsicatech.com/api',
    appBase: 'https://portal.dev.corsicatech.com',
    production: false,
    corsicaCustomerId: CORSICA_CUSTOMER_ID,
  },
  testing: {
    name: 'testing',
    apiBase: 'http://localhost:19499/api',
    appBase: 'http://localhost:19499',
    production: false,
    corsicaCustomerId: CORSICA_CUSTOMER_ID,
  },
  e2eTesting: {
    name: 'e2eTesting',
    apiBase: 'http://localhost:1337/api',
    appBase: 'http://localhost:1337', // this looks wrong, but it is used for how we handle the auth redirect
    production: false,
    corsicaCustomerId: CORSICA_CUSTOMER_ID,
  },
};

const checkEnv = env => process.env.REACT_APP_ENV === env.name;

/*
 * NOTE: these are not functions but just bool
 * usage: `environment.isE2ETesting ? 'testing, yo!' : 'not testing';`
 */
export const environment = {
  env: process.env.REACT_APP_ENV,
  isStaging: checkEnv(envStages.staging),
  isDemo: checkEnv(envStages.demo),
  isProduction: checkEnv(envStages.production),
  isDevelopment: checkEnv(envStages.development),
  isTesting: checkEnv(envStages.testing),
  isE2ETesting: checkEnv(envStages.e2eTesting),
  get current() {
    return envStages[process.env.REACT_APP_ENV] || envStages.development;
  },
};
