import React from 'react';
import { Modal, Message, Button } from 'semantic-ui-react';
import { FormInput, FormFileUpload, FormTextArea, FormSelect } from '../';

const phoneRegExp = /^\(\d{3}\)\s\d{3}-\d{4}/;

const ContentTicketCreate = ({
  clientName,
  isError,
  title,
  isErrorSubject,
  description,
  isErrorIssue,
  handleFiles,
  isUploading,
  uploadProgress,
  isErrorFiles,
  isSubmitting,
  handleSubmit,
  handleSoftCancel,
  handleFieldChange,
  isLoadingAttributes,
  nonSparkPriority,
  contactMethod,
  othersExperiencingIssue,
  phoneMobile,
  phoneOffice,
  phoneOfficeExt,
  userProfile,
}) => (
  <>
    <Modal.Header style={{ borderBottom: 'none' }}>
      <h2>Create New Support Ticket</h2>
      {clientName && (
        <p className="subheading">
          for <strong>{clientName}</strong>
        </p>
      )}
    </Modal.Header>
    <Modal.Content>
      {isError && (
        <Message negative>There was an error creating the ticket</Message>
      )}
      <FormInput
        style={{ width: '100%', marginBottom: '15px' }}
        label="Subject for ticket"
        onChange={e => handleFieldChange('title', e?.target?.value)}
        value={title}
        error={isErrorSubject}
        errorMessage={'A subject is required'}
        maxLength={100}
        autoFocus={true}
      />
      {!(title.length < 100) && (
        <span className="subject-length-warning">
          Please limit the subject to 100 characters.
        </span>
      )}
      <FormTextArea
        placeholder="Please describe your issue and include details like: when the issue started, what troubleshooting steps you have already taken and attach any associated screenshots below"
        rows={10}
        onChange={e => handleFieldChange('description', e?.target?.value)}
        value={description}
        style={{ width: '100%', marginBottom: '15px' }}
        label="What can we help you with?"
        error={isErrorIssue}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <FormFileUpload
          multiple={true}
          style={{ width: '100%', marginBottom: '15px' }}
          onChange={handleFiles}
          label={
            <>
              {'Add screenshots to assist with troubleshooting'}
              <br />
              {
                'JPG, PNG, PDF, CSV, DOC, DOCX, XLS, XLSX allowed - max file size 2MB'
              }
            </>
          }
          isUploading={isUploading}
          uploadProgress={uploadProgress}
          error={isErrorFiles}
          errorMessage={'There was a problem uploading your file'}
        />
      </div>
      <div className="form-row">
        {!!userProfile?.can_set_ticket_priority && (
          <FormSelect
            style={{ width: '30%' }}
            options={[
              {
                text: 'Urgent',
                value: '15',
              },
              {
                text: 'Standard',
                value: '8',
              },
            ]}
            label="Priority"
            onChange={(e, opt) =>
              handleFieldChange('nonSparkPriority', opt?.value)
            }
            value={nonSparkPriority}
            isLoading={isLoadingAttributes}
            default="8"
          />
        )}
        <FormSelect
          style={{
            width: userProfile?.can_set_ticket_priority ? '67%' : '100%',
          }}
          label="Are others experiencing this issue?"
          options={[
            { text: 'Yes', value: 'Yes' },
            { text: 'No', value: 'No' },
            { text: "I don't know", value: "I don't know" },
          ]}
          onChange={(e, opt) => {
            handleFieldChange('othersExperiencingIssue', opt?.value);
            if (opt?.value === 'Yes') {
              handleFieldChange('nonSparkPriority', '15');
            } else {
              handleFieldChange('nonSparkPriority', '8');
            }
          }}
          value={othersExperiencingIssue}
        />
      </div>
      <FormSelect
        style={{ width: '100%', marginBottom: '15px' }}
        label="What is the best contact number to reach you at?"
        options={[
          { text: 'Office Phone', value: 'Office Phone' },
          { text: 'Mobile Phone', value: 'Mobile Phone' },
          { text: 'Email', value: 'Email' },
        ]}
        onChange={(e, opt) => handleFieldChange('contactMethod', opt?.value)}
        value={contactMethod}
        autoFocus
      />
      {contactMethod === 'Mobile Phone' && (
        <FormInput
          style={{ marginBottom: '15px' }}
          label="Mobile Phone Number"
          autocomplete="tel"
          onChange={(e, { value }) => handleFieldChange('phoneMobile', value)}
          value={phoneMobile}
          required
        />
      )}

      {contactMethod === 'Office Phone' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'flex-end',
          }}>
          <FormInput
            style={{ width: '67%' }}
            label="Office Phone Number"
            autocomplete="tel"
            onChange={(e, { value }) => handleFieldChange('phoneOffice', value)}
            value={phoneOffice}
            required
          />
          <FormInput
            style={{ width: '30%' }}
            label=""
            placeholder="Ext"
            onChange={(e, { value }) => {
              const newVal = String(value).replace(/[^0-9.]/gi, '');
              handleFieldChange('phoneOfficeExt', newVal);
            }}
            value={phoneOfficeExt}
          />
        </div>
      )}

      {contactMethod === 'Email' && (
        <FormInput
          style={{ marginBottom: '15px', width: '100%' }}
          label="Email"
          autocomplete="email"
          value={userProfile?.email}
          disabled
        />
      )}
    </Modal.Content>
    <Modal.Actions>
      <Button
        primary
        loading={isSubmitting || isUploading}
        onClick={handleSubmit}
        disabled={
          !title ||
          !description ||
          (contactMethod === 'Office Phone' &&
            (!phoneOffice || !String(phoneOffice).match(phoneRegExp))) ||
          (contactMethod === 'Mobile Phone' &&
            (!phoneMobile || !String(phoneMobile).match(phoneRegExp)))
        }>
        Create Ticket
      </Button>
      <Button className="ghost secondary" onClick={handleSoftCancel}>
        Cancel
      </Button>
    </Modal.Actions>
  </>
);

export default ContentTicketCreate;
