import React from 'react';

import { Dashboard, ErrorHandler } from '../../api';
import { Widget } from '../Widget/Widget';
import { numberWithCommas } from '../../utils';
import AreaChartMalwareWidget from './AreaChartMalwareWidget';

import './WidgetMalware.scss';

export default class WidgetMalware extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      malwareCount: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });

    let res;
    try {
      res = await Dashboard.malwareCount();
    } catch (err) {
      ErrorHandler.error(err);
    }

    let malwareCount = null;
    let eventData = null;
    if (res && res.body) {
      const { data, meta } = res.body;
      if (data) {
        eventData = data;
      }
      if (meta) {
        malwareCount = meta.malicious_events_count;
      }
    }

    this.safeSetState({
      eventData,
      isLoading: false,
      malwareCount,
    });
  };

  render() {
    const { malwareCount, isLoading, eventData } = this.state;
    return (
      <Widget
        title="Malicious Events Detected"
        subtitle="in last 30 days"
        isLoading={isLoading}
        link="/devices/malware-activity"
        widgetName="WidgetMalware"
        isEmpty={!malwareCount && malwareCount !== 0}
        emptyStateMessage="No malicious events detected in the last 30 days."
        className="Malware-widget height-3">
        <div className="malwareCount">{numberWithCommas(malwareCount)}</div>
        <AreaChartMalwareWidget data={eventData} isLoading={isLoading} />
      </Widget>
    );
  }
}
