import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

const ContentConfirmAction = ({
  handleContinue,
  handleHideTicketCreateModal,
}) => (
  <>
    <Modal.Header style={{ borderBottom: 'none' }}>
      <h2>Are you sure?</h2>
    </Modal.Header>
    <Modal.Content>
      <p>If you close this window now, any information entered will be lost.</p>
    </Modal.Content>
    <Modal.Actions>
      <Button style={{ display: 'none' }}>hidden</Button>
      <Button className="ghost dark" onClick={handleContinue} active={true}>
        Continue editing
      </Button>
      <Button
        className="ghost destructive"
        onClick={handleHideTicketCreateModal}>
        Close
      </Button>
    </Modal.Actions>
  </>
);

export default ContentConfirmAction;
