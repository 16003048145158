import { lazy } from 'react';
import componentLoader from '../utils/componentLoader';

const ConfigChanges = lazy(() =>
  componentLoader(() => import('./ConfigurationChanges/ConfigurationChanges'))
);
const FailedLogins = lazy(() =>
  componentLoader(() => import('./FailedLogins/FailedLogins'))
);

const MalwareProtection = lazy(() =>
  componentLoader(() => import('./MalwareProtection/MalwareProtection'))
);
const NetworkThreats = lazy(() =>
  componentLoader(() => import('./NetworkThreats/NetworkThreats'))
);
const VpnActivity = lazy(() =>
  componentLoader(() => import('./VpnActivity/VpnActivity'))
);
const InternetUtilization = lazy(() =>
  componentLoader(() => import('./InternetUtilization/InternetUtilization'))
);
const InternetUtilizationDetails = lazy(() =>
  componentLoader(() =>
    import('./InternetUtilization/InternetUtilizationDetails')
  )
);

const Users = lazy(() => componentLoader(() => import('./Users/Users')));
const Tickets = lazy(() => componentLoader(() => import('./Tickets/Tickets')));
const SupportOverview = lazy(() =>
  componentLoader(() => import('./SupportOverview/SupportOverview'))
);
const SecurityAwarenessTraining = lazy(() =>
  componentLoader(() =>
    import('./SecurityAwarenessTraining/SecurityAwarenessTraining')
  )
);
const SecurityAwarenessTrainingDetails = lazy(() =>
  componentLoader(() =>
    import('./SecurityAwarenessTraining/SecurityAwarenessTrainingDetails')
  )
);
const TechnologyRoadmap = lazy(() =>
  componentLoader(() => import('./TechnologyRoadmap/TechnologyRoadmap'))
);
const DarkWebMonitoring = lazy(() =>
  componentLoader(() => import('./DarkWebMonitoring/DarkWebMonitoring'))
);

const Account = lazy(() => componentLoader(() => import('./Account/Account')));
const AccountFinalize = lazy(() =>
  componentLoader(() => import('./AccountFinalize/AccountFinalize'))
);

const LayoutDefault = lazy(() =>
  componentLoader(() => import('./LayoutDefault/'))
);
const PatchingV2 = lazy(() =>
  componentLoader(() => import('./PatchingV2/Patching'))
);
const PatchingDetails = lazy(() =>
  componentLoader(() => import('./PatchingV2/PatchingDetails'))
);
const Dashboard = lazy(() => componentLoader(() => import('./Dashboard')));
const Login = lazy(() => componentLoader(() => import('./Login/Login')));
const UtilizationOverview = lazy(() =>
  componentLoader(() => import('./Utilization/UtilizationOverview'))
);
const LifeCycle = lazy(() =>
  componentLoader(() => import('./LifeCycle/LifeCycle'))
);
const Seats = lazy(() => componentLoader(() => import('./Seats/Seats')));
const Vulnerabilities = lazy(() =>
  componentLoader(() => import('./Vulnerabilities/Vulnerabilities'))
);
const VulnerabilitiesBasic = lazy(() =>
  componentLoader(() => import('./VulnerabilitiesBasic/VulnerabilitiesBasic'))
);
const DNSActivity = lazy(() =>
  componentLoader(() => import('./DNSActivity/DNSActivity'))
);
const EndpointProtection = lazy(() =>
  componentLoader(() => import('./EndpointProtection/EndpointProtection'))
);
const ActiveDirectory = lazy(() =>
  componentLoader(() => import('./ActiveDirectory/ActiveDirectory'))
);
const Backups = lazy(() => componentLoader(() => import('./Backups/Backups')));
const DevicesList = lazy(() =>
  componentLoader(() => import('./DevicesList/DevicesList'))
);
const DevicesOverview = lazy(() =>
  componentLoader(() => import('./DevicesOverview/DevicesOverview'))
);
const SecurityPosture = lazy(() =>
  componentLoader(() => import('./SecurityPosture/SecurityPosture'))
);
const Timeout = lazy(() => componentLoader(() => import('./Timeout')));
const MyTickets = lazy(() =>
  componentLoader(() => import('./MyTickets/MyTickets'))
);
const OnboardingRoadmap = lazy(() =>
  componentLoader(() => import('./OnboardingRoadmap/OnboardingRoadmap'))
);
const Documents = lazy(() =>
  componentLoader(() => import('./Documents/Documents'))
);
const Reports = lazy(() => componentLoader(() => import('./Reports/Reports')));
const SecurityIncidents = lazy(() =>
  componentLoader(() => import('./SecurityIncidents/SecurityIncidents'))
);
const SecurityRemediations = lazy(() =>
  componentLoader(() => import('./SecurityRemediations/SecurityRemediations'))
);
const ClientSettings = lazy(() =>
  componentLoader(() => import('./ClientSettings/ClientSettings'))
);
const TicketDetails = lazy(() =>
  componentLoader(() => import('./TicketDetails/TicketDetails'))
);

export {
  Account,
  AccountFinalize,
  ActiveDirectory,
  Backups,
  ClientSettings,
  DarkWebMonitoring,
  Documents,
  ConfigChanges,
  Dashboard,
  DevicesList,
  DevicesOverview,
  DNSActivity,
  SecurityAwarenessTraining,
  SecurityAwarenessTrainingDetails,
  EndpointProtection,
  FailedLogins,
  InternetUtilization,
  InternetUtilizationDetails,
  LayoutDefault,
  LifeCycle,
  Login,
  MalwareProtection,
  MyTickets,
  NetworkThreats,
  OnboardingRoadmap,
  PatchingDetails,
  PatchingV2,
  Reports,
  Seats,
  SecurityIncidents,
  SecurityPosture,
  SecurityRemediations,
  TechnologyRoadmap,
  TicketDetails,
  Tickets,
  SupportOverview,
  Timeout,
  Users,
  UtilizationOverview,
  VpnActivity,
  Vulnerabilities,
  VulnerabilitiesBasic,
};

export * from './BugsnagFallback';
