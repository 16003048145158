import React from 'react';

import './CountDown.scss';

export default function CountDown({ seconds }) {
  return (
    <div className="CountDown">
      <div
        className="SandsOfTime"
        style={{ animationDuration: `${seconds || 60}s` }}></div>{' '}
    </div>
  );
}
