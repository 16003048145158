import React from 'react';
import { UserRoles } from '../../utils/';
import { FormSelect } from '../../components/';

const options = [];
Object.keys(UserRoles).forEach(key => {
  // `+key` just converts back to a number from a string. Object.keys turns the index to a string
  options.push({ key: +key, value: UserRoles[key], text: UserRoles[key] });
});

class UserRoleSelect extends React.Component {
  placeholderRole = role => {
    if (role === 'it_operations') {
      return 'IT Operations';
    } else if (role && typeof role === 'string') {
      return role.charAt(0).toUpperCase() + role.slice(1);
    } else {
      return role;
    }
  };

  render() {
    const { role, onRoleChange, style } = this.props;

    return (
      <FormSelect
        value={
          role !== null && typeof role !== 'undefined'
            ? role
            : options.find(opt => opt.text)
        }
        options={options}
        placeholder={
          role !== null && role !== 'undefined'
            ? this.placeholderRole(role)
            : null
        }
        onChange={onRoleChange}
        style={style}
      />
    );
  }
}

export default UserRoleSelect;
