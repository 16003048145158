import React from 'react';

import { HeaderWidget } from '../';

import './TotalTicketsWidget.scss';

export const TotalTicketsWidget = ({ ticketCount, site, ...otherProps }) => (
  <HeaderWidget
    {...otherProps}
    title="Tickets Opened"
    subtitle={['in last 90 days', site].filter(f => !!f).join(' at ')}
    value={ticketCount || 0}
    color="gray"
    className="TotalTicketsWidget"
  />
);

export default TotalTicketsWidget;
