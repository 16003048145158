import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { FormSelect } from '../';

const ContentSelectClient = ({
  corsicaId,
  userProfile,
  selectedClientId,
  handleSelectClient,
  handleContinue,
  handleHideTicketCreateModal,
}) => (
  <>
    <Modal.Header style={{ borderBottom: 'none' }}>
      <h2>Create New Support Ticket</h2>
    </Modal.Header>
    <Modal.Content>
      <FormSelect
        style={{ width: '100%', marginBottom: '15px' }}
        label="Who will be working this ticket?"
        placeholder="Select a team"
        options={[
          { text: 'Corsica Technologies', value: corsicaId },
          { text: userProfile.client_name, value: userProfile.client_id },
        ]}
        value={selectedClientId}
        onChange={(e, opt) =>
          handleSelectClient({
            selectedClientId: opt?.value,
            selectedClientName: opt?.text,
            alsoUpdateState: true,
          })
        }
      />
    </Modal.Content>
    <Modal.Actions>
      <Button primary disabled={true}>
        Create Ticket
      </Button>
      <Button className="ghost secondary" onClick={handleHideTicketCreateModal}>
        Cancel
      </Button>
    </Modal.Actions>
  </>
);

export default ContentSelectClient;
