import React, { useState, useEffect } from 'react';
import { Dashboard, ErrorHandler } from '../../api';
import { WidgetProgressRows } from '../';
import { roundPercent } from '../../utils/utils';
import { widgetClick } from '../../utils/analytics';

const colorChangeThresholds = {
  lessThan85: 'red',
  between85and95: 'orange',
  moreThan95: 'blue',
};

const getPercentColor = percent => {
  if (percent >= 95) {
    return colorChangeThresholds.moreThan95;
  } else if (percent >= 85 && percent < 95) {
    return colorChangeThresholds.between85and95;
  } else {
    return colorChangeThresholds.lessThan85;
  }
};

function WidgetEndpointProtectionStatus({ ...props }) {
  const [totalPercent, setTotalPercent] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // need to prevent attempting to set state on unmounted components
    let unmounted = false;

    const fetchData = async () => {
      let res;
      try {
        res = await Dashboard.endpointProtectionStatus();
      } catch (err) {
        ErrorHandler.error(err);
      }

      if (res?.body?.data && !unmounted) {
        const { data } = res.body;

        setTotalPercent(data.total_percent_current);

        const labelStyles = { fontSize: '18px', fontWeight: 'bold' };

        const _rows = [
          {
            subLabel: 'Workstations',
            labelStyles,
            percent: data.workstations_percent_current,
            labelLeft: `${roundPercent(data.workstations_percent_current)}%`,
            color: getPercentColor(data.workstations_percent_current),
            onClick: () =>
              widgetClick({
                link:
                  '/devices/endpoint-protection?device_category=Workstation',
                name: 'WidgetDevicesEndpointProtection-workstationClick',
              }),
          },
          {
            subLabel: 'Servers',
            labelStyles,
            percent: data.servers_percent_current,
            labelLeft: `${roundPercent(data.servers_percent_current)}%`,
            color: getPercentColor(data.servers_percent_current),
            onClick: () =>
              widgetClick({
                link: '/devices/endpoint-protection?device_category=Server',
                name: 'WidgetDevicesEndpointProtection-serverClick',
              }),
          },
        ];

        setRows(_rows);
      }
      if (!unmounted) {
        setLoading(false);
      }
    };
    fetchData();

    // on dismount this return callback gets called
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <WidgetProgressRows
      className="endpoint-protection-status"
      title="Endpoint Protection"
      subtitle="as of today"
      mainValue={`${roundPercent(totalPercent)}%`}
      mainValueOnClick={() =>
        widgetClick({
          link: '/devices/endpoint-protection',
          name: 'WidgetDevicesEndpointProtection-mainValueClick',
        })
      }
      mainLabel="Current"
      isLoading={loading}
      rows={rows}
      {...props}
    />
  );
}

export default WidgetEndpointProtectionStatus;
