import React from 'react';

import { distanceInWordsStrict, format } from 'date-fns';
import { Popup } from 'semantic-ui-react';

const RelativeTimeStamp = ({ time, displayFormat }) => (
  <Popup
    content={displayFormat ? format(time, displayFormat) : time}
    trigger={<span>{`${distanceInWordsStrict(time, new Date())} ago`}</span>}
    position="top center"
    size="mini"
  />
);

export default RelativeTimeStamp;
