export const Common = {
  REDIRECT_TO: 'REDIRECT_TO',
  REDIRECT: 'REDIRECT',
  PROFILE_SET: 'PROFILE_SET',
  CUSTOMER_CHANGE: 'CUSTOMER_CHANGE',
  POST_LOGIN_REDIRECT_URL_SET: 'POST_LOGIN_REDIRECT_URL_SET',
};

export const Toasts = {
  TOAST_ADD: 'TOAST_ADD',
  TOAST_REMOVE: 'TOAST_REMOVE',
};

export const Auth = {
  LOGIN_PENDING: 'Auth/LOGIN_PENDING',
  LOGIN_SUCCESS: 'Auth/LOGIN_SUCCESS',
  LOGIN_FAIL: 'Auth/LOGIN_FAIL',
  LOGIN_SECURE_PENDING: 'Auth/LOGIN_SECURE_PENDING',
  LOGIN_SECURE_SUCCESS: 'Auth/LOGIN_SECURE_SUCCESS',
  LOGIN_SECURE_FAIL: 'Auth/LOGIN_SECURE_FAIL',
  LOGIN_SECURE_CANCEL: 'Auth/LOGIN_SECURE_CANCEL',
  LOGIN_SECURE_REQUESTED: 'Auth/LOGIN_SECURE_REQUESTED',
  LOGOUT: 'Auth/LOGOUT',
  PROFILE_PENDING: 'Auth/PROFILE_PENDING',
  PROFILE_SUCCESS: 'Auth/PROFILE_SUCCESS',
  PROFILE_FAIL: 'Auth/PROFILE_FAIL',
  REDIRECT_TO_LOGIN_WITH_ERROR: 'REDIRECT_TO_LOGIN_WITH_ERROR',
};
export const Filters = {
  CHANGED: 'Filters/CHANGED',
  ADD: 'Filters/ADD',
  SET: 'Filters/SET',
  REMOVE: 'Filters/REMOVE',
  CLEAR: 'Filters/CLEAR',
  CLEAR_TYPE: 'Filters/CLEAR_TYPE',
  RESET: 'Filters/RESET',
};
export const DateFilters = {
  SET: 'DateFilters/SET',
  ADD: 'DateFilters/ADD',
  CLEAR: 'DateFilters/CLEAR',
};
export const Banner = {
  SHOW: 'Banner/SHOW',
  HIDE: 'Banner/HIDE',
};
export const Notification = {
  SHOW: 'Notification/SHOW',
  HIDE: 'Notification/HIDE',
};

export const BANNER_SHOW = 'BANNER_SHOW';
export const BANNER_HIDE = 'BANNER_HIDE';
