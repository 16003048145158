import React from 'react';

import { Button } from 'semantic-ui-react';
import { CreateTicketWrapper } from '../';

const CreateTicketButton = ({
  linkText,
  userFirstName,
  userLastName,
  ...otherProps
}) => (
  <CreateTicketWrapper>
    {({ onClick }) => (
      <Button {...otherProps} onClick={onClick}>
        {linkText || 'Create Ticket'}
      </Button>
    )}
  </CreateTicketWrapper>
);

export default CreateTicketButton;
