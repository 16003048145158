import React from 'react';

import { Dashboard, ErrorHandler } from '../../api';
import { Widget } from '../Widget/Widget';
import { DonutChart } from '../../components/';
import { shortenLargeNumbers } from '../../utils/utils';

import './WidgetPatching.scss';

export default class WidgetPatching extends React.Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      isLoading: false,
      totalCount: 0,
      declinedCount: 0,
      installedCount: 0,
      notInstalledCount: 0,
      pendingCount: 0,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });

    let res;
    try {
      res = await Dashboard.patchingCounts();
    } catch (err) {
      ErrorHandler.error(err);
    }

    const newState = { isLoading: false };

    if (res && res.body && res.body.data) {
      const {
        declined_patch_count,
        installed_patch_count,
        new_patch_count,
        queued_patch_count,
        missing_patch_count,
      } = res.body.data;
      newState.declinedCount = declined_patch_count;
      newState.installedCount = installed_patch_count;
      newState.newCount = new_patch_count;
      newState.queuedCount = queued_patch_count;
      newState.missingCount = missing_patch_count;
      newState.totalCount =
        declined_patch_count +
        installed_patch_count +
        new_patch_count +
        queued_patch_count +
        missing_patch_count;
    }

    this.safeSetState(newState);
  };

  render() {
    const {
      isLoading,
      declinedCount,
      installedCount,
      newCount,
      queuedCount,
      totalCount,
      missingCount,
    } = this.state;
    const chartData = [
      {
        name: 'New',
        value: newCount,
        className: 'new-count',
        key: 'new_patch_count',
      },
      {
        name: 'Queued for Install',
        value: queuedCount,
        className: 'queued-count',
        key: 'queued_patch_count',
      },
      {
        name: 'Installed',
        value: installedCount,
        className: 'installed',
        key: 'installed_patch_count',
      },
      {
        name: 'Missing',
        value: missingCount,
        className: 'missing-count',
        key: 'missing_patch_count',
      },
      {
        name: 'Declined',
        value: declinedCount,
        className: 'declined',
        key: 'declined_patch_count',
      },
    ];
    return (
      <Widget
        title="Patching"
        link="/devices/patching"
        widgetName="WidgetPatchingV2"
        isLoading={isLoading}
        emptyStateMessage="No patching data found."
        isEmpty={!totalCount}
        className="patching-widget height-2">
        <DonutChart
          size={180}
          data={chartData}
          centerCount={shortenLargeNumbers(totalCount)}
          centerTitle="Patches"
          dataKey="value"
          className="centered"
          startAngle={90}
          endAngle={-270}
        />
      </Widget>
    );
  }
}
