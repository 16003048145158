import React from 'react';
import { BaseWarningPage } from '../';

const IPBlockedWarning = () => {
  return (
    <BaseWarningPage
      className="IPBlockedWarning"
      title="IP Address Blocked"
      body="The IP address you are using has been blocked. If you feel like this is an error, please contact your account representative."></BaseWarningPage>
  );
};
export default IPBlockedWarning;
