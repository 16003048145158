import React, { useState, useEffect } from 'react';
import { ListWidget } from '..';
import { SecurityAwarenessTraining, usePromise } from '../../api';
import { widgetClick } from '../../utils/analytics';

export default function WidgetHighestRiskRecipients({
  className,
  ...otherProps
}) {
  const [rows, setRows] = useState([]);
  const { response, isLoading } = usePromise(
    SecurityAwarenessTraining.highestRiskRecipients
  );

  useEffect(() => {
    let newData = [];
    if (response?.body?.data?.length) {
      newData = response.body.data.map(r => ({
        text: r.recipient || r.email,
        value: `${r.phish_risk}%`,
        onClick: () => {
          widgetClick({
            link: `/security/security-awareness-training/${r.user_uuid}`,
            name: 'WidgetHighestRiskRecipients',
          });
        },
      }));
    }

    setRows(newData);
  }, [response]);

  return (
    <ListWidget
      {...otherProps}
      leftHeading="Recipient"
      rightHeading="Phish Risk"
      rows={5}
      columns={1}
      data={rows}
      title="Highest Risk Recipients"
      subtitle="as of today"
      className="WidgetHighestRiskRecipients"
      isLoading={isLoading}
      emptyStateMessage="No recent High Risk Recipients were found. Great work!"
    />
  );
}
