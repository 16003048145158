import React from 'react';

import { Button, Modal } from 'semantic-ui-react';

import './ConfirmModal.scss';

const ConfirmModal = ({
  open,
  heading,
  onConfirm,
  onClose,
  children,
  confirmProps,
  confirmText,
  confirmColor = 'blue',
  cancelProps,
  cancelText = 'Cancel',
  className,
  focus = 'cancel',
  ...otherProps
}) => (
  <Modal
    size="mini"
    open={open}
    onClose={onClose}
    className={['ConfirmModal', className].filter(f => !!f).join(' ')}
    {...otherProps}>
    <Modal.Header>
      <h2>{heading}</h2>
    </Modal.Header>
    <Modal.Content>
      <div className="container">
        {children}
        <div className="button-row">
          <Button
            color={confirmColor}
            onClick={onConfirm}
            {...confirmProps}
            focus={focus !== 'cancel' ? 'true' : 'false'}>
            {confirmText}
          </Button>
          <Button
            className="ghost secondary"
            onClick={onClose}
            focus={focus === 'cancel' ? 'true' : 'false'}>
            {cancelText}
          </Button>
        </div>
      </div>
    </Modal.Content>
  </Modal>
);

export default ConfirmModal;
