import React from 'react';
import { Link } from 'react-router-dom';
import queryParam from '../../utils/queryParams';

import { Dashboard, ErrorHandler } from '../../api';
import { Widget } from '../Widget/Widget';
import { numberWithCommas } from '../../utils';

import { colors } from '../../styles/utils';
import './WidgetRefreshes.scss';

export default class WidgetRefreshes extends React.Component {
  _isMounted = false;
  constructor() {
    super();

    this.state = {
      isLoading: true,
      criticalCount: null,
      overdueCount: null,
      within90DaysCount: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });

    let res;
    try {
      res = await Dashboard.refreshes();
    } catch (err) {
      ErrorHandler.error(err);
    }

    let criticalCount = null;
    let overdueCount = null;
    let within90DaysCount = null;

    if (res && res.body && res.body.data) {
      const {
        critical_count,
        overdue_count,
        due_within_90_days_count,
      } = res.body.data;
      criticalCount = numberWithCommas(critical_count);
      overdueCount = numberWithCommas(overdue_count);
      within90DaysCount = numberWithCommas(due_within_90_days_count);
    }
    this.safeSetState({
      isLoading: false,
      criticalCount,
      overdueCount,
      within90DaysCount,
    });
  };

  render() {
    const {
      criticalCount,
      overdueCount,
      within90DaysCount,
      isLoading,
    } = this.state;

    const categories = [
      {
        value: criticalCount,
        name: 'More than 1 year past due',
        link: '/devices/life-cycle',
        query: queryParam.stringify({
          refresh_status: 'Critical',
        }),
        color: colors.orange,
      },
      {
        value: overdueCount,
        name: '1 year past due',
        link: '/devices/life-cycle',
        query: queryParam.stringify({
          refresh_status: 'Overdue',
        }),
        color: colors.yellow,
      },
      {
        value: within90DaysCount,
        name: 'Due within 90 days',
        link: '/devices/life-cycle',
        query: queryParam.stringify({
          refresh_status: 'Upcoming',
        }),
        color: colors.primary,
      },
    ];
    const isEmpty =
      !overdueCount &&
      overdueCount !== 0 &&
      !criticalCount &&
      criticalCount !== 0 &&
      !within90DaysCount &&
      within90DaysCount !== 0;

    return (
      <Widget
        title="Device Refreshes"
        widgetName="WidgetRefreshes"
        isLoading={isLoading}
        isEmpty={isEmpty}
        emptyStateMessage="No device refresh data found."
        className="refreshes-widget height-2">
        <div className="utilization-table">
          <div className="table-heading">
            <span>Category</span>
            <span>Count</span>
          </div>
          <ul>
            {categories &&
              categories.map((category, i) => (
                <li style={{ color: category.color }} key={i}>
                  <Link
                    to={{ pathname: category.link, search: category.query }}>
                    {category.name}
                    <span>{category.value ? category.value : 0}</span>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </Widget>
    );
  }
}
