import React from 'react';
import { Progress, Icon } from 'semantic-ui-react';
import { Card } from '../';
import { formatPrecision } from '../../utils/utils';

import './Interfaces.scss';

const DECIMAL_PLACES = 1;

const Interface = ({
  device,
  color,
  onSelect,
  onHighlight,
  withHover = true,
}) => {
  const handleSelect = device => {
    if (onSelect) {
      onSelect(device);
    }
  };
  const handleHighlight = deviceId => {
    if (onHighlight) {
      onHighlight(deviceId);
    }
  };
  return (
    <div
      onMouseEnter={() => handleHighlight(device.interface_id)}
      onMouseLeave={() => handleHighlight(null)}
      className={`rightHighlight Interface ${color ? color : ''}${
        withHover ? ' withHover' : ''
      }`}
      onClick={() => handleSelect(device)}>
      <Card
        title={device.interface_name}
        subtitle={`(${device.downstream_speed_mbps ||
          '?'}/${device.upstream_speed_mbps || '?'} Mbps)`}>
        <div className="progress down">
          <Icon name="arrow down" />
          <span className="percent">{`${formatPrecision(
            device.average_downstream_utilization_percentage,
            DECIMAL_PLACES
          )}%`}</span>
          <Progress
            className="progressBar"
            percent={device.average_downstream_utilization_percentage}
            size="tiny"
            color={color === 'fuchsia' ? 'red' : color}
          />
        </div>
        <div className="progress up">
          <Icon name="arrow up" />
          <span className="percent">{`${formatPrecision(
            device.average_upstream_utilization_percentage,
            DECIMAL_PLACES
          )}%`}</span>
          <Progress
            className="progressBar"
            percent={device.average_upstream_utilization_percentage}
            size="tiny"
            color={color === 'fuchsia' ? 'red' : color}
          />
        </div>
      </Card>
    </div>
  );
};

const getColor = (selectedDevices, device) => {
  if (!selectedDevices) {
    return null;
  }
  const selected = selectedDevices.find(
    sd => sd.interface_id === device.interface_id
  );
  return selected && selected.color ? selected.color : null;
};

const Interfaces = ({
  data,
  selectedDevices,
  onDeviceSelect,
  onHighlight,
  title,
  subtitle,
  isLoading,
}) => {
  return (
    <Card
      isLoading={isLoading}
      title={title}
      subtitle={subtitle}
      className="Interfaces">
      {data &&
        data.map((device, index) => (
          <Interface
            device={device}
            onHighlight={onHighlight}
            color={getColor(selectedDevices, device)}
            onSelect={onDeviceSelect}
            key={index}
          />
        ))}
    </Card>
  );
};

export { Interfaces, Interface };
export default Interfaces;
