import React, { useEffect } from 'react';

import { Dashboard, usePromise } from '../../api';
import { Card } from '../';
import { formatPhone } from '../../utils/utils';

import './WidgetClientTeam.scss';

function WidgetClientTeam({ setWidth, setHeight, setHidden }) {
  const { response } = usePromise(Dashboard.clientTeam);

  const contacts = response?.body?.data || [];
  const count = contacts?.length || 0;
  /* from SCP-4932
   1 contact - small widget width
   2 contacts - small widget widget, double high
   3 contacts - medium widget width, 3 across
   4 contacts - medium widget width 3 top row, 1 bottom row
   5 contacts - medium widget width 3 top row, 2 bottom row
   6 contacts - medium widget width 3 top row, 3 bottom row
   7+ contacts - follow pattern above and keep increasing height
  */

  const baseWidth = 260;

  useEffect(() => {
    const baseHeight = 100;
    const gapWidth = 20;
    const minHeight = baseHeight + 40; // account for the card title when its only 1 heigh.
    let calculatedWidth = baseWidth;
    let calculatedHeight = minHeight;

    if (response) {
      if (count === 0) {
        setHidden(true);
      } else if (count === 2) {
        calculatedHeight = 2 * baseHeight + gapWidth;
        calculatedWidth = baseWidth;
      } else if (count > 2) {
        const rows = Math.ceil(count / 3);
        calculatedHeight = rows * baseHeight + (rows - 1) * gapWidth;
        calculatedWidth = 3 * baseWidth + 2 * gapWidth;
      }
    }

    calculatedHeight =
      calculatedHeight > minHeight ? calculatedHeight : minHeight;

    setWidth(calculatedWidth);
    setHeight(calculatedHeight);
  }, [count, setHeight, setWidth, setHidden, response]);

  return (
    <Card className="WidgetClientTeam" title="Corsica Team">
      {contacts?.map?.((contact, i) => (
        <ContactBlock {...contact} width={baseWidth} key={'contact-' + i} />
      ))}
    </Card>
  );
}

export default WidgetClientTeam;

function ContactBlock({ name, role, phone, email, width, ...otherProps }) {
  const formattedPhone =
    phone && typeof phone === 'string' ? formatPhone(phone) : '';

  return (
    <div
      {...otherProps}
      className="ContactBlock"
      style={{ width: `${width}px` }}>
      <div className="name">{name}</div>
      <div className="title">{role}</div>
      <div className="phone">{formattedPhone}</div>
      <div className="email">
        <a href={`mailto:${email}`}>{email}</a>
      </div>
    </div>
  );
}
