import React, { Component } from 'react';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import { Toast } from './Toast';
import { AppContext } from '../../AppContext';

class ToastContainer extends Component {
  render() {
    return (
      <TransitionGroup
        enter={true}
        exit={true}
        appear={true}
        className="toast-container">
        {this.props.toasts &&
          this.props.toasts
            .slice(0)
            .reverse()
            .map(t => (
              <Toast key={t.id} id={t.id} type={t.type} duration={t.duration}>
                {t.message}
              </Toast>
            ))}
      </TransitionGroup>
    );
  }
}

const WrappedToastContainer = props => (
  <AppContext.Consumer>
    {({ toasts }) => <ToastContainer {...props} toasts={toasts} />}
  </AppContext.Consumer>
);

export { WrappedToastContainer as ToastContainer };
