import { environment } from '../environment';
import { Analytics } from '../analytics';
import mixpanel from './mixpanel';
import { history } from '../store';
import bugsnagClient from './bugsnag';
import { getSessionKey, sessionKeys, setSessionKey } from '../ManageCache';

export const eventTypes = {
  SIGN_OUT: 'signOut',
  SIGN_IN: 'signIn',
  TIME_OUT: 'timeOut',
  LOAD_PAGE: 'loadPage',
  APPLY_FILTER: 'applyFilter',
  OPEN_MODAL: 'openModal',
  CLOSE_MODAL: 'closeModal',
  TAB_SELECT: 'tabSelect',
  TABLE_SORT: 'tableSort',
  TEXT_FILTER: 'textFilter',
  SELECT_ITEM: 'selectItem',
  DESELECT_ITEM: 'deselectItem',
  DROPDOWN_SELECT: 'dropdownSelect',
  VIEW_TICKET: 'viewTicket',
  VIEW_TICKET_NOT_FOUND: 'viewTicketNotFound',
  CREATE_TICKET: 'createTicket',
  GET_HELP: 'getHelp',
  VIEW_TICKET_FROM_EMAIL: 'viewTicketFromEmail',
  VIEW_TICKET_FROM_EMAIL_NOT_FOUND: 'viewTicketFromEmailNotFound',
  WIDGET_CLICK: 'widgetClick',
  /* Reports view events */
  REPORT_SELECTED: 'reportSelected',
  REPORT_SNAPSHOT_SELECTED: 'reportSnapshotDateSelected',
  REPORT_START_DATE_SELECTED: 'reportStartDateSelected',
  REPORT_END_DATE_SELECTED: 'reportEndDateSelected',
  REPORT_GENERATED: 'reportGenerated',
  /* SPARK */
  SPARK_UPDATE_TICKET_DETAILS: 'sparkUpdateTicket',
  SPARK_CREATE_TICKET: 'sparkCeateTicket',
  SPARK_TRANSFER_TO_CORSICA: 'sparkTransferToCorsica',
};

export const createPreSession = ({ ip, ticketId }) => {
  const startTime = new Date();

  return Analytics.preLogin({
    source_ticket_id: ticketId,
    app_version: 2,
    app_environment: environment.current.name,
    start_time: startTime.toISOString(),
    ip_address: ip,
    user_agent: window.navigator.userAgent,
    viewport_size:
      (document.documentElement.clientWidth || '0') +
      'x' +
      (document.documentElement.clientHeight || '0'),
  }).then(res => {
    if (res?.body?.session_id) {
      setSessionKey(sessionKeys.session_id, res.body.session_id);
      setSessionKey(sessionKeys.session_start, startTime.toISOString());
    }
  });
};

export const updateSession = ({ userId, sessionId }) => {
  const ip = getSessionKey(sessionKeys.session_ip);
  const startTime = getSessionKey(sessionKeys.session_start);

  return Analytics.postLogin({
    id: sessionId,
    app_version: 2,
    app_environment: environment.current.name,
    start_time: startTime,
    ip_address: ip,
    user_agent: window.navigator.userAgent,
    viewport_size:
      (document.documentElement.clientWidth || '0') +
      'x' +
      (document.documentElement.clientHeight || '0'),
    user_id: userId,
  }).then(res => {
    if (res?.body?.session_id) {
      setSessionKey(sessionKeys.session_id);
    }
  });
};

export const createSession = userId => {
  const ip = getSessionKey(sessionKeys.session_ip);
  const startTime = new Date();

  return Analytics.createSession({
    app_version: 2,
    app_environment: environment.current.name,
    start_time: startTime.toISOString(),
    ip_address: ip,
    user_agent: window.navigator.userAgent,
    viewport_size:
      (document.documentElement.clientWidth || '0') +
      'x' +
      (document.documentElement.clientHeight || '0'),
    user_id: userId,
  }).then(res2 => {
    if (res2 && res2.body && res2.body.session_id) {
      setSessionKey(sessionKeys.session_id, res2.body.session_id);
    }
  });
};

export const endSession = async () => {
  const sessionId = getSessionKey(sessionKeys.session_id);
  const time = new Date();

  if (!sessionId) {
    return false;
  }

  try {
    const msg = await Analytics.sessionEnd({
      end_time: time.toISOString(),
      id: sessionId,
    });
    return msg;
  } catch (err) {
    bugsnagClient.leaveBreadcrumb('Analytics - end session failed');
    bugsnagClient.notify(err);
  }
};

export const sendWindowClosed = async () => {
  const sessionId = getSessionKey(sessionKeys.session_id);
  const time = new Date();

  if (!sessionId) {
    return false;
  }

  try {
    const msg = await Analytics.windowClosed({
      event_time: time.toISOString(),
      portal_session_id: sessionId,
    });
    return msg;
  } catch (err) {
    bugsnagClient.leaveBreadcrumb('Analytics - send window closed failed');
    bugsnagClient.notify(err);
  }
};

export const createEvent = async ({ route, action, detail }) => {
  const sessionId = getSessionKey(sessionKeys.session_id);

  mixpanel.track(action, { route, detail });
  if (action === 'signOut') {
    mixpanel.reset();
  }

  if (!sessionId) {
    return false;
  }

  const time = new Date();

  try {
    const eventId = await Analytics.createEvent({
      current_view: route || window.location.pathname,
      event_time: time.toISOString(),
      event_action: action || null,
      event_detail: detail || null,
      portal_session_id: sessionId,
    });
    return eventId;
  } catch (err) {
    bugsnagClient.leaveBreadcrumb('Analytics - create event failed');
    bugsnagClient.notify(err);
  }
};

export const widgetClick = ({ link, name }) => {
  const detail = `${link}${link.indexOf('?') === -1 ? '?' : '&'}ref=${name}`;
  createEvent({
    action: eventTypes.WIDGET_CLICK,
    detail,
  });
  history.push(link);
};
