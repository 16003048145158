import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { CountDown } from '../';

import './WarnIdleUserDialog.scss';

export const WarnIdleUserDialog = ({
  open,
  onClose,
  onDontLogout,
  onLogout,
}) => {
  return (
    <Modal
      open={open}
      size="mini"
      onClose={onClose}
      className="WarnIdleUserDialog">
      <Modal.Header>
        You are about to be signed out due to inactivity, would you like to
        remain signed in?
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <CountDown seconds={60} />
        </Modal.Description>
        <Modal.Actions>
          <Button onClick={onDontLogout} className="primary">
            Stay Signed in
          </Button>
          <Button onClick={onLogout}>Sign out</Button>
        </Modal.Actions>
      </Modal.Content>
    </Modal>
  );
};

export default WarnIdleUserDialog;
