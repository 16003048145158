import React from 'react';
import { Icon } from 'semantic-ui-react';

import './StatusBanner.scss';

const getIcon = type => {
  const icon = {};
  switch (type) {
    case 'info':
      icon.name = 'info circle';
      icon.color = 'blue';
      break;
    case 'error':
      icon.name = 'times circle';
      icon.color = 'red';
      break;
    case 'warning':
      icon.name = 'warning sign';
      icon.color = 'orange';
      break;
    case 'success':
      icon.name = 'check circle';
      icon.color = 'green';
      break;
    case 'question':
      icon.name = 'question circle';
      icon.color = 'gray';
      break;
    default:
      icon.name = 'info circle';
      icon.color = 'gray';
  }
  return icon;
};

const StatusBanner = ({ type, iconName, message, color, ...otherProps }) => {
  const icon = getIcon(type);

  return (
    <div
      {...otherProps}
      className={['StatusBanner', color || icon?.color]
        .filter(f => !!f)
        .join(' ')}>
      <Icon name={iconName || icon?.name} />
      <span>{message}</span>
    </div>
  );
};

export default StatusBanner;
