export const colorsNEW = {
  blue: '#2b5597',
  red: '#CE3D3D',
  lightRed: '#e69e9e',
  // Grays
  black: '#262c2c',
  mediumGray: '#AEB9B9',
  lightGray: '#DFE4E4',
};

export const colors = {
  blue: '#2b5597',
  darkBlue: '#375d70',
  teal: '#4f7f82',
  salmon: '#f2664c',
  orange: '#E74A2C',
  lightOrange: '#e77a2c',
  red: '#a54450',
  fuchsia: '#cc1d5f',
  green: '#45a041',
  yellow: '#ffae02',
  mutedSlate: '#4d5858',

  // Donut Colors
  color0: '#577590',
  color1: '#43AA8B',
  color2: '#90BE6D',
  color3: '#F9C74F',
  color4: '#F8961E',
  color5: '#F3722C',

  // Grays
  black: '#262c2c',
  gray: '#5f6972',
  mediumGray: '#4d5858',
  mediumLightGray: '#dfdfdf',
  mediumLightGray2: '#dee2e5',
  mediumLightGray3: '#eee',
  mediumMediumGray: '#b2b2b2',
  lightGray: '#f5f5f5',
  lightGray2: '#cccfd2',

  // one-off's
  upsellBackground: '#464646',
  seatsBarChartLight: '#dce0e6',
  seatsBarChartDark: '#b8bdc4',
  faintError: '#fff6f6',

  get primary() {
    return this.blue;
  },
  get secondary() {
    return this.teal;
  },
  get accent() {
    return this.salmon;
  },
  get success() {
    return this.green;
  },
  get danger() {
    return this.red;
  },
  get info() {
    return this.blue;
  },
  get textgray() {
    return this.gray;
  },
};
