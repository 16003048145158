import React from 'react';
import { Modal } from 'semantic-ui-react';

import ContentSparkCorsicaCreate from './ContentSparkCorsicaCreate';
import ContentSparkClientCreate from './ContentSparkClientCreate';
import ContentTicketSubmitted from './ContentTicketSubmitted';
import ContentConfirmAction from './ContentConfirmAction';
import ContentTicketCreate from './ContentTicketCreate';
import ContentSelectClient from './ContentSelectClient';

import './AddTicketModal.scss';

const AddTicketModal = ({
  isVisibleCreateTicketModal,
  componentState,
  COMPONENT_STATES,
  ...otherProps
}) => (
  <Modal
    className="AddTicketModal"
    onClose={otherProps.handleSoftCancel}
    open={isVisibleCreateTicketModal}
    size="tiny">
    {componentState === COMPONENT_STATES.SUBMITTED && (
      <ContentTicketSubmitted {...otherProps} />
    )}
    {componentState === COMPONENT_STATES.CONFIRM && (
      <ContentConfirmAction {...otherProps} />
    )}
    {componentState === COMPONENT_STATES.CREATE_SPARK_CORSICA && (
      <ContentSparkCorsicaCreate
        isError={componentState === 'error'}
        {...otherProps}
      />
    )}
    {componentState === COMPONENT_STATES.CREATE_SPARK_CLIENT && (
      <ContentSparkClientCreate
        isError={componentState === 'error'}
        {...otherProps}
      />
    )}
    {componentState === COMPONENT_STATES.CREATE_SPARK && (
      <ContentTicketCreate
        isError={componentState === 'error'}
        {...otherProps}
      />
    )}
    {componentState === COMPONENT_STATES.CREATE && (
      <ContentTicketCreate
        isError={componentState === 'error'}
        {...otherProps}
      />
    )}
    {componentState === COMPONENT_STATES.SPARK_SELECT_OWNER && (
      <ContentSelectClient
        isError={componentState === 'error'}
        {...otherProps}
      />
    )}
  </Modal>
);

export default AddTicketModal;
