import React, { Component } from 'react';
import { Switch, Link, Route } from 'react-router-dom';
import { Card } from '../';

import './CardWithTabs.scss';

/**
 * A component similar to Card, just with Tabs
 * @param {Object[]} tabs - The array of tab objects
 * @param {Object} tabs[].title - The title of the tab
 *
 *
 * */

export class CardWithTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabIndex: props.selectedTab || 0,
    };
  }

  onTabSelect = tabIndex => this.setState({ selectedTabIndex: tabIndex });

  render() {
    const { tabs, className, isLoading, ...rest } = this.props;
    const { selectedTabIndex } = this.state;

    const selectedTab = tabs[selectedTabIndex];
    return (
      <div
        className={`CardWithTabs${className ? ` ${className}` : ''}`}
        {...rest}>
        <div className="tab-container">
          {tabs &&
            tabs.map((tab, i) => (
              <Tab
                key={i}
                title={tab.tabTitle}
                className={tab.tabClassName}
                selected={i === selectedTabIndex}
                onClick={() => this.onTabSelect(i)}
              />
            ))}
        </div>
        <Card
          title={selectedTab.cardTitle}
          subtitle={selectedTab.cardSubtitle}
          isLoading={isLoading}
          emptyStateMessage={selectedTab.emptyStateMessage}>
          {selectedTab.content}
        </Card>
      </div>
    );
  }
}

export default class CardWithTabsWithRouting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabIndex: props.selectedTab || 0,
    };
  }

  render() {
    const { tabs, className, isLoading, ...rest } = this.props;

    return (
      <div
        className={`CardWithTabs${className ? ` ${className}` : ''}`}
        {...rest}>
        <div className="tab-container">
          {tabs &&
            tabs.map((tab, i) =>
              tab.route === window.location.pathname ? (
                <Tab
                  key={i}
                  title={tab.tabTitle}
                  className={tab.tabClassName}
                  selected={tab.route === window.location.pathname}
                />
              ) : (
                <Link key={i} to={tab.route}>
                  <Tab
                    title={tab.tabTitle}
                    className={tab.tabClassName}
                    selected={tab.route === window.location.pathname}
                  />
                </Link>
              )
            )}
        </div>
        <Switch>
          {tabs &&
            tabs.map((tab, i) => (
              <Route key={i} path={tab.route} exact>
                <Card
                  title={tab.cardTitle}
                  subtitle={tab.cardSubtitle}
                  isLoading={isLoading}
                  emptyStateMessage={tab.emptyStateMessage}>
                  {tab.content}
                </Card>
              </Route>
            ))}
        </Switch>
      </div>
    );
  }
}

const Tab = ({ title, className, selected, ...otherProps }) => (
  <div
    {...otherProps}
    className={`Tab ${selected ? 'selected' : ''} ${
      className ? className : ''
    }`}>
    {title}
  </div>
);
