import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import {
  isAuthorized,
  isUpsell,
  isNew,
  FEATURE_LIST,
  SECTIONS,
  activeFeatures,
} from '../../permissions';
import { AppContext } from '../../AppContext';
import { environment } from '../../environment';
import { Pill } from '../../components/';

import './Sidebar.scss';

/**
 * Accepts a feature, and returns bool based on if the user can see the feature either
 * because it is an upsell feature, or because it is an active feature
 * @param {String} feature - the name of a feature
 * @returns {Boolean} user is or isn't allowed to see feature
 */
const canSeeFeature = feature => isAuthorized(feature) || isUpsell(feature);

/**
 * Accepts an array of features and returns true if the current user has access to at least one of the features.
 * @param {String[]} features the features in the section we're checking
 * @returns {Boolean} whether or not the current user can access at least one of the features passed
 */
const canSeeSection = features =>
  Array.isArray(features) && features.some(canSeeFeature);

export function Sidebar({ selectedCustomer, imitateClientId, ...otherProps }) {
  const features = activeFeatures() || [];
  const activeFeatureCount = features ? features.length : 0;

  const areAllTabsOpen = activeFeatureCount <= 10;

  const [activeTab, setActiveTab] = useState('/dashboard');
  const [activeSections, setActiveSections] = useState([]);

  useEffect(() => {
    setActiveSections([]);
  }, [
    // handle a selected customer change upstream
    selectedCustomer,

    // handle a switch to imitate client upstream
    imitateClientId,
    activeTab,
  ]);

  // sometimes the URL changes, so we need to update to make sure we are displaying the correct current location
  let locationParts =
    window.location.pathname.split('/').filter(f => !!f) || [];

  locationParts = locationParts.slice(0, 2);
  const currentTab = `/${locationParts.join('/')}`;

  if (activeTab !== currentTab) {
    setActiveTab(currentTab);
  }

  const handleClick = section => {
    let newSet = [];

    if (activeSections.includes(section)) {
      // remove it
      newSet = activeSections.filter(s => s !== section);
    } else {
      // add it
      newSet = [...activeSections, section];
    }
    setActiveSections(newSet);
  };

  const SidebarFeature = props => (
    <SidebarFeatureUncomposed
      {...props}
      canSeeFeature={canSeeFeature}
      setSelectedTab={setActiveTab}
    />
  );
  const SidebarSection = props => (
    <SidebarSectionUncomposed
      {...props}
      activeSections={activeSections}
      areAllTabsOpen={areAllTabsOpen}
      canSeeSection={canSeeSection}
      onClick={handleClick}
      activeTab={activeTab}
    />
  );

  return (
    <section id="sidebar" className="app sidebar">
      <div className="brand">
        <img
          alt="Corsica Technologies"
          src={`${process.env.REACT_APP_CDN_URL}/assets/images/CorsicaTech_Logo_Color.svg`}
        />
        <EnvBadge />
        <nav className="main">
          <SidebarFeature
            permission={FEATURE_LIST.DASHBOARD}
            link="/dashboard"
            title="Dashboard"
          />
          <SidebarFeature
            permission={FEATURE_LIST.ONBOARDING_ROADMAP}
            link="/onboarding-roadmap"
            title="Onboarding Roadmap"
          />
          <SidebarFeature
            permission={FEATURE_LIST.MY_TICKETS}
            link="/my-tickets"
            title="My Tickets"
          />
          <SidebarFeature
            permission={FEATURE_LIST.DOCUMENTS}
            link="/documents"
            title="Documents"
          />
          <SidebarSection
            name="MyOrganization"
            permission={SECTIONS.MY_ORGANIZATION}
            title="My Organization">
            <SidebarFeature
              permission={FEATURE_LIST.SUPPORT_OVERVIEW}
              link="/my-organization/support-overview"
              title="Support Overview"
            />
            <SidebarFeature
              permission={FEATURE_LIST.SECURITY_POSTURE}
              link="/my-organization/security-posture"
              title="Security Posture"
            />
            <SidebarFeature
              permission={FEATURE_LIST.TICKETS}
              link="/my-organization/tickets"
              title="Tickets"
            />
            <SidebarFeature
              permission={FEATURE_LIST.TECH_ROADMAP}
              link="/my-organization/technology-roadmap"
              title="Technology Roadmap"
            />
            <SidebarFeature
              permission={FEATURE_LIST.ACTIVE_DIRECTORY}
              link="/my-organization/active-directory"
              title="Active Directory Accounts"
            />
            <SidebarFeature
              permission={FEATURE_LIST.REPORTS}
              link="/my-organization/reports"
              title="Reports"
            />
          </SidebarSection>

          <SidebarSection
            name="Security"
            permission={SECTIONS.SECURITY}
            title="Security">
            <SidebarFeature
              permission={FEATURE_LIST.SECURITY_INCIDENTS}
              link="/security/incidents"
              title="Incidents"
            />
            <SidebarFeature
              permission={FEATURE_LIST.SECURITY_REMEDIATIONS}
              link="/security/remediations"
              title="Remediations"
            />
            <SidebarFeature
              permission={FEATURE_LIST.SECURITY_AWARENESS_TRAINING}
              link="/security/security-awareness-training"
              title="Security Awareness Training"
            />
            <SidebarFeature
              permission={FEATURE_LIST.DARK_WEB_MONITORING}
              link="/security/dark-web-monitoring"
              title="Dark Web Monitoring"
            />
          </SidebarSection>

          <SidebarSection
            name="Devices"
            permission={SECTIONS.DEVICES}
            title="Devices">
            <SidebarFeature
              permission={FEATURE_LIST.DEVICES_OVERVIEW}
              link="/devices/overview"
              title="Overview"
            />
            <SidebarFeature
              permission={FEATURE_LIST.DEVICES_LIST}
              link="/devices/list"
              title="Devices List"
            />
            <SidebarFeature
              permission={FEATURE_LIST.SEATS}
              link="/devices/seats"
              title="Seats"
            />
            <SidebarFeature
              permission={FEATURE_LIST.PATCHING_V2}
              link="/devices/patching"
              title="Patching"
            />
            <SidebarFeature
              permission={FEATURE_LIST.ENDPOINT_PROTECTION}
              link="/devices/endpoint-protection"
              title="Endpoint Protection"
            />
            <SidebarFeature
              permission={FEATURE_LIST.VULNERABILITIES}
              link="/devices/vulnerabilities"
              title="Vulnerabilities"
            />
            <SidebarFeature
              permission={FEATURE_LIST.VULNERABILITIES_BASIC}
              link="/devices/vulnerabilities-basic"
              title={`Vulnerabilities${
                canSeeFeature(FEATURE_LIST.VULNERABILITIES_BASIC) &&
                canSeeFeature(FEATURE_LIST.VULNERABILITIES)
                  ? ' (Basic)'
                  : ''
              }`}
            />
            <SidebarFeature
              permission={FEATURE_LIST.DNS_ACTIVITY}
              link="/devices/dns-activity"
              title="DNS Activity"
            />
            <SidebarFeature
              permission={FEATURE_LIST.UTILIZATION}
              link="/devices/utilization"
              title="Server Utilization"
            />
            <SidebarFeature
              permission={FEATURE_LIST.BACKUPS}
              link="/devices/backups"
              title="Backups"
            />
            <SidebarFeature
              permission={FEATURE_LIST.DEVICE_LIFE_CYCLE}
              link="/devices/life-cycle"
              title="Life Cycle"
            />
          </SidebarSection>

          <SidebarSection
            name="Network"
            permission={SECTIONS.NETWORK_EVENTS}
            title="Network">
            <SidebarFeature
              permission={FEATURE_LIST.NETWORK_THREATS}
              link="/network-events/network-threats"
              title="Threats Blocked"
            />
            <SidebarFeature
              permission={FEATURE_LIST.VPN_ACTIVITY}
              link="/network-events/vpn-activity"
              title="VPN Activity"
            />
            <SidebarFeature
              permission={FEATURE_LIST.CONFIGURATION_CHANGES}
              link="/network-events/configuration-changes"
              title="Configuration Changes"
            />
            <SidebarFeature
              permission={FEATURE_LIST.FAILED_LOGINS}
              link="/network-events/failed-logins"
              title="Device Failed Logins"
            />
            <SidebarFeature
              permission={FEATURE_LIST.INTERNET_UTILIZATION}
              link="/network-events/internet-utilization"
              title="Internet Utilization"
            />
          </SidebarSection>

          <SidebarSection
            name="Admin"
            title="Admin"
            permission={SECTIONS.ADMIN}>
            <SidebarFeature
              permission={FEATURE_LIST.USER_MANAGEMENT}
              link="/admin/users"
              title="Users"
            />
            <SidebarFeature
              permission={FEATURE_LIST.CLIENT_SETTINGS}
              link="/admin/client-settings"
              title="Client Settings"
            />
          </SidebarSection>

          <SidebarSection
            name="SelfService"
            permission={SECTIONS.SELF_SERVICE}
            title="Self Service">
            <SidebarFeature
              title="Security Change Requests"
              permission={FEATURE_LIST.SELF_SERVICE}
              link="/self-service/security-change-requests"
            />
          </SidebarSection>
        </nav>
      </div>
    </section>
  );
}

const WrappedSidebar = props => (
  <AppContext.Consumer>
    {({ imitateClientId, selectedCustomer }) => (
      <Sidebar
        {...props}
        imitateClientId={imitateClientId}
        selectedCustomer={selectedCustomer}
      />
    )}
  </AppContext.Consumer>
);
export default WrappedSidebar;

const SidebarSectionUncomposed = ({
  permission,
  title,
  children,
  className,
  onClick,
  name,
  canSeeSection,
  areAllTabsOpen,
  activeSections,
  activeTab,
  ...otherProps
}) => {
  const sectionNames = {
    MyOrganization: ['dashboard', 'my-organization'],
    Security: ['security'],
    Devices: ['devices'],
    Network: ['network-events'],
    Admin: ['admin'],
    SelfService: ['self-services'],
  };

  // note singular here, not the plural passed from above.
  const activeSection = activeTab?.split?.('/')?.[1];

  const expanded =
    areAllTabsOpen ||
    activeSections.includes(name) ||
    sectionNames[name].includes(activeSection);

  return canSeeSection(permission) ? (
    <section
      className={`${className || ''} ${expanded ? 'expanded' : ''}`}
      {...otherProps}>
      <label onClick={() => onClick(name)}>
        {title}
        <Icon name={`chevron ${expanded ? 'up' : 'down'}`} />
      </label>
      {expanded && children}
    </section>
  ) : null;
};

const SidebarFeatureUncomposed = ({
  permission,
  title,
  link,
  setSelectedTab,
  canSeeFeature,
}) =>
  canSeeFeature(permission) ? (
    <NavLink
      to={link}
      onClick={() => {
        setSelectedTab(link);
      }}>
      {title}
      {isNew(permission) && <Pill text="NEW" color="green" />}
    </NavLink>
  ) : null;

const EnvBadge = () => {
  const envName = environment.current.name;
  // hidden for 'production' or anything unexpected
  const isVisible = [
    'staging',
    'development',
    'testing',
    'e2eTesting',
    'demo',
  ].includes(envName);
  return (
    isVisible && (
      <div className={`EnvBadge ${envName}`}>
        <span>{isVisible && envName.toUpperCase()}</span>
      </div>
    )
  );
};
