import React from 'react';

import { Dashboard, ErrorHandler } from '../../api';
import { Widget } from '../Widget/Widget';
import { DonutChart } from '../../components/';
import { shortenLargeNumbers } from '../../utils/utils';

import './WidgetVulnerabilities.scss';

export default class WidgetVulnerabilities extends React.Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      isLoading: false,
      totalCount: 0,
      easyCount: 0,
      moderateCount: 0,
      difficultCount: 0,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });

    let res;
    try {
      res = await Dashboard.vulnerableDevices();
    } catch (err) {
      ErrorHandler.error(err);
    }

    const newState = { isLoading: false };

    if (res && res.body && res.body.data) {
      const {
        device_exploitable_by_novice_count,
        device_exploitable_by_intermediate_count,
        device_exploitable_by_expert_count,
        exploitable_device_count,
      } = res.body.data;
      newState.easyCount = device_exploitable_by_novice_count || 0;
      newState.moderateCount = device_exploitable_by_intermediate_count || 0;
      newState.difficultCount = device_exploitable_by_expert_count || 0;
      newState.totalCount = exploitable_device_count || 0;
    }

    this.safeSetState(newState);
  };

  render() {
    const {
      isLoading,
      easyCount,
      difficultCount,
      moderateCount,
      totalCount,
    } = this.state;
    const chartData = [
      {
        name: 'Easy',
        value: easyCount,
        className: 'easy-count',
        key: 'easy_count',
        legendOrder: 1,
      },
      {
        name: 'Moderate',
        value: moderateCount,
        className: 'moderate-count',
        key: 'moderate_count',
        legendOrder: 2,
      },
      {
        name: 'Difficult',
        value: difficultCount,
        className: 'difficult-count',
        key: 'difficult_count',
        legendOrder: 3,
      },
    ];
    return (
      <Widget
        title="Vulnerable Devices"
        subtitle="by ease of exploit"
        link="/devices/vulnerabilities"
        widgetName="WidgetVulnerabilities"
        isLoading={isLoading}
        emptyStateMessage="No vulnerable device data found."
        isEmpty={!totalCount}
        className="vulnerabilities-widget height-2">
        <DonutChart
          size={180}
          data={chartData}
          centerCount={shortenLargeNumbers(totalCount)}
          centerTitle="Devices"
          dataKey="value"
          className="centered"
          startAngle={90}
          endAngle={-270}
        />
      </Widget>
    );
  }
}
