import React from 'react';

import { Dashboard, ErrorHandler } from '../../api';
import { Widget } from '../Widget/Widget';
import { numberWithCommas } from '../../utils';
import ShortDashWidget from '../ShortDashWidget/ShortDashWidget';

export default class WidgetServiceTickets extends React.Component {
  _isMounted = false;
  constructor() {
    super();

    this.state = {
      isLoading: true,
      ticketCount: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });

    let res;
    try {
      res = await Dashboard.serviceTickets();
    } catch (err) {
      ErrorHandler.error(err);
    }

    let ticketCount = null;
    if (res && res.body && res.body.data.total_ticket_count) {
      ticketCount = numberWithCommas(res.body.data.total_ticket_count);
    }
    this.safeSetState({
      isLoading: false,
      ticketCount,
    });
  };

  render() {
    const { ticketCount, isLoading } = this.state;
    return (
      <Widget
        link="/my-organization/tickets"
        widgetName="WidgetServiceTickets"
        isEmpty={!ticketCount && ticketCount !== 0}
        title="Support Tickets"
        subtitle="in last 90 days"
        isLoading={isLoading}
        emptyStateMessage="No ticket data found."
        className="tickets-widget widget-loader-right height-grow">
        <ShortDashWidget number={ticketCount || 0} />
      </Widget>
    );
  }
}
