import { DirectLine } from 'botframework-directlinejs';
import React, { useState, useEffect } from 'react';
import ReactWebChat from 'botframework-webchat';
import { Modal, Button } from 'semantic-ui-react';
import { AppContext } from '../../AppContext';
import { Clients, ErrorHandler } from '../../api';
import { eventTypes, createEvent } from '../../utils/analytics';

import './ChatBot.scss';

class ChatBot extends React.Component {
  _userID = '';
  _isMounted = false;
  constructor(props) {
    super(props);

    const timestamp = Date.now();

    this.state = { isOpen: false, token: null, timestamp };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { userProfile, selectedCustomer } = this.props;

    var user = userProfile;
    if (!user) {
      if (window.localStorage.getItem('userProfile')) {
        user = JSON.parse(window.localStorage.getItem('userProfile'));
      }
    }

    var settings = {
      customContext: {
        ContactFirst: {
          value: user?.first_name,
          isDisplayable: true,
        },
        ContactLast: {
          value: user?.last_name,
          isDisplayable: true,
        },
        ContactMail: { value: user?.email, isDisplayable: true },
        ContactId: {
          value: user?.external_details.connectwise.contact_id,
          isDisplayable: true,
        },
        CompanyId: { value: user?.external_details.connectwise.account_id },
        CompanyTeam: { value: selectedCustomer.territory, isDisplayable: true },
      },
    };
    function contextProvider() {
      return settings;
    }

    console.log('settings: ');
    console.log(settings);

    console.log('contextProvider:');
    console.log(contextProvider());

    window.Microsoft.Omnichannel.LiveChatWidget.SDK.setContextProvider(
      contextProvider
    );
    window.Microsoft.Omnichannel.LiveChatWidget.SDK.startChat(settings);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedCustomer?.id !== this.props.selectedCustomer?.id) {
      this._fetch();
    }
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _getBotFeatureId = async () => {
    let res;
    try {
      res = await Clients.getFeatures(this.props.selectedCustomer.id);
    } catch (err) {
      ErrorHandler.error(err);
    }
    return res?.body?.find(f => f.name === 'chatbot')?.integrations?.[0]?.id;
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });

    const { selectedCustomer } = this.props;

    const integrationId = await this._getBotFeatureId();

    let res;
    try {
      res = await Clients.getClientIntegration({
        clientId: selectedCustomer?.id,
        integrationId,
      });
    } catch (err) {
      ErrorHandler.error(err);
    }

    let token = null;

    if (res?.body?.fields?.length) {
      token = res.body.fields[0].value;
    }
    this.safeSetState({ token, isLoading: false });
  };

  handleOpen = () =>
    this.safeSetState({ isOpen: true }, () => {
      createEvent({ action: eventTypes.GET_HELP, detail: this._userID });
    });

  handleClose = () =>
    this.safeSetState({
      isOpen: false,
      /* when the user closes the chatbot, update the timestamp to force a new clientId, which creates a new conversation
       * if the user opens the chat again
       */
      timestamp: Date.now(),
    });
  store = window.WebChat.createStore({}, ({ dispatch }) => next => action => {
    if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
      dispatch({
        meta: {
          method: 'keyboard',
        },
        payload: {
          activity: {
            channelData: {
              postBack: true,
            },
            //Web Chat will show the 'Greeting' System Topic message which has a trigger-phrase 'hello'
            name: 'startConversation',
            type: 'event',
          },
        },
        type: 'DIRECT_LINE/POST_ACTIVITY',
      });
    }
    return next(action);
  });
  render() {
    const { isOpen, token, timestamp } = this.state;
    const { userProfile } = this.props;

    this._userID =
      userProfile?.external_details?.connectwise &&
      `${timestamp}:${userProfile.external_details?.connectwise?.account_id}:${userProfile.external_details?.connectwise?.contact_id}`;
    const username = [userProfile?.first_name, userProfile?.last_name]
      .filter(f => !!f)
      .join(' ');

    const userInitials = `${userProfile?.first_name?.charAt(
      0
    )}${userProfile?.last_name?.charAt(0)}`;
    
    return (
      <button className="ui primary button" onClick={this.handleClick}>
        Chat
      </button>
    );
  }
}

const WrappedChatBot = props => (
  <AppContext.Consumer>
    {({ selectedCustomer, userProfile }) => (
      <ChatBot
        {...props}
        selectedCustomer={selectedCustomer}
        userProfile={userProfile}
      />
    )}
  </AppContext.Consumer>
);

export default WrappedChatBot;
