import React from 'react';
import { FormSelect } from '../../components/';

const options = [
  { key: 'Enabled', value: 'Enabled', text: 'Enabled' },
  { key: 'Disabled', value: 'Disabled', text: 'Disabled' },
];

const keys = ['Enabled', 'Disabled'];

class UserAccessSelect extends React.Component {
  render() {
    const { access, onAccessChange } = this.props;

    return (
      <FormSelect
        options={options}
        value={access}
        placeholder={keys.includes(access) ? access : 'Select an Access Level'}
        onChange={onAccessChange}
      />
    );
  }
}

export default UserAccessSelect;
