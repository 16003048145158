import React from 'react';
import { Transition, Icon } from 'semantic-ui-react';
import { localKeys, getLocalKey, setLocalKey } from '../../ManageCache';

import './RDPNotification.scss';

const transitionTime = 500;

export class RDPNotification extends React.Component {
  constructor(props) {
    super(props);

    const hasUserHiddenIt =
      getLocalKey(localKeys.hide_rdp_notification) === 'true';

    this.state = {
      isVisibleModal: false,
      isVisible: !hasUserHiddenIt,
    };
  }

  handleHideNotification = () => {
    setLocalKey(localKeys.hide_rdp_notification, 'true');
    this.setState({ isVisible: false });
  };

  render() {
    const { isVisible } = this.state;
    return (
      <Transition
        visible={isVisible}
        animation="fade"
        unmountOnHide={true}
        duration={transitionTime}>
        <div className="RDPNotification info-lite">
          <div className="icon-box">
            <Icon name="info" />
            <Icon name="circle" />
          </div>
          <p>
            <b>Notice:</b>
            {
              ' Corsica service team members are currently unable to remote into user workstations to provide on-device support due to a global service outage from one of our vendors. We are sorry for any inconvenience this may cause.'
            }
          </p>
          <button className="close-btn" onClick={this.handleHideNotification}>
            <Icon name="close" />
          </button>
        </div>
      </Transition>
    );
  }
}

export default RDPNotification;
