import React from 'react';

import './MFAButton.scss';

const MFAButton = ({
  icon,
  title,
  subtitle,
  body,
  primary = false,
  secondary = false,
  className,
  onClick,
  disabled = false,
  ...otherProps
}) => {
  return (
    <div
      className={`MFAButton ${className || ''} ${primary ? 'primary' : ''} ${
        secondary ? 'secondary' : ''
      } ${disabled ? 'disabled' : ''}`}
      onClick={!disabled ? onClick : () => null}
      style={
        typeof onClick === 'function' && !disabled
          ? { cursor: 'pointer' }
          : null
      }
      {...otherProps}>
      {icon && (
        <div className="col-1">
          <img src={icon} alt="" />
        </div>
      )}
      <div className="col-2">
        <h4>
          {title}
          {subtitle ? <small>{subtitle}</small> : ''}
        </h4>
        {body}
      </div>
    </div>
  );
};

export default MFAButton;
