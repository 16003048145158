import React, { useState, useEffect } from 'react';

import { Card, CustomTooltipV2, Dot } from '../';
import { SecurityAwarenessTraining, usePromise } from '../../api';
import { monthLookupShort } from '../../utils/utils';

import {
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Bar,
  ReferenceLine,
} from 'recharts';

import { colorsNEW } from '../../styles/utils';

import './WidgetPhishTestingOverview.scss';

export default function WidgetPhishTestingOverview({ ...otherProps }) {
  const [data, setData] = useState([]);
  const [average, setAverage] = useState(0);

  const { response, isLoading } = usePromise(
    SecurityAwarenessTraining.phishTestingSummary
  );

  const buildDisplay = (percent, val) => {
    // recharts is changing our float to an int and losing decimal places, so if there is a number, just say "<1%"
    // this logic specifically is: if percent is zero, do additional stuff, else percent
    // additional stuff is, check if val is greater than zero, if so, reutnr "<1%" else "0%"
    return `${percent === 0 ? (val > 0 ? '<1' : 0) : percent}% (${val || 0})`;
  };

  const rows = [
    {
      title: 'Failed Tests',
      dataKey: 'failure_count',
      className: 'red bold',
      color: colorsNEW.red,
      formatter: (val, payload) => {
        let percent;
        if (payload && payload.length > 0) {
          const subP = payload[0];
          percent =
            (subP && subP.payload && subP.payload.failure_percentage) || 0;
        }
        return buildDisplay(percent, val);
      },
    },
  ];

  const CustomTooltip =
    typeof CustomTooltipV2 === 'function'
      ? CustomTooltipV2({
          rows,
          icon: 'rect',
          headingFormatter: label => label,
        })
      : CustomTooltipV2;

  useEffect(() => {
    if (response?.body?.data) {
      const { data, meta } = response.body;

      const earliestObj = data[0];
      const earliestYear = (earliestObj && earliestObj.year) || 0;
      const earliestMonth = (earliestObj && earliestObj.month) || 0;
      const today = new Date();
      const thisMonth = today.getMonth() + 1; // zero based months
      const thisYear = today.getFullYear();

      const yearsToAdd = thisYear - earliestYear;
      const monthsToAdd = yearsToAdd * 12 + (thisMonth - earliestMonth);

      let months = [];
      // must be >= 0. 0 indicates we only have data for this month, dont forget to include this month!!
      if (monthsToAdd >= 0) {
        months = Array.from(Array(monthsToAdd + 1), (a, i) => {
          // Start with the earliest month, then just ++ through to today
          const diff = earliestMonth + i;
          // Determine if we go past December, ie, if its greater than 12, then we need to ++ year
          const remainder = diff / 12;
          return {
            // get the reminder, unless its 12, then get 12.(we dont want zero)
            month: diff % 12 || 12,
            // if remainder is greater than 1, ie, we rolled over a year, then add 1,
            // otherwise, if its <= 1, then we're in december or earlier
            year: earliestYear + (remainder > 1 ? 1 : 0),
          };
        });
        // max 12 months back.
        months =
          months.length > 12
            ? months.slice(months.length - 12, months.length)
            : months;
      }

      setData(
        Array.from(months, (month, index) => {
          // here we are filling the gaps with blank data to ensure we have ticks at each month
          const row = data.find(
            d => d.month === month.month && d.year === month.year
          ) || {
            scheduled_count: 0,
            failure_count: 0,
            failure_percentage: 0,
            delivered_count: 0,
            delivered_percentage: 0,
            year: month.year,
            month: month.month,
          };

          // here we are creating the X-Axis label "name"
          row.name =
            index === months.length - 1
              ? 'Current*'
              : `${monthLookupShort[row.month - 1]} ${String(row.year).substr(
                  2
                )}`;

          row.failure_percentage = Math.round(+row.failure_percentage * 100);
          row.delivered_percentage = Math.round(
            +row.delivered_percentage * 100
          );
          return row;
        })
      );

      setAverage(meta?.six_month_average);
    }
  }, [response]);

  const vals = data.map(row => row.failure_percentage) || [100];
  let max = Math.max(...vals);
  max = isNaN(max) || max > 100 || max < 0 ? 100 : max;

  const tickCount = 5;
  // take the max number, add 10 minus the modulus of 5 of max, IE make sure its divisible by 5
  const chartMax = max + (2 * tickCount - (max % tickCount));
  const gap = chartMax / tickCount;
  const ticks = Array.from(Array(tickCount + 1).keys()).map(i => i * gap);

  return (
    <Card
      className="WidgetPhishTestingOverview"
      title="Phish Testing Overview"
      subtitle="for last 6 months"
      isEmpty={!data || !data.length > 0}
      emptyStateMessage="No phish testing data found."
      isLoading={isLoading}
      {...otherProps}>
      <div className="wrapper">
        <div className="legend-wrapper">
          <span>
            <Dot style={{ backgroundColor: colorsNEW.red }} />
            Failed Tests
          </span>
          <span>
            <Dot style={{ backgroundColor: colorsNEW.lightRed }} />
            Average Failure Rate
          </span>
        </div>
        <ResponsiveContainer height={150} width="100%">
          <ComposedChart
            data={data}
            margin={{ top: 10, right: 0, bottom: -5, left: -20 }}>
            <CartesianGrid vertical={false} />
            <XAxis
              axisLine={false}
              tickLine={false}
              dataKey="name"
              xAxisId="failed"
              style={{ fontSize: '12px' }}
            />
            <YAxis
              style={{ fontSize: '12px' }}
              tickFormatter={val => `${val}%`}
              allowDecimals={false}
              axisLine={false}
              tickLine={false}
              ticks={ticks}
              interval={0}
              domain={[0, chartMax]}
            />
            <Tooltip content={CustomTooltip} cursor={false} />
            <Bar
              radius={[3, 3, 0, 0] /* Rounded top */}
              dataKey="failure_percentage"
              xAxisId="failed"
              fill={colorsNEW.red}
              name="Failed Tests"
              barSize={6}
            />
            <ReferenceLine
              strokeDasharray="8 8"
              stroke={colorsNEW.lightRed}
              strokeWidth={2}
              y={average * 100}
              ifOverflow="extendDomain"
              xAxisId="failed"
            />
          </ComposedChart>
        </ResponsiveContainer>
        <span className="chartDisclaimer">
          * <em>Indicates an incomplete month</em>
        </span>
      </div>
    </Card>
  );
}
