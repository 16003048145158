import React from 'react';

import { CreateTicketWrapper } from '../';

const CreateTicketLink = ({ linkText, className, ...otherProps }) => (
  <CreateTicketWrapper>
    {({ onClick }) => (
      <button
        {...otherProps}
        onClick={onClick}
        className={[className, 'link', 'primary', 'bold']
          .filter(f => !!f)
          .join(' ')}>
        {linkText || 'Create Ticket'}
      </button>
    )}
  </CreateTicketWrapper>
);

export default CreateTicketLink;
