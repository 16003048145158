import mixpanel from 'mixpanel-browser';

import { environment } from '../environment';

const MIXPANEL_TOKENS = {
  dev: '14a56758f02628f6d70e3ce1178568b2',
  prod: '64817b4b9864d871b6241c284e707073',
};

const isProd = environment.current.name === 'production';

const config = {
  // HTTP method for tracking requests
  //api_method: 'POST'

  // transport for sending requests ('XHR' or 'sendBeacon')
  // NB: sendBeacon should only be used for scenarios such as
  // page unload where a &quot;best-effort&quot; attempt to send is
  // acceptable; the sendBeacon API does not support callbacks
  // or any way to know the result of the request. Mixpanel
  // tracking via sendBeacon will not support any event-
  // batching or retry mechanisms.
  //api_transport: 'XHR'

  // turn on request-batching/queueing/retry
  //batch_requests: false,

  // maximum number of events/updates to send in a single
  // network request
  //batch_size: 50,

  // milliseconds to wait between sending batch requests
  //batch_flush_interval_ms: 5000,

  // milliseconds to wait for network responses to batch requests
  // before they are considered timed-out and retried
  //batch_request_timeout_ms: 90000,

  // override value for cookie domain, only useful for ensuring
  // correct cross-subdomain cookies on unusual domains like
  // subdomain.mainsite.avocat.fr; NB this cannot be used to
  // set cookies on a different domain than the current origin
  //cookie_domain: '',

  // super properties cookie expiration (in days)
  //cookie_expiration: 365,

  // if true, cookie will be set with SameSite=None; Secure
  // this is only useful in special situations, like embedded
  // 3rd-party iframes that set up a Mixpanel instance
  //cross_site_cookie: false,

  // super properties span subdomains
  //cross_subdomain_cookie: true,

  // debug mode
  debug: !isProd,

  // if this is true, the mixpanel cookie or localStorage entry
  // will be deleted, and no user persistence will take place
  //disable_persistence: false,

  // if this is true, Mixpanel will automatically determine
  // City, Region and Country data using the IP address of
  //the client
  //ip: true,

  // opt users out of tracking by this Mixpanel instance by default
  //opt_out_tracking_by_default: false,

  // opt users out of browser data storage by this Mixpanel instance by default
  //opt_out_persistence_by_default: false,

  // persistence mechanism used by opt-in/opt-out methods - cookie
  // or localStorage - falls back to cookie if localStorage is unavailable
  //opt_out_tracking_persistence_type: 'localStorage',

  // customize the name of cookie/localStorage set by opt-in/opt-out methods
  //opt_out_tracking_cookie_prefix: null,

  // type of persistent store for super properties (cookie/
  // localStorage) if set to 'localStorage', any existing
  // mixpanel cookie value with the same persistence_name
  // will be transferred to localStorage and deleted
  //persistence: 'cookie',

  // name for super properties persistent store
  //persistence_name: '',

  // names of properties/superproperties which should never
  // be sent with track() calls
  //property_blacklist: [],

  // if this is true, mixpanel cookies will be marked as
  // secure, meaning they will only be transmitted over https
  //secure_cookie: false,

  // the amount of time track_links will
  // wait for Mixpanel's servers to respond
  //track_links_timeout: 300,

  // if you set upgrade to be true, the library will check for
  // a cookie from our old js library and import super
  // properties from it, then the old cookie is deleted
  // The upgrade config option only works in the initialization,
  // so make sure you set it when you create the library.
  //upgrade: false,

  // extra HTTP request headers to set for each API request, in
  // the format {'Header-Name': value}
  //xhr_headers: {},

  // protocol for fetching in-app message resources, e.g.
  // 'https://' or 'http://'; defaults to '//' (which defers to the
  // current page's protocol)
  //inapp_protocol: '//',

  // whether to open in-app message link in new tab/window
  //inapp_link_new_window: false,

  // whether to ignore or respect the web browser's Do Not Track setting
  //ignore_dnt: false,
};

mixpanel.init(isProd ? MIXPANEL_TOKENS.prod : MIXPANEL_TOKENS.dev, config);

/*

mixpanel.register({
    "age": 28,
    "gender": "male",
    "source": "facebook"
});

mixpanel.track("Signup");

// after making the previous call to mixpanel.register, it is just like adding the properties directly:

mixpanel.track("Signup", {
    "age": 28,
    "gender": "male",
    "source": "facebook"
});

// register a super property for the first time only
mixpanel.register_once({
    'First Login Date': new Date().toISOString()
});


// useful on user logout
mixpanel.reset();

mixpanel.set_group('company', ['mixpanel', 'google']) // an array of IDs
mixpanel.set_group('company', 'mixpanel')
mixpanel.set_group('company', 128746312)


Time an event by including the time between this call and a later 'track' call for the same event in the properties sent with the event. Note that each call to time_event for the same event name restarts the start timer.

Usage:
// time an event named 'Registered'
mixpanel.time_event('Registered');
mixpanel.track('Registered', {'Gender': 'Male', 'Age': 21});


// track an event using navigator.sendBeacon
mixpanel.track('Left page', {'duration_seconds': 35}, {transport: 'sendBeacon'});

mixpanel.people.increment('page_views');




// mixpanel.identify must be called in order to associate the profile properties you set 
// with that user. You only need to call it once per page load for a given user.
mixpanel.identify("13793");

mixpanel.people.set({ "Plan": "Premium" });

*/

export default mixpanel;
