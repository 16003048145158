import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import './SectionHeader.scss';

export default class SectionHeader extends Component {
  constructor(props) {
    super(props);
    this.header = React.createRef();

    this.state = {
      isFixedHeader: false,
      fixedStyles: {},
      nonFixedStyles: props.style || {},
    };
  }

  componentDidMount() {
    if (!this.props.disableSticky) {
      window.addEventListener('scroll', this.handleScroll, true);
      window.addEventListener('resize', this.updateWidth, true);
    }
  }
  componentWillUnmount() {
    if (!this.props.disableSticky) {
      window.removeEventListener('scroll', this.handleScroll, true);
      window.removeEventListener('resize', this.updateWidth, true);
    }
  }

  handleScroll = e => {
    const isScrollingVertically =
      this.header?.current && e.target.id === 'appContent';
    const isScrollingHorizontally =
      this.header?.current && e.target.id === 'LayoutDefault';

    if (isScrollingVertically) {
      if (e.target.scrollTop + 60 > this.header.current.clientHeight) {
        if (!this.state.isFixedHeader) {
          this.setState({
            isFixedHeader: true,
          });
          document.getElementById('root').classList.add('hasStickyHeader');
        }
      } else {
        if (this.state.isFixedHeader) {
          this.setState({
            isFixedHeader: false,
          });
          document.getElementById('root').classList.remove('hasStickyHeader');
        }
      }
    }

    if (isScrollingHorizontally) {
      this.updateWidth();
    }
  };

  updateWidth = () => {
    const { style } = this.props;
    const horizontalScrollContainer = document.getElementById('LayoutDefault');
    let leftOffset = horizontalScrollContainer.scrollLeft;

    // make sure left offset is at least a number
    leftOffset = !leftOffset || isNaN(leftOffset) ? 0 : leftOffset;
    // get width of sidebar
    const sidebarElement = document.getElementsByClassName('.sidebar');
    // find the sidebar element and get its current width. default to its last known value if that fails.
    const sidebarWidth = (sidebarElement && sidebarElement.offsetWidth) || 220;
    // subtract the width of the sidebar
    leftOffset = sidebarWidth - leftOffset;
    // find the width of the screen, and subtract our scroll offset
    const width = document.body.offsetWidth - leftOffset;
    // only apply these offsets if the header is fixed
    //const fixedStyles = { ...style, left: leftOffset, width };
    const fixedStyles = { ...style, left: leftOffset, width };
    const nonFixedStyles = { ...style };

    this.setState({ fixedStyles, nonFixedStyles });
  };

  render() {
    const { isFixedHeader, fixedStyles, nonFixedStyles } = this.state;
    const {
      onBackClick,
      title,
      subtitle,
      children,
      subtitleNonItalic,
      notification,
    } = this.props;

    return (
      <React.Fragment>
        {!!notification && notification.message && (
          <div className={`notification ${notification.type}`}>
            <h4>
              <Icon
                name={
                  notification.type === 'info'
                    ? 'warning circle'
                    : 'warning sign'
                }
              />
              {notification.message}
            </h4>
          </div>
        )}
        <div
          className={[
            'SectionHeader',
            isFixedHeader && 'stuck',
            onBackClick && 'wrappedHeaderWithBackBtn',
            notification && 'hasLocalNotification',
          ]
            .filter(f => !!f)
            .join(' ')}
          ref={this.header}
          style={isFixedHeader ? fixedStyles : nonFixedStyles}>
          {onBackClick && (
            <button className="backButton" onClick={onBackClick}>
              <Icon className="back-btn-icon" name="chevron left" />
            </button>
          )}
          <h1>
            {title}
            {subtitle ? (
              typeof subtitle === 'string' ? (
                <small>{subtitle}</small>
              ) : (
                subtitle()
              )
            ) : null}
            {subtitleNonItalic ? (
              <small className="non-italic">{subtitleNonItalic}</small>
            ) : null}
          </h1>
          {children ? <div className="headerActions">{children}</div> : null}
        </div>
      </React.Fragment>
    );
  }
}

SectionHeader.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.node,
  style: PropTypes.object,
};
