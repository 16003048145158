import React from 'react';

import { Success } from '../';
import { Modal, Button } from 'semantic-ui-react';

const ContentSubmitted = ({ firstName, onGoToEntry, onClose }) => (
  <Modal.Content className="ContentSubmitted">
    <Success />
    <h2>Thanks for your feedback {firstName}.</h2>
    <p>
      Thank you for taking the time to help us improve our portal. We are
      reviewing your feedback and will reach out with any questions!
    </p>
    <Button className="primary" onClick={onClose}>
      Close
    </Button>
    <br />
    <br />
    <Button basic onClick={onGoToEntry}>
      Submit Additional Feedback
    </Button>
  </Modal.Content>
);

export default ContentSubmitted;
