import React, { useState, useEffect } from 'react';
import { Card, TabularList } from '../../components';
import { numberWithCommas } from '../../utils/utils';
import { Dashboard, ErrorHandler } from '../../api';
import { widgetClick } from '../../utils/analytics';

import './WidgetIncidents.scss';

function WidgetIncidents() {
  const [count, setCount] = useState();
  const [openCount, setOpenCount] = useState();
  const [resolvedCount, setResolvedCount] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // need to prevent attempting to set state on unmounted components
    let unmounted = false;

    const fetchData = async () => {
      let res;
      try {
        res = await Dashboard.incidentsCount();
      } catch (err) {
        ErrorHandler.error(err);
      }

      if (res?.body?.data && !unmounted) {
        const { data } = res.body;

        setCount(data.total_count);
        setOpenCount(data.open_count);
        setResolvedCount(data.resolved_count);
      }
      if (!unmounted) {
        setLoading(false);
      }
    };
    fetchData();

    // on dismount this return callback gets called
    return () => {
      unmounted = true;
    };
  }, []);

  const rows = [
    {
      onClick: () => {
        widgetClick({
          link: '/my-organization/tickets?tab=open&ticket_category=Incident',
          name: 'WidgetIncidents',
        });
      },
      title: 'Open',
      value: openCount,
    },
    {
      onClick: () => {
        widgetClick({
          link:
            '/my-organization/tickets?tab=resolved&ticket_category=Incident',
          name: 'WidgetIncidents',
        });
      },
      title: 'Resolved',
      value: resolvedCount,
    },
  ];

  return (
    <Card
      className="WidgetIncidents"
      title="Incidents"
      isLoading={loading}
      subtitle="in last 90 days">
      <div className="count">{numberWithCommas(count)}</div>
      <TabularList rows={rows} />
    </Card>
  );
}

export default WidgetIncidents;
