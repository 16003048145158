import { localKeys, setLocalKey, getLocalKey } from '../ManageCache';
import { bugsnagClient } from './bugsnag';

export default function componentLoader(
  lazyComponent,
  attemptsLeft = 3,
  interval = 1000
) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch(error => {
        const lastReload = getLocalKey(localKeys.forced_reload);

        bugsnagClient.leaveBreadcrumb('Chunk load fail - Attempting retry', {
          attemptsLeft,
          interval,
          url: window.location.href,
          lastReload,
          timeStamp: new Date().getTime(),
        });
        // try to re-resolve after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            // this was our last attempt, so instead we will try to reload the webpage, only if it has not recently reloaded
            const timeStamp = new Date().getTime();

            if (
              !lastReload ||
              Math.abs(Number(lastReload) - timeStamp) > 60000
            ) {
              // if there is no last reload time, OR that time is more than a minute ago, then lets hard reload
              // set timestamp so that we dont get into a reload loop
              setLocalKey(localKeys.forced_reload, timeStamp);
              bugsnagClient.leaveBreadcrumb(
                'Chunk load fail - Forcing reload',
                {
                  attemptsLeft,
                  interval,
                  url: window.location.href,
                  lastReload,
                  timeStamp: new Date().getTime(),
                  timeSinceLastReload: Math.abs(Number(lastReload) - timeStamp),
                }
              );
              window.location.reload(true);
            }

            bugsnagClient.leaveBreadcrumb(
              'Chunk load fail - Skip reload, throwing error',
              {
                attemptsLeft,
                interval,
                url: window.location.href,
                lastReload,
                timeStamp: new Date().getTime(),
                timeSinceLastReload: Math.abs(Number(lastReload) - timeStamp),
              }
            );
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, interval);
      });
  });
}
