import React from 'react';
import { Transition } from 'react-transition-group';
import { AppContext } from '../../AppContext';

import './Toast.scss';

class Toast extends React.Component {
  constructor(props) {
    super(props);

    this.onDismissToast = e => {
      if (e) {
        e.preventDefault();
      }
      this.props.removeToast(props.id);
    };
  }

  componentDidMount() {
    if (this.props.duration) {
      this.timeoutId = window.setTimeout(() => {
        this.onDismissToast();
      }, this.props.duration);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  render() {
    return (
      <TransitionSlideInOut
        {...this.props}
        isVisible={true}
        inDuration={300}
        outDuration={300}>
        <div className={`Toast -${this.props.type}`}>
          <div className="body">{this.props.children}</div>
          <a
            className="closeButton"
            onClick={this.onDismissToast}
            href="#close-toast">
            &times;
          </a>
        </div>
      </TransitionSlideInOut>
    );
  }
}

const WrappedToast = props => (
  <AppContext.Consumer>
    {({ removeToast }) => <Toast {...props} removeToast={removeToast} />}
  </AppContext.Consumer>
);

export { WrappedToast as Toast };

function TransitionSlideInOut(props) {
  const { children, inDuration = 0, outDuration = 300 } = props;
  const transDuration = 300;
  const defaultStyle = {
    transform: 'translateX(100%)',
    transition: `transform ${transDuration}ms cubic-bezier(1, 0, 0, 1)`,
  };

  const transitionStyles = {
    entering: {
      transform: 'translateX(110%)',
    },
    entered: {
      transform: 'translateX(0)',
    },
    exiting: {
      transform: 'translateX(110%)',
    },
  };

  return (
    <Transition
      {...props}
      appear={true}
      timeout={{ enter: inDuration, exit: outDuration }}>
      {status => {
        const currentStyles = transitionStyles[status];
        return React.cloneElement(children, {
          style: Object.assign({}, defaultStyle, currentStyles),
        });
      }}
    </Transition>
  );
}
