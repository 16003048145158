import React from 'react';
import { Modal, Message, Button } from 'semantic-ui-react';
import { FormInput, FormFileUpload, FormTextArea, FormSelect } from '../';

const ContentSparkClientCreate = ({
  isError,
  title,
  isErrorSubject,
  description,
  isErrorIssue,
  isSubmitting,
  handleSubmit,
  handleSoftCancel,
  assignedTo,
  optionsPriority,
  optionsType,
  optionsSubtype,
  priority,
  type,
  subtype,
  handleFieldChange,
  userProfile,
  corsicaId,
  selectedClientId,
  handleSelectClient,
  optionsAssignedTo,
  isLoadingAttributes,
  /* file upload fields */
  handleFiles,
  isUploading,
  uploadProgress,
  isErrorFiles,
}) => (
  <>
    <Modal.Header style={{ borderBottom: 'none' }}>
      <h2>Create New Support Ticket</h2>
    </Modal.Header>
    <Modal.Content>
      {isError && (
        <Message negative>There was an error creating the ticket</Message>
      )}
      <FormSelect
        style={{ width: '100%', marginBottom: '15px' }}
        options={[
          { text: 'Corsica Technologies', value: corsicaId },
          { text: userProfile.client_name, value: userProfile.client_id },
        ]}
        value={selectedClientId}
        label="Who will be working this ticket?"
        onChange={(e, opt) =>
          handleSelectClient({
            selectedClientId: opt?.value,
            selectedClientName: opt?.text,
            alsoUpdateState: true,
          })
        }
      />
      <FormSelect
        style={{ width: '100%', marginBottom: '15px' }}
        options={optionsAssignedTo.map(opt => ({
          text: opt.name,
          value: opt.contact_id,
        }))}
        label="Who is the contact for this ticket?"
        onChange={(e, opt) => handleFieldChange('assignedTo', opt?.value)}
        value={assignedTo}
        isLoading={isLoadingAttributes}
      />
      <FormInput
        error={isErrorSubject}
        errorMessage={'A subject is required'}
        label="Subject for ticket"
        maxLength={100}
        onChange={e => handleFieldChange('title', e?.target?.value)}
        style={{ width: '100%', marginBottom: '15px' }}
        value={title}
      />
      {!(title.length < 100) && (
        <span className="subject-length-warning">
          Please limit the subject to 100 characters.
        </span>
      )}
      <FormTextArea
        rows={10}
        onChange={e => handleFieldChange('description', e?.target?.value)}
        value={description}
        style={{ width: '100%', marginBottom: '15px' }}
        label="Briefly describe the issue"
        error={isErrorIssue}
      />
      <div className="form-row">
        <FormSelect
          options={optionsPriority.map(opt => ({
            text: opt.name,
            value: opt.id,
          }))}
          label="Priority"
          onChange={(e, opt) => handleFieldChange('priority', opt?.value)}
          value={priority}
          isLoading={isLoadingAttributes}
        />
        <FormSelect
          className="add-margins"
          options={optionsType.map(opt => ({
            text: opt.name,
            value: opt.id,
          }))}
          label="Category"
          onChange={(e, opt) => handleFieldChange('type', opt?.value)}
          value={type}
          isLoading={isLoadingAttributes}
        />
        <FormSelect
          options={optionsSubtype
            .filter(o => {
              // TODO: this should be replaced soon
              // temp filter for alert type only allowing subtypes of "hardware" and "workstation"
              if (type === 318) {
                return o.id === 364 || o.id === 372;
              }
              return true;
            })
            .map(opt => ({
              text: opt.name,
              value: opt.id,
            }))}
          label="Type"
          onChange={(e, opt) => handleFieldChange('subtype', opt?.value)}
          value={subtype}
          isLoading={isLoadingAttributes}
        />
      </div>
      <FormFileUpload
        multiple={true}
        style={{ width: '100%' }}
        onChange={handleFiles}
        label="Add screenshots if needed (JPG, PNG, PDF, CSV, DOC, DOCX, XLS, XLSX allowed - max file size 2MB)"
        isUploading={isUploading}
        uploadProgress={uploadProgress}
        error={isErrorFiles}
        errorMessage={'There was a problem uploading your file'}
      />
    </Modal.Content>
    <Modal.Actions>
      <Button
        primary
        loading={isSubmitting || isUploading}
        onClick={handleSubmit}
        disabled={!title || !description}>
        Create Ticket
      </Button>
      <Button className="ghost secondary" onClick={handleSoftCancel}>
        Cancel
      </Button>
    </Modal.Actions>
  </>
);

export default ContentSparkClientCreate;
