import React from 'react';
import './ShortDashWidget.scss';

const ShortDashWidget = props => {
  return (
    <div className="shortDashWidget">
      <div className={['number', props.color].filter(f => !!f).join(' ')}>
        {props.number}
        {props.subnumber ? (
          <div className="addedCount">{props.subnumber}</div>
        ) : null}
      </div>
    </div>
  );
};

export default ShortDashWidget;
