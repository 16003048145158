import React, { useState, useEffect } from 'react';
import { Dashboard, ErrorHandler } from '../../api';
import { WidgetProgressRows } from '../../components';
import { roundPercent } from '../../utils/utils';
import { widgetClick } from '../../utils/analytics';

const colorChangeThresholds = {
  lessThan85: 'blue',
  between85and95: 'blue',
  moreThan95: 'blue',
};

const getPercentColor = percent => {
  if (percent >= 95) {
    return colorChangeThresholds.moreThan95;
  } else if (percent >= 85 && percent < 95) {
    return colorChangeThresholds.between85and95;
  } else {
    return colorChangeThresholds.lessThan85;
  }
};

function WidgetOnboardingProgress({ ...props }) {
  const [totalPercent, setTotalPercent] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // need to prevent attempting to set state on unmounted components
    let unmounted = false;

    const fetchData = async () => {
      let res;
      try {
        res = await Dashboard.onboardingProgress();
      } catch (err) {
        ErrorHandler.error(err);
      }

      if (res?.body?.data && !unmounted) {
        const { data } = res.body;

        setTotalPercent(data.total_percentage);

        const labelStyles = { fontSize: '18px', fontWeight: 'bold' };

        const _rows = data.categories.map(c => ({
          labelStyles,
          subLabel: c.title,
          labelLeft: `${roundPercent(c.percentage)}%`,
          percent: c.percentage,
          color: getPercentColor(c.percentage),
          onClick: () =>
            widgetClick({
              link: '/onboarding-roadmap',
              name: 'WidgetOnboardingProgress-categoryClick',
            }),
        }));

        setRows(_rows);
      }
      if (!unmounted) {
        setLoading(false);
      }
    };
    fetchData();

    // on dismount this return callback gets called
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <WidgetProgressRows
      className="onboarding-progress"
      title="Onboarding Progress"
      subtitle="as of today"
      mainValue={`${roundPercent(totalPercent)}%`}
      mainValueOnClick={() =>
        widgetClick({
          link: '/onboarding-roadmap',
          name: 'WidgetOnboardingProgress-mainValueClick',
        })
      }
      mainLabel="Complete"
      isLoading={loading}
      rows={rows}
      {...props}
    />
  );
}

export default WidgetOnboardingProgress;
