import React from 'react';

import { Dashboard, ErrorHandler } from '../../api';
import { Widget } from '../Widget/Widget';
import { DonutChart } from '../../components/';
import { shortenLargeNumbers, roundPercent } from '../../utils/utils';

import './WidgetEndpointProtection.scss';

export default class WidgetEndpointProtection extends React.Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      isLoading: false,
      percentCurrent: 0,
      notCurrentCount: 0,
      currentCount: 0,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });

    let res;
    try {
      res = await Dashboard.endpointProtection();
    } catch (err) {
      ErrorHandler.error(err);
    }

    const newState = { isLoading: false };

    if (res && res.body && res.body.data) {
      const { data } = res.body;
      newState.percentCurrent = roundPercent(data.percent_current) || 0;
      newState.notCurrentCount = data.not_current_devices || 0;
      newState.currentCount = data.current_devices || 0;
    }

    this.safeSetState(newState);
  };

  render() {
    const {
      isLoading,
      notCurrentCount,
      currentCount,
      percentCurrent,
    } = this.state;
    const chartData = [
      {
        name: 'Current',
        value: currentCount,
        className: 'current-count',
        key: 'currentCount',
        legendOrder: 1,
      },
      {
        name: 'Not Current',
        value: notCurrentCount,
        className: 'not-current-count',
        key: 'notCurrentCount',
        legendOrder: 2,
      },
    ];
    return (
      <Widget
        title="Device Endpoint Protection"
        link="/devices/endpoint-protection"
        widgetName="WidgetEndpointProtection"
        isLoading={isLoading}
        emptyStateMessage="No endpoint protection device data found."
        isEmpty={!percentCurrent && percentCurrent !== 0}
        className="endpoint-protection-widget height-2">
        <DonutChart
          size={180}
          data={chartData}
          centerCount={`${shortenLargeNumbers(percentCurrent)}%`}
          centerTitle="Current"
          dataKey="value"
          className="centered"
          startAngle={90}
          endAngle={-270}
        />
      </Widget>
    );
  }
}
