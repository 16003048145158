import React from 'react';
import { BaseDateSelector } from '../';
import format from 'date-fns/format';
import withQueryParams from '../../queryParamHOC';
import PropTypes from 'prop-types';
import { getDateFilter } from '../../utils';

class DateSelector extends React.Component {
  componentDidMount() {
    // If the component mounts and Redux has a date for it, add it back
    // to query params, otherwise it'll get wiped out.
    if (this.props.queryParams.date) {
      this.props.setQueryParams({
        date: format(
          getDateFilter(this.props.filters.dateFilters, 'date'),
          'YYYY-MM-DD'
        ),
      });
    } else {
      // if there is nothing from redux, make sure qp are cleared
      this.props.setQueryParams({ date: undefined });
    }
  }

  handleDateChange = newDate => {
    this.props.setQueryParams({ date: format(newDate, 'YYYY-MM-DD') });
  };

  render() {
    const range = this.props.range;
    const dayPickerProps = {
      disabledDays: { before: range && range.from, after: new Date() },
    };
    const { dateFilters } = this.props.filters;
    return (
      <div className="DateSelector">
        <BaseDateSelector
          date={getDateFilter(dateFilters, 'date') || this.props.defaultDate}
          onDateChanged={this.handleDateChange}
          disabled={this.props.disabled}
          label={this.props.label}
          className="single-date"
          dayPickerProps={dayPickerProps}
        />
      </div>
    );
  }
}

DateSelector.propTypes = {
  date: PropTypes.instanceOf(Date),
  range: PropTypes.shape({
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
  }),
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default withQueryParams({
  stripUnknownKeys: false,
  keys: {
    date: {
      default: undefined,
      validate: () => true,
    },
  },
})(DateSelector);
