// IE11 polyfills - This must be the first line in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { BugsnagFallback } from './views';
import bugsnagReact from '@bugsnag/plugin-react';
import bugsnagClient from './utils/bugsnag';
import { IEWarning, IPBlockedWarning } from './components/';
import bowser from 'bowser';
import { Analytics } from './analytics';
import reportWebVitals from './reportWebVitals';
import { ErrorHandler } from './api';

//import { unregister } from './registerServiceWorker';
//import register from './registerServiceWorker';

import { history, store } from './store';

import { WithAppContext } from './AppContext';
import componentLoader from './utils/componentLoader';

import './styles/base.scss';
// import the component styles from the corsica component library
import 'corsica-component-library/dist/css/styles.scss';
import { sessionKeys, setSessionKey } from './ManageCache';

// lazy load the app for code splitting to avoid IE trying to load everything
const App = lazy(() => componentLoader(() => import('./App')));

// wrap your entire app tree in the ErrorBoundary provided
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

// React Router Browser History is now broken, so lets make our own history
// Things to keep in mind:
// 1. dont let this array get out of hand, maybe cap at like 10-20 loc hist
// 2. dedupe it
// 3. replace should replace
// 4. initialize it with this location so we can go back here correctly from the start!!
let CustomHistory = window.CustomHistory || [
  { pathname: window.location.pathname, search: window.location.search || '' },
];

history.listen((loc, action) => {
  const locShort = { pathname: loc.pathname, search: loc.search };

  switch (action) {
    case 'PUSH':
      CustomHistory.push(locShort);
      break;
    case 'REPLACE':
      // replace the last item in the array
      CustomHistory[CustomHistory.length - 1] = locShort;
      break;
    case 'POP':
      // a 'POP' occurs when the browser back button is pressed. We could pop the last location,
      // HOWEVER, a 'POP' also occurs when the browser "forward" button is pressed. We only use
      // CustomHistory for goBack, so we just need to know where to go back to, so just pushing
      // all the changes will suffice.
      CustomHistory.push(locShort);
      break;
    default:
  }

  // cap the length of our custom history at 20.
  CustomHistory = CustomHistory.slice(-20);

  window.CustomHistory = CustomHistory;
});

// list of IP's of known bots / email scan software. these regex IP matches will see a blank page
const botIpRegexCheckList = [
  /^205.169.39/g,
  /^65.154.226.100/g,
  /^65.128.0/g,
  /^65.154.226/g,
  /^65.155.38/g,
  /^66.232.33/g,
  /^66.232.34/g,
  /^70.42.131/g,
  /^70.42.242/g,
  /^74.217.90/g,
  /^199.167.52/g,
  /^199.167.53/g,
];

// using an IIAF so that we can use async/await so that we can prevent loading till we get an IP check
(async () => {
  // if we fail to get the IP because of the endpoint failing, lets play safe and let the user in
  let isBot = false;
  const isIE = bowser.msie;

  try {
    const res = await Analytics.geo();

    const ip = res?.body?.ip || '';
    if (
      ip &&
      typeof ip.match === 'function' &&
      botIpRegexCheckList.some(botIpMatcher => ip.match(botIpMatcher))
    ) {
      isBot = true;
    }
    setSessionKey(sessionKeys.session_ip, ip);
  } catch (err) {
    bugsnagClient.leaveBreadcrumb('IP Endpoint failed');
    bugsnagClient.notify(err);
  }

  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(
    isIE ? (
      <IEWarning />
    ) : isBot ? (
      <IPBlockedWarning />
    ) : (
      <ErrorBoundary FallbackComponent={BugsnagFallback}>
        <WithAppContext>
          <Provider store={store}>
            <Router history={history}>
              <Suspense fallback={null}>
                <Route path="/" component={App} />
              </Suspense>
            </Router>
          </Provider>
        </WithAppContext>
      </ErrorBoundary>
    )
  );
  /*
    <React.StrictMode>
    </React.StrictMode>
    */

  // unregister();
  reportWebVitals(ErrorHandler.log);
  //register();
})();
