import React from 'react';
import { Transition, Icon } from 'semantic-ui-react';
import { Clients, ErrorHandler } from '../../api';
import { eventTypes, createEvent } from '../../utils/analytics';
import { AppContext } from '../../AppContext';
import {
  sessionKeys,
  localKeys,
  setLocalKey,
  setSessionKey,
} from '../../ManageCache';

import NotificationModal from './NotificationModal';

import './Notification.scss';

const transitionTime = 500;

export class EOLNotification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nukeIt: false,
      isLoading: false,
      isVisibleModal: false,
      emailSent: false,
    };
  }

  componentDidUpdate(prevProps) {
    /* fix persisting bluebar even when everything seems to say its hidden
     * --------------------------------------------------------------------
     * so basically it seems that the 'hide' dispatch is firing as the page exits,
     * then seems to fire again for some reason, which trips up the internal
     * mech of the `Transition` component, so if the incoming `isVisible` prop
     * is false, but it gets set to false a 2nd time, we fire off `nukeIt` to
     * just manually nuke the component, then we reset if `isVisible` changes again.
     */
    if (!prevProps.isVisible && !this.props.isVisible && !this.state.nukeIt) {
      this.setState({ nukeIt: true });
    } else if (this.state.nukeIt && this.props.isVisible) {
      this.setState({ nukeIt: false });
    }
    if (this.props.isVisible && !prevProps.isVisible) {
      document.getElementById('root').classList.add('hasNotification');
    }
    if (!this.props.isVisible && prevProps.isVisible) {
      document.getElementById('root').classList.remove('hasNotification');
    }
  }

  handleShowModal = () => {
    // analytics, yo!
    createEvent({ action: eventTypes.OPEN_MODAL, detail: 'end_of_support' });

    this.setState({ isVisibleModal: true });
  };

  handleCloseModal = e => {
    // analytics, yo!
    createEvent({ action: eventTypes.CLOSE_MODAL, detail: 'end_of_support' });

    if (e.target.innerText === 'Contact Me About This Topic') {
      return false;
    }
    // if they have requested contact, then we dont need to bug them on EVERY login
    if (this.state.emailSent) {
      setLocalKey(localKeys.has_requested_eol_contact, 'true');
      this.handleHideNotification();
    }
    this.setState({ isVisibleModal: false });
  };

  handleContact = async () => {
    this.setState({ isLoading: true });
    try {
      await Clients.contactAboutEOL();
    } catch (err) {
      // lets use bug here because we might want a record of when this fails
      ErrorHandler.bug(err);
    }
    this.setState({ emailSent: true, isLoading: false });
    return false;
  };

  handleHideNotification = () => {
    setSessionKey(sessionKeys.hide_eol_notification, 'true');
    this.props.hideNotification();
  };

  render() {
    const { isVisible, eolServerCount, eolWindowsCount } = this.props;
    const names = [];
    if (eolWindowsCount) {
      names.push('Windows 7 PCs');
    }
    if (eolServerCount) {
      names.push('Windows 2008 R2 Servers');
    }
    const total = eolServerCount + eolWindowsCount || 0;

    const { nukeIt, isVisibleModal, isLoading, emailSent } = this.state;
    return !nukeIt ? (
      <Transition
        visible={isVisible && !nukeIt}
        animation="fade"
        unmountOnHide={true}
        duration={transitionTime}>
        <div className="Notification">
          {isVisibleModal && (
            <NotificationModal
              handleContact={this.handleContact}
              handleCloseModal={this.handleCloseModal}
              isVisible={isVisibleModal}
              names={names}
              emailSent={emailSent}
              isLoading={isLoading}
            />
          )}
          <strong>Support for {names.join(' & ')} has ended.</strong> You have{' '}
          <strong>{total}</strong> device{total > 1 ? 's ' : ' '}
          running{' '}
          {names.length > 1
            ? 'one of these operating systems'
            : 'this operating system'}
          .{' '}
          <button className="link" onClick={this.handleShowModal}>
            Learn More
          </button>
          <button className="close-btn" onClick={this.handleHideNotification}>
            <Icon name="close" />
          </button>
        </div>
      </Transition>
    ) : null;
  }
}

const WrappedEOLNotification = props => (
  <AppContext.Consumer>
    {({
      eolWindowsCount,
      eolServerCount,
      hideNotification,
      notificationIsVisible,
    }) => (
      <EOLNotification
        {...props}
        hideNotification={hideNotification}
        isVisible={notificationIsVisible}
        eolServerCount={eolServerCount}
        eolWindowsCount={eolWindowsCount}
      />
    )}
  </AppContext.Consumer>
);

export default WrappedEOLNotification;
