import React from 'react';

import { history } from '../../store';
import { Ticket, Users, ErrorHandler } from '../../api';
import { environment } from '../../environment';
import { eventTypes, createEvent } from '../../utils/analytics';
import { AddTicketModal } from '../';
import { AppContext } from '../../AppContext';
import { formatPhoneWithSteps } from '../../utils/utils';

const COMPONENT_STATES = {
  CREATE: 'create',
  CREATE_SPARK: 'create spark',
  SPARK_SELECT_OWNER: 'client select',
  CONFIRM: 'confirm',
  SUBMITTED: 'submitted',
  CREATE_SPARK_CLIENT: 'create spark client',
  CREATE_SPARK_CORSICA: 'create spark corsica',
};

const DEFAULTS_new_ticket_form = {
  files: [],
  description: '',
  title: '',
  assignedTo: '',
  nonSparkPriority: '8',
  priority: '',
  type: '',
  subtype: '',
  selectedClientId: '',
  selectedClientName: '',
  isError: false,
  othersExperiencingIssue: 'no',
  wasUserInfoTouched: false,
};

function contactMethodLookupReverse(cm) {
  let res = '';

  switch (cm) {
    case 'mobile_phone':
      res = 'Mobile Phone';
      break;
    case 'office_phone':
      res = 'Office Phone';
      break;
    case 'email':
      res = 'Email';
      break;
    default:
  }
  return res;
}

function contactMethodLookup(cm) {
  let res = '';

  switch (cm) {
    case 'Mobile Phone':
      res = 'mobile_phone';
      break;
    case 'Office Phone':
      res = 'office_phone';
      break;
    case 'Email':
      res = 'email';
      break;
    default:
  }
  return res;
}

class CreateTicketWrapper extends React.Component {
  _isMounted = false;
  _user_cw_contact_id = null;
  _starting_state = null;

  constructor(props) {
    super(props);

    this._user_cw_contact_id =
      props.userProfile?.external_details?.connectwise?.contact_id;

    // client is not always resoved before this is loaded... fun times.
    let componentState = props.client
      ? this.initComponentState({
          client: props.client,
          userProfile: props.userProfile,
        })
      : COMPONENT_STATES.CREATE;

    this.state = {
      title: '',
      description: '',
      isSubmitting: false,
      isVisibleCreateTicketModal: props.isVisible,
      isUploading: false,
      uploadProgress: 0,
      componentState,
      selectedClientId: null,
      selectedClientName: null,
      wasUserInfoTouched: false,

      optionsAssignedTo: [],

      optionsPriority: [],
      optionsType: [],
      optionsSubtype: [],
      assignedTo: '',
      nonSparkPriority: '8',
      priority: '',
      type: '',
      subtype: '',
      isError: false,
      isLoadingAttributes: false,
      othersExperiencingIssue: 'No',
      contactMethod:
        contactMethodLookupReverse(props.userProfile?.primary_contact_method) ||
        'Mobile Phone',
      phoneMobile: formatPhoneWithSteps(props.userProfile?.mobile_phone || ''),
      phoneOffice: formatPhoneWithSteps(props.userProfile?.office_phone || ''),
      phoneOfficeExt: props.userProfile?.office_phone_ext || '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const clientId = this.props.client?.id;
    const prevClientId = prevProps.client?.id;
    if (clientId !== prevClientId) {
      this.safeSetState({
        componentState: this.initComponentState({
          client: this.props.client,
          userProfile: this.props.userProfile,
        }),
      });
    }
  }

  initComponentState = ({ client, userProfile }) => {
    /*
=====================================
if spark_client
    if user is a spark user
        ask user where to create
    else
        if creation priority = spark
            create on spark board
        else
            create on service desk
else
    create on service desk
=====================================
     */

    const isSparkClient = client?.is_spark_client;
    const canManageTickets = client?.manages_tickets;
    const isSparkUser = userProfile?.is_spark_user;
    const isCorsicaUser =
      userProfile?.client_id === environment.current.corsicaCustomerId;

    let componentState = COMPONENT_STATES.CREATE;

    if (isSparkClient) {
      if (isCorsicaUser) {
        componentState = COMPONENT_STATES.CREATE;
      }
      // if it is a spark client
      else if (isSparkUser) {
        // if it is a spark enabled user, let them pick the board
        componentState = COMPONENT_STATES.SPARK_SELECT_OWNER;
      } else {
        // if it is a spark client, but not a spark enabled user...
        if (canManageTickets) {
          // if they have the boolean enabled to allow them to default to spark board, then do so
          componentState = COMPONENT_STATES.CREATE_SPARK;
        } else {
          // else just let them spark create
          componentState = COMPONENT_STATES.CREATE;
        }
      }
    }

    this._starting_state = componentState;

    return componentState;
  };

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  fetchAttributes = async () => {
    this.safeSetState({ isLoadingAttributes: true });
    let res;
    try {
      res = await Ticket.attributes(this.state.selectedClientId);
    } catch (err) {
      ErrorHandler.error(err);
    }
    const newState = { isLoadingAttributes: false };
    if (res?.body?.data) {
      const { status, priority, assigned_to, category, type } = res.body.data;
      newState.optionsStatus = status;
      newState.optionsPriority = priority;
      newState.optionsAssignedTo = assigned_to;
      newState.optionsType = category;
      newState.optionsSubtype = type;
    }
    this.safeSetState(newState);
  };

  cleanFileName = str => {
    return str.replace(/([^a-z0-9 -.]+)/gi, '').replace(/ /g, '_');
  };

  handleClearNewTicketForm = () => {
    this.safeSetState(DEFAULTS_new_ticket_form);
  };

  handleHideTicketCreateModal = () => {
    const { componentState } = this.state;
    this.safeSetState(
      {
        isVisibleCreateTicketModal: false,
        componentState: this._starting_state,
      },
      () => {
        this.handleClearNewTicketForm();
        if (
          componentState === COMPONENT_STATES.SUBMITTED &&
          (window.location.pathname === '/my-organization/tickets' ||
            window.location.pathname === '/my-tickets')
        ) {
          window.location.reload(true);
        }
      }
    );
  };

  handleFiles = files => {
    // dropzone returns an object on error instead of the array, so this prevents errors from that
    this.safeSetState({ files: Array.isArray(files) ? files : [] });
  };

  handleSelectClient = ({
    selectedClientId,
    selectedClientName,
    alsoUpdateState,
  }) => {
    const newState = { selectedClientId, selectedClientName };
    let callback = () => null;
    if (alsoUpdateState) {
      // if they selected corsica as the ticket handler
      if (selectedClientId === environment.current.corsicaCustomerId) {
        newState.componentState = COMPONENT_STATES.CREATE_SPARK_CORSICA;
        // if they select corsica, then default them as the assignee
        newState.assignedTo = this._user_cw_contact_id;
      } else {
        newState.componentState = COMPONENT_STATES.CREATE_SPARK_CLIENT;
      }
      callback = this.fetchAttributes;
    }
    this.safeSetState(newState, callback);
  };

  handleSubmit = () => {
    this.safeSetState({ isSubmitting: true });

    const {
      componentState,
      files,
      title,
      description,
      assignedTo,
      nonSparkPriority,
      contactMethod,
      othersExperiencingIssue,
      phoneMobile,
      phoneOffice,
      phoneOfficeExt,
    } = this.state;

    const email = this.props?.userProfile?.email;

    let contactMethodDesc = '';

    if (contactMethod) {
      contactMethodDesc = `**__Preferred Contact Method:__** \n${contactMethod}\n`;

      switch (contactMethod) {
        case 'Office Phone':
          contactMethodDesc = `${contactMethodDesc}${[
            phoneOffice,
            phoneOfficeExt,
          ]
            .filter(f => !!f)
            .join(' x')}`;
          break;
        case 'Mobile Phone':
          contactMethodDesc = `${contactMethodDesc}${phoneMobile}`;
          break;
        case 'Email':
          contactMethodDesc = `${contactMethodDesc}${email}`;
          break;
        default:
      }
    }

    const othersExpDesc = `**__Are other users experiencing this issue?__** \n${othersExperiencingIssue}`;

    const segments = [contactMethodDesc, othersExpDesc];

    const linebreak = '------------------------------------------------';

    const updatedDescription = `${segments.join(
      '\n\n'
    )}\n\n${linebreak}\n\n${description}`;

    // create the ticket request that we will start adding to
    const req = Ticket.create();

    if (nonSparkPriority) {
      req.field('priority_id', nonSparkPriority);
    }

    req.field('title', title).field('description', updatedDescription);
    if (componentState === COMPONENT_STATES.CREATE_SPARK_CORSICA) {
      req.field('spark_client_owned', false);
      if (assignedTo) {
        req.field('contact_id', assignedTo);
      }
    }
    if (componentState === COMPONENT_STATES.CREATE_SPARK) {
      // this is a user that is at a spark client, but is not a spark user, but they have manage spark tickets enabled
      req.field('spark_client_owned', true);
      req.field('queue_id', '55'); // spark board
    }
    if (componentState === COMPONENT_STATES.CREATE_SPARK_CLIENT) {
      req.field('spark_client_owned', true);
      const { type, subtype, priority } = this.state;
      if (type) {
        req.field('category_id', type);
      }
      if (subtype) {
        req.field('type_id', subtype);
      }
      if (assignedTo) {
        req.field('contact_id', assignedTo);
      }
      if (priority) {
        req.field('priority_id', priority);
      }
      req.field('queue_id', '55'); // all client tickets go to spark board
    }
    for (let i in files) {
      const file = files[i];
      req.attach('attachments[]', file, this.cleanFileName(file.name));
    }
    req
      .on('progress', event => {
        if (event.direction === 'upload') {
          this.setState({ isUploading: true, uploadProgress: event.percent });
        }
      })
      .then(res => {
        const ticketId = res?.body?.ticket_number || '';
        if (componentState === COMPONENT_STATES.CREATE_SPARK_CLIENT) {
          createEvent({
            action: eventTypes.SPARK_CREATE_TICKET,
            detail: window.location.pathname,
          });
        } else {
          createEvent({
            action: eventTypes.CREATE_TICKET,
            detail: window.location.pathname,
          });
        }

        this.safeSetState({
          isSubmitting: false,
          componentState: COMPONENT_STATES.SUBMITTED,
          isUploading: false,
          ticketId,
        });
      })
      .catch(err => {
        this.safeSetState(
          {
            isSubmitting: false,
            isUploading: false,
            isError: true,
          },
          () => {
            ErrorHandler.bug(`ERROR::Ticket_Create::${err}`);
          }
        );
      });

    /// now do we need to update the user record also???
    /*
   *
 - the added attributes to the User update route are:
- first_name string
- last_name string
- mobile_phone string
- office_phone string
- office_phone_ext string
- can_set_ticket_priority boolean
- primary_contact_method ['office_phone', 'mobile_phone', 'email'] <-- the only options in the enum
- job_title string

    */

    if (this.state.wasUserInfoTouched) {
      Users.update({
        user_id: this.props.userProfile?.id,
        values: {
          mobile_phone: String(phoneMobile).replace(/[^\d]/g, ''),
          office_phone: String(phoneOffice).replace(/[^\d]/g, ''),
          office_phone_ext: phoneOfficeExt,
          primary_contact_method: contactMethodLookup(contactMethod),
        },
      })
        .then(res => {
          if (res?.body?.id) {
            this.props.setUserProfile(res.body);
          }
        })
        .catch(err => {
          ErrorHandler.error(err);
        });
    }
  };
  handleContinue = () => {
    const newState = {};
    const { selectedClientId } = this.state;

    let callback = () => null;
    // if it is a spark user, send them to one of the two spark create views
    if (this.props.userProfile?.is_spark_user) {
      // if the user selected corsica to handle the ticket, then send them to corsica spark create view
      if (selectedClientId === environment.current.corsicaCustomerId) {
        newState.componentState = COMPONENT_STATES.CREATE_SPARK_CORSICA;
        // if they select corsica to handle the ticket, then defaul assign them to the ticket
        newState.assignedTo = this._user_cw_contact_id;
      } else {
        newState.componentState = COMPONENT_STATES.CREATE_SPARK_CLIENT;
      }
      callback = this.fetchAttributes;
    } else {
      newState.componentState = COMPONENT_STATES.CREATE;
    }
    this.safeSetState(newState, callback);
  };
  handleSoftCancel = () => {
    // has anything been changed? if not, just close it
    const { title, description, files } = this.state;
    if (title || description || (files && files.length > 0)) {
      this.safeSetState({ componentState: COMPONENT_STATES.CONFIRM });
    } else {
      this.handleHideTicketCreateModal();
    }
  };
  handleGoToMyTickets = () => {
    this.handleHideTicketCreateModal();
    history.push('/my-tickets');
  };
  handleGoToMyTicketDetails = () => {
    this.handleHideTicketCreateModal();
    history.push(`/my-tickets/${this.state.ticketId}`);
  };
  handleGoToTicketDetails = () => {
    this.handleHideTicketCreateModal();
    history.push(`/my-organization/tickets/${this.state.ticketId}`);
  };
  handleShowTicketCreateModal = () => {
    this.handleClearNewTicketForm();
    this.safeSetState({
      isVisibleCreateTicketModal: true,
      componentState: this._starting_state,
    });
  };

  handleCreateTicketClick = () => {
    this.handleShowTicketCreateModal();
  };

  handleSelect = (text, value) => {
    const userFields = [
      'phoneOffice',
      'phoneMobile',
      'phoneOfficeExt',
      'contactMethod',
    ];

    const phoneFields = ['phoneOffice', 'phoneMobile'];

    if (phoneFields.includes(text)) {
      this.safeSetState({ [text]: formatPhoneWithSteps(value) });
    } else {
      this.safeSetState({ [text]: value });
    }
    if (userFields.includes(text)) {
      this.safeSetState({ wasUserInfoTouched: true });
    }
  };

  render() {
    const { children } = this.props;
    const { selectedClientName } = this.state;
    return (
      <React.Fragment>
        <AddTicketModal
          {...this.state}
          isSparkUser={this.props.userProfile?.is_spark_user}
          corsicaId={environment.current.corsicaCustomerId}
          clientName={
            /* This `clientName` field is to notify the user which client the ticket will be associated with.
             * This is a little complicated. if it is a spark user, they can select their client or corsica
             * to assign the ticket to. This is where we get the `selectedClientName`.
             * if its not a spark user, then this will default to the client id of the
             * current user, unless it is a corsica user, and they have selected a different client in the
             * customer select, then that is the client id that will be used.
             * */
            selectedClientName || this.props.clientName
          }
          COMPONENT_STATES={COMPONENT_STATES}
          userProfile={this.props.userProfile}
          handleHideTicketCreateModal={this.handleHideTicketCreateModal}
          handleFiles={this.handleFiles}
          handleSubmit={this.handleSubmit}
          handleContinue={this.handleContinue}
          handleSoftCancel={this.handleSoftCancel}
          handleSelectClient={this.handleSelectClient}
          handleGoToMyTickets={this.handleGoToMyTickets}
          handleGoToMyTicketDetails={this.handleGoToMyTicketDetails}
          handleGoToTicketDetails={this.handleGoToTicketDetails}
          handleFieldChange={(field, value) => this.handleSelect(field, value)}
        />
        {!!children &&
          typeof children === 'function' &&
          children({
            onClick: this.handleCreateTicketClick,
          })}
      </React.Fragment>
    );
  }
}

const WrappedCreateTicketWrapper = props => (
  <AppContext.Consumer>
    {({ userProfile, selectedCustomer, setUserProfile }) => (
      <CreateTicketWrapper
        {...props}
        clientName={selectedCustomer.name}
        client={selectedCustomer}
        userProfile={userProfile}
        setUserProfile={setUserProfile}
      />
    )}
  </AppContext.Consumer>
);

export default WrappedCreateTicketWrapper;
