import React from 'react';
import { Loader, Popup, Button } from 'semantic-ui-react';
import { ModalUserEdit } from '../';
import { Users, ErrorHandler } from '../../api';
import { AppContext } from '../../AppContext';
import isEqual from 'lodash/isEqual';

import './TableRowOptionPopup.scss';

class TableRowOptionPopup extends React.Component {
  name = '';

  constructor(props) {
    super(props);
    this.state = {
      ssoEnabled: false,
      isVisible: false,
      isSendingAction: null,
      userId: null,
      email: null,
      is_client_managed: props.is_client_managed,
    };
  }

  componentDidMount() {
    this.initUserFromProps();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.userData, this.props.userData)) {
      this.initUserFromProps();
    }
  }

  initUserFromProps = () => {
    const { userData } = this.props;

    if (userData) {
      this.name = [userData.first_name, userData.last_name]
        .filter(f => !!f)
        .join(' ');
    }
    if (userData) {
      this.setState({
        ssoEnabled: userData.ad_user,
        userId: userData.id,
        email: userData.email,
      });
    }
  };

  onShow = () => this.setState({ isVisible: true });
  onHide = () => this.setState({ isVisible: false });

  // disable/enable user function
  enableUser = () => {
    const row = this.props.userData;
    Users.update({
      user_id: row.id,
      values: {
        status: 1,
      },
    })
      .then(res => {
        if (res && res.body) {
          this.props.showSuccessToast(`${this.name} has been enabled.`);
          // Updating this key forces the userTable to re-fetch
          this.props.setUserTableReloadKey(Math.random() * 1000);
        }
      })
      .catch(err => {
        ErrorHandler.error(err);
        this.props.showErrorToast('There was an error.');
      });
  };

  disableUser = () => {
    const row = this.props.userData;
    Users.update({
      user_id: row.id,
      values: {
        status: 2,
      },
    })
      .then(res => {
        if (res && res.body) {
          this.props.showSuccessToast(`${this.name} has been disabled.`);
          // Updating this key forces the userTable to re-fetch
          this.props.setUserTableReloadKey(Math.random() * 1000);
        }
      })
      .catch(err => {
        ErrorHandler.error(err);
        this.props.showErrorToast('There was an error.');
      });
  };

  resetPassword = async () => {
    await Users.requestPasswordReset({
      email: this.state.email,
    })
      .then(
        this.setState({
          isSendingAction: true,
          userWeAreUpdating: this.state.userId,
        })
      )
      .then(res => {
        this.props.showSuccessToast(
          `A password reset email has been sent to ${this.state.email}.`
        );
        this.setState({
          isSendingAction: false,
        });
      })
      .catch(err => {
        this.setState({
          isSendingAction: false,
        });
        this.props.showErrorToast(`There was a problem sending the email.`);
      });
  };

  ssoEnableInvite = async () => {
    await Users.update({
      user_id: this.state.userId,
      values: {
        status: 1,
      },
    });
    this.sendInviteSSO();
  };

  sendInvite = async () => {
    if (this.state.ssoEnabled) {
      this.sendInviteSSO();
      return;
    }

    this.setState({
      isSendingAction: true,
      userWeAreUpdating: this.state.userId,
    });
    const res = await Users.invite({ id: this.state.userId });
    try {
      if (res && res.body) {
        this.props.showSuccessToast(`An invite has been sent to ${this.name}.`);
        // Updating this key forces the userTable to re-fetch
        this.props.setUserTableReloadKey(Math.random() * 1000);
        this.setState({
          isSendingAction: false,
          selectedStatus: null,
        });
      }
    } catch (err) {
      ErrorHandler.error(err);
      this.props.showErrorToast(`There was a problem sending the invite.`);
      this.setState({
        isSendingAction: false,
        selectedStatus: null,
      });
    }
  };

  // INVITE FUNCTION FOR SSO USERS ONLY
  sendInviteSSO = async () => {
    this.setState({
      isSendingAction: true,
      userWeAreUpdating: this.state.userId,
    });
    const res = await Users.inviteSSO({ id: this.state.userId });
    try {
      if (res && res.body) {
        this.props.showSuccessToast(`An invite has been sent to ${this.name}.`);
        // Updating this key forces the userTable to re-fetch
        this.props.setUserTableReloadKey(Math.random() * 1000);
        this.setState({
          isSendingAction: false,
          selectedStatus: null,
        });
      }
    } catch (err) {
      ErrorHandler.error(err);
      this.props.showErrorToast(`There was a problem sending the invite.`);
      this.setState({
        isSendingAction: false,
        selectedStatus: null,
      });
    }
  };

  resetMFA = () => {
    Users.resetMFA({
      user_id: this.state.userId,
      values: {
        mfa_type: null,
      },
    })
      .then(
        this.setState({
          isSendingAction: true,
          userWeAreUpdating: this.state.userId,
        })
      )
      .then(res => {
        if (res && res.body) {
          this.setState({
            showEditUserModal: false,
            showConfirmMFADelete: false,
            isSendingAction: false,
          });
          this.props.showSuccessToast(
            `MFA method successfully updated for ${this.name}.`
          );
        }
      })
      .catch(err => {
        ErrorHandler.error(err);
        this.setState({
          showEditUserModal: false,
          showConfirmMFADelete: false,
          isSendingAction: false,
        });
        this.props.showErrorToast(
          `There was a problem processing the MFA update.`
        );
      });
  };

  determineOptions = row => {
    const editUser = { text: 'Edit User', action: this.showEditUserModal };
    const invite = { text: 'Send Invite', action: this.sendInvite };
    const reInvite = { text: 'Re-send Invite', action: this.sendInvite };
    const enable = { text: 'Enable User', action: this.enableUser };
    const disable = { text: 'Disable User', action: this.disableUser };
    const resetMFA = { text: 'Reset MFA Method', action: this.showMFAModal };
    const enableAndInvite = {
      text: 'Enable User & Send Invite',
      action: this.ssoEnableInvite,
    };
    const resetPassword = {
      text: 'Reset Password',
      action: this.resetPassword,
    };

    const baseOptions = this.state.is_client_managed ? [editUser] : [];
    let extraOptions = [];
    const { status, ad_user } = row || {};
    const ssoEnabled = ad_user;

    switch (status) {
      case null:
      case 'not_invited':
        extraOptions = ssoEnabled ? [enableAndInvite] : [invite];
        break;
      case 'enabled':
        extraOptions = ssoEnabled
          ? [disable, reInvite]
          : [disable, resetMFA, resetPassword];
        break;
      case 'disabled':
        extraOptions = ssoEnabled ? [enable] : [enable, resetMFA];
        break;
      case 'invited':
        extraOptions = [reInvite];
        break;
      default:
    }

    // Return a single array of all available options
    return baseOptions.concat(extraOptions);
  };

  showEditUserModal = () => {
    this.setState({
      showEditUserModal: true,
    });
  };

  showMFAModal = () => {
    this.setState({
      showConfirmMFADelete: true,
      showEditUserModal: true,
    });
  };

  handleCloseUserEditModal = () =>
    this.setState({ showEditUserModal: false, showConfirmMFADelete: false });

  render() {
    const { isVisible, showEditUserModal, showConfirmMFADelete } = this.state;
    const { userData } = this.props;
    const options = this.determineOptions(userData) || [];
    return (
      <>
        {showEditUserModal && (
          <ModalUserEdit
            isMulti={this.props.isMulti}
            onCancel={this.handleCloseUserEditModal}
            showSuccessToast={this.props.showSuccessToast}
            showErrorToast={this.props.showErrorToast}
            selectedUser={userData}
            showEditUserModal={showEditUserModal}
            showConfirmMFADelete={showConfirmMFADelete}
            resetMFA={this.resetMFA}
            setUserTableReloadKey={this.props.setUserTableReloadKey}
          />
        )}
        <Popup
          className="TableRowOptionPopup"
          trigger={
            this.state.isSendingAction ? (
              <div className="user-loader">
                <Loader active size="tiny" />
              </div>
            ) : (
              <Button className="ghost" circular icon="ellipsis horizontal" />
            )
          }
          on="click"
          basic
          position="left center"
          open={isVisible}
          onOpen={this.onShow}
          onClose={this.onHide}
          content={
            <div className="EditMenu">
              {options.map((option, index) => {
                return (
                  <p
                    key={index}
                    onClick={() => {
                      option.action();
                      this.onHide();
                    }}>
                    {option.text}
                  </p>
                );
              })}
            </div>
          }
        />
      </>
    );
  }
}

const WrappedTableRowOptionPopup = props => (
  <AppContext.Consumer>
    {({ addToast, setUserTableReloadKey }) => (
      <TableRowOptionPopup
        {...props}
        setUserTableReloadKey={setUserTableReloadKey}
        showErrorToast={message => addToast({ type: 'error', message })}
        showSuccessToast={message => addToast({ type: 'success', message })}
      />
    )}
  </AppContext.Consumer>
);

export default WrappedTableRowOptionPopup;
