import React from 'react';

import { Dashboard } from '../../api';
import { Widget } from '../Widget/Widget';
import ShortDashWidget from '../ShortDashWidget/ShortDashWidget';

export default class WidgetOverallCsat extends React.Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
      csat: null,
    };
  }

  componentDidMount() {
    this._fetch();
  }

  _fetch = () => {
    this.setState({ isLoading: true });

    const request = Dashboard.csatPercentage();

    this.cancelRequest = request;

    request
      .then(res => {
        if (res && res.body) {
          this.setState({
            isLoading: false,
            csat: res.body.data.satisfaction_percentage,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  };

  componentWillUnmount() {
    this.cancelAllRequests();
  }

  cancelAllRequests = () => {
    if (this.cancelRequest && typeof this.cancelRequest.abort === 'function') {
      this.cancelRequest.abort();
    }
  };

  render() {
    const { csat, isLoading } = this.state;
    return (
      <Widget
        widgetName="WidgetOverallCsat"
        isLoading={isLoading}
        isEmpty={!csat && csat !== 0}
        emptyStateMessage="No surveys received."
        link="/my-organization/tickets?tab=resolved&csat_rating_name=great%7Cgood%7Cbad"
        title="Overall Satisfaction"
        subtitle="in last 90 days"
        className="csat-widget widget-loader-right height-1">
        <ShortDashWidget
          number={(csat !== null && csat !== undefined && `${csat}%`) || '--'}
        />
      </Widget>
    );
  }
}
