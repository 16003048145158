import React from 'react';

import { Card } from '../';

import './DetailCard.scss';

const DetailCard = ({ details, className, ...otherProps }) => (
  <Card className={`DetailCard ${className || ''}`} {...otherProps}>
    {details &&
      details.map((row, i) => (
        <div className="column" key={i}>
          <div className="row title">{row.title}</div>
          <div className="row">{row.value}</div>
        </div>
      ))}
  </Card>
);

export default DetailCard;
