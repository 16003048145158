import React from 'react';
import { Link } from 'react-router-dom';
import { widgetClick } from '../../utils/analytics';

import { Card } from '../';
import { Icon } from 'semantic-ui-react';

import './Widget.scss';

export const Widget = ({
  link,
  widgetName,
  title,
  className,
  subtitle,
  footerLink,
  footerLinkTitle,
  children,
  isLoading,
  ...rest
}) => {
  return (
    <Card
      className={`dashboard-widget ${className || ''}`}
      title={title}
      subtitle={subtitle}
      isLoading={isLoading}
      onClick={link ? () => widgetClick({ link, name: widgetName }) : null}
      footer={
        footerLink && (
          <Link to={footerLink}>
            {footerLinkTitle}
            <Icon name="chevron right" />
          </Link>
        )
      }
      {...rest}>
      {children}
    </Card>
  );
};

export default Widget;
