import React from 'react';
import { Loader } from 'semantic-ui-react';
import format from 'date-fns/format';

const LastUpdated = ({ date, isLoading }) =>
  isLoading ? (
    <Loader active size="tiny" />
  ) : date ? (
    <React.Fragment>
      {`Last updated ${format(date, 'M/DD/YYYY')} at ${format(date, 'h:mm A')}`}
    </React.Fragment>
  ) : null;

export default LastUpdated;
