import React, { useState, useEffect } from 'react';
import { Dashboard, ErrorHandler } from '../../api';
import { WidgetProgressRows } from '../../components';
import { roundPercent } from '../../utils/utils';
import { widgetClick } from '../../utils/analytics';

const colorChangeThresholds = {
  lessThan85: 'red',
  between85and95: 'orange',
  moreThan95: 'blue',
};

const getPercentColor = percent => {
  if (percent >= 95) {
    return colorChangeThresholds.moreThan95;
  } else if (percent >= 85 && percent < 95) {
    return colorChangeThresholds.between85and95;
  } else {
    return colorChangeThresholds.lessThan85;
  }
};

function WidgetPatchingStatus({ ...props }) {
  const [totalPercent, setTotalPercent] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // need to prevent attempting to set state on unmounted components
    let unmounted = false;

    const fetchData = async () => {
      let res;
      try {
        res = await Dashboard.patchingStatus();
      } catch (err) {
        ErrorHandler.error(err);
      }

      if (res?.body?.data && !unmounted) {
        const { data } = res.body;

        setTotalPercent(data.devices_patched_percent);

        const labelStyles = { fontSize: '18px', fontWeight: 'bold' };

        const _rows = [
          {
            labelStyles,
            subLabel: 'Workstations',
            labelLeft: `${roundPercent(data.workstations_patched_percent)}%`,
            percent: data.workstations_patched_percent,
            color: getPercentColor(data.workstations_patched_percent),
            onClick: () =>
              widgetClick({
                link: '/devices/patching?device_category=Workstation',
                name: 'WidgetDevicesPatchingStatus-workstationClick',
              }),
          },
          {
            labelStyles,
            subLabel: 'Servers',
            labelLeft: `${roundPercent(data.servers_patched_percent)}%`,
            percent: data.servers_patched_percent,
            color: getPercentColor(data.servers_patched_percent),
            onClick: () =>
              widgetClick({
                link: '/devices/patching?device_category=Server',
                name: 'WidgetDevicesPatchingStatus-serverClick',
              }),
          },
        ];

        setRows(_rows);
      }
      if (!unmounted) {
        setLoading(false);
      }
    };
    fetchData();

    // on dismount this return callback gets called
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <WidgetProgressRows
      className="patching-status"
      title="Patching Status"
      subtitle="as of today"
      mainValue={`${roundPercent(totalPercent)}%`}
      mainValueOnClick={() =>
        widgetClick({
          link: '/devices/patching',
          name: 'WidgetDevicesPatchingStatus-mainValueClick',
        })
      }
      mainLabel="Patched"
      isLoading={loading}
      rows={rows}
      {...props}
    />
  );
}

export default WidgetPatchingStatus;
