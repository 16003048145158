import React, { useState, useEffect } from 'react';
import { Modal, Message, Button } from 'semantic-ui-react';
import { FormInput } from '../';
import { AppContext } from '../../AppContext';
import { formatPhoneWithSteps } from '../../utils/utils';
import { Users, ErrorHandler } from '../../api';

import './UserProfileModal.scss';

const phoneRegExp = /^\(\d{3}\)\s\d{3}-\d{4}/;

function UserProfileModal({
  isVisible,
  onClose,
  userProfile,
  showSuccessToast,
  ...otherProps
}) {
  const [firstName, setFirstName] = useState(userProfile?.first_name || '');
  const [lastName, setLastName] = useState(userProfile?.last_name || '');
  const [email, setEmail] = useState(userProfile?.email || '');
  const [jobTitle, setJobTitle] = useState(userProfile?.job_title || '');
  const [phoneMobile, setPhoneMobile] = useState(
    formatPhoneWithSteps(userProfile?.mobile_phone || '')
  );
  const [phoneOffice, setPhoneOffice] = useState(
    formatPhoneWithSteps(userProfile?.office_phone || '')
  );
  const [phoneOfficeExt, setPhoneOfficeExt] = useState(
    userProfile?.office_phone_ext || ''
  );

  const [isErrorFirstName, setIsErrorFirstName] = useState(false);
  const [isErrorLastName, setIsErrorLastName] = useState(false);
  const [isErrorJobTitle, setIsErrorJobTitle] = useState(false);
  const [isErrorPhoneMobile, setIsErrorPhoneMobile] = useState(false);
  const [isErrorPhoneOffice, setIsErrorPhoneOffice] = useState(false);

  const [isError, setIsError] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleSubmit() {
    setIsSubmitting(true);

    let pendingValidation = true;
    if (!firstName) {
      pendingValidation = false;
      setIsErrorFirstName('First Name is required');
    }
    if (!lastName) {
      pendingValidation = false;
      setIsErrorLastName('Last Name is required');
    }
    if (!jobTitle) {
      pendingValidation = false;
      setIsErrorJobTitle('Job Title is required');
    }
    if (!phoneMobile && !phoneOffice) {
      pendingValidation = false;
      setIsErrorPhoneMobile('At least one phone number is required');
      setIsErrorPhoneOffice('At least one phone number is required');
    }
    if (phoneMobile && !String(phoneMobile).match(phoneRegExp)) {
      pendingValidation = false;
      setIsErrorPhoneMobile('Mobile Phone is invalid');
    }
    if (phoneOffice && !String(phoneOffice).match(phoneRegExp)) {
      pendingValidation = false;
      setIsErrorPhoneOffice('Office Phone is invalid');
    }

    if (!pendingValidation) {
      setIsSubmitting(false);
      return false;
    }

    try {
      await Users.update({
        user_id: userProfile?.id,
        values: {
          first_name: firstName,
          last_name: lastName,
          job_title: jobTitle,
          mobile_phone: String(phoneMobile).replace(/[^\d]/g, ''),
          office_phone: String(phoneOffice).replace(/[^\d]/g, ''),
          office_phone_ext: phoneOfficeExt,
        },
      });
      showSuccessToast('User Profile updated successfully!');
      onClose();
    } catch (err) {
      setIsError(true);
      ErrorHandler.error(err);
    }
    setIsSubmitting(false);
  }

  // as userProfile updates async, we need to update our context
  useEffect(() => {
    setEmail(userProfile?.email || '');
    setFirstName(userProfile?.first_name || '');
    setLastName(userProfile?.last_name || '');
    setJobTitle(userProfile?.job_title || '');
    setPhoneMobile(formatPhoneWithSteps(userProfile?.mobile_phone || ''));
    setPhoneOffice(formatPhoneWithSteps(userProfile?.office_phone || ''));
    setPhoneOfficeExt(userProfile?.office_phone_ext || '');
  }, [userProfile]);

  return (
    <Modal
      className="UserProfileModal"
      onClose={otherProps.handleSoftCancel}
      open={isVisible}
      size="tiny">
      <Modal.Header style={{ borderBottom: 'none' }}>
        <h2>User Profile</h2>
      </Modal.Header>
      <Modal.Content>
        {isError && (
          <Message negative>There was an error creating the ticket</Message>
        )}
        <div className="form-row">
          <FormInput
            style={{ width: '48%' }}
            label="First Name"
            onChange={(e, { value }) => {
              setFirstName(value);
              setIsErrorFirstName(false);
            }}
            value={firstName}
            error={isErrorFirstName}
            errorMessage={isErrorFirstName}
            autoFocus={true}
            required
          />
          <FormInput
            style={{ width: '48%' }}
            label="Last Name"
            onChange={(e, { value }) => {
              setLastName(value);
              setIsErrorLastName(false);
            }}
            value={lastName}
            error={isErrorLastName}
            errorMessage={isErrorLastName}
            required
          />
        </div>
        <div className="form-row">
          <FormInput
            style={{ width: '100%' }}
            label="Job Title"
            onChange={(e, { value }) => {
              setJobTitle(value);
              setIsErrorJobTitle(false);
            }}
            value={jobTitle}
            error={isErrorJobTitle}
            errorMessage={isErrorJobTitle}
            required
          />
        </div>
        <div className="form-row">
          <FormInput
            style={{ width: '100%' }}
            label="Email Address"
            value={email}
            disabled
          />
        </div>
        <div className="form-row">
          <FormInput
            style={{ width: '37%' }}
            label="Mobile Phone Number"
            onChange={(e, { value }) => {
              const formatted = formatPhoneWithSteps(value);
              setPhoneMobile(formatted);
              setIsErrorPhoneMobile(false);
            }}
            value={phoneMobile}
            error={isErrorPhoneMobile}
            errorMessage={isErrorPhoneMobile}
          />
          <FormInput
            style={{ width: '37%' }}
            label="Office Phone Number"
            onChange={(e, { value }) => {
              const formatted = formatPhoneWithSteps(value);
              setPhoneOffice(formatted);
              setIsErrorPhoneOffice(false);
            }}
            value={phoneOffice}
            error={isErrorPhoneOffice}
            errorMessage={isErrorPhoneOffice}
          />
          <FormInput
            style={{ width: '18%', marginTop: '22px' }}
            label=""
            onChange={(e, { value }) => {
              const newVal = String(value).replace(/[^0-9.]/gi, '');
              setPhoneOfficeExt(newVal);
            }}
            value={phoneOfficeExt}
            placeholder="Ext"
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button primary loading={isSubmitting} onClick={handleSubmit}>
          Update Profile
        </Button>
        <Button className="ghost secondary" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

const WrappedUserProfileModal = props => (
  <AppContext.Consumer>
    {({ userProfile, addToast }) => (
      <UserProfileModal
        {...props}
        showSuccessToast={message => addToast({ type: 'success', message })}
        userProfile={userProfile}
      />
    )}
  </AppContext.Consumer>
);

export default WrappedUserProfileModal;
