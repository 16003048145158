import React from 'react';

import { Dashboard, ErrorHandler } from '../../api';
import { Widget } from '../Widget/Widget';
import { numberWithCommas } from '../../utils';
import ShortDashWidget from '../ShortDashWidget/ShortDashWidget';

export default class WidgetWorkstationCount extends React.Component {
  _isMounted = false;

  constructor() {
    super();

    this.state = {
      isLoading: true,
      workstationCount: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });

    let res;
    try {
      res = await Dashboard.workstationCount();
    } catch (err) {
      ErrorHandler.error(err);
    }

    this.safeSetState({
      isLoading: false,
      workstationCount: res?.body?.data?.workstation_count,
    });
  };

  render() {
    const { workstationCount, isLoading } = this.state;
    return (
      <Widget
        isLoading={isLoading}
        widgetName="WidgetWorkstationCount"
        link="/devices/list/workstations"
        title="Active Workstations"
        subtitle="as of today"
        isEmpty={!workstationCount && workstationCount !== 0}
        emptyStateMessage="No workstation count data found."
        className="WorkstationCount-widget widget-loader-right height-1">
        <ShortDashWidget number={numberWithCommas(workstationCount)} />
      </Widget>
    );
  }
}
