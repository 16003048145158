import React from 'react';

import { FormSelect } from '../../components/';

function AssigneeSelect({
  isLoading,
  assignees,
  selectedUser,
  error,
  onChange,
}) {
  let options = [];

  if (assignees) {
    options = assignees.map(user => ({
      text: [user.name, user.role].filter(f => !!f).join(' - '),
      value: user.id,
      group: user.group === 'corsica' ? 'Corsica Team' : 'Client Organization',
    }));
  }

  return (
    <FormSelect
      fluid
      isLoading={isLoading}
      options={options}
      value={selectedUser}
      error={error}
      onChange={onChange}
      groupBy={true}
      groupSortFunction={(a, b) => (a > b ? -1 : 1)}
    />
  );
}

export default React.memo(AssigneeSelect);
