import React from 'react';
import { format } from 'date-fns';

import { Dashboard, ErrorHandler } from '../../api';
import { Widget } from '../Widget/Widget';
import { numberWithCommas, buildArrayOfDatesSinceStart } from '../../utils';
import AreaChartSecurityIncidents from './AreaChartSecurityIncidents';

import './WidgetSecurityIncidents.scss';

export default class WidgetSecurityIncidents extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      securityIncidents: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });

    let res;
    try {
      res = await Dashboard.securityIncidents();
    } catch (err) {
      ErrorHandler.error(err);
    }

    let securityIncidents = null;

    // fill entire 7 day span
    let eventData = buildArrayOfDatesSinceStart({
      count: 7,
      span: 'days',
      formatter: date => ({
        event_date: format(date, 'YYYY-MM-DD'),
        incidents_count: 0,
      }),
    });

    if (res && res.body) {
      const { data, meta } = res.body;
      if (data) {
        eventData = eventData.map(obj => {
          return data.find(day => day.event_date === obj.event_date) || obj;
        });
      }
      if (meta) {
        securityIncidents = meta.record_count;
      }
    }

    this.safeSetState({
      eventData,
      isLoading: false,
      securityIncidents,
    });
  };

  render() {
    const { securityIncidents, isLoading, eventData } = this.state;
    return (
      <Widget
        title="Security Incidents"
        subtitle="in last 7 days"
        isLoading={isLoading}
        link="/security/incidents"
        widgetName="WidgetSecurityIncidents"
        isEmpty={!securityIncidents && securityIncidents !== 0}
        emptyStateMessage="No security incidents detected in the last 7 days."
        className="SecurityIncidents-widget height-3">
        <div className="securityIncidentsCount">
          {numberWithCommas(securityIncidents)}
        </div>
        <AreaChartSecurityIncidents data={eventData} isLoading={isLoading} />
      </Widget>
    );
  }
}
