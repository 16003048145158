import React from 'react';
import format from 'date-fns/format';
import { colors } from '../../styles/utils';

import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer } from 'recharts';

export const AreaChartMalwareEvents = ({ data, isLoading, className }) => (
  <ResponsiveContainer className={className} width="100%" height="50%">
    <AreaChart data={data}>
      <defs>
        <linearGradient id="OrangeGradient" x1="0" y1="0" x2="0" y2="1">
          <stop offset="1%" stopColor={colors.orange} stopOpacity={0.7} />
          <stop offset="99%" stopColor={colors.orange} stopOpacity={0} />
        </linearGradient>
      </defs>
      <YAxis axisLine={false} tickLine={false} hide />
      <XAxis
        dataKey="date"
        name="Date"
        tickLine={false}
        axisLine={false}
        tickFormatter={date => format(date, 'M/D/YYYY')}
        hide
      />
      <Area
        stroke={colors.orange}
        fill="url(#OrangeGradient)"
        fillOpacity={1}
        dataKey="malicious_count"
        name="Malicious"
      />
    </AreaChart>
  </ResponsiveContainer>
);

export default AreaChartMalwareEvents;
