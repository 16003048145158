import React from 'react';

import './TabularList.scss';

export default function TabularList({ rows }) {
  return (
    <div className="TabularList">
      {rows.map((row, index) => (
        <div
          key={`tlr-${index}`}
          className="row"
          onClick={typeof row.onClick === 'function' ? row.onClick : null}>
          <span>{row.title}</span>
          <span>{row.value}</span>
        </div>
      ))}
    </div>
  );
}
