import React from 'react';
import { Button, Card } from 'semantic-ui-react';

const NothingFound = ({ title, description, actions }) => {
  return (
    <Card centered>
      <Card.Content>
        <Card.Header>{title}</Card.Header>
        <Card.Description>{description}</Card.Description>
      </Card.Content>
      {actions && (
        <Card.Content>
          {actions.map((action, i) => (
            <Button onClick={action.action} key={i}>
              {action.title}
            </Button>
          ))}
        </Card.Content>
      )}
    </Card>
  );
};

export default NothingFound;
