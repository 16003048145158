import React, { useState, useEffect } from 'react';
import { DonutWidget } from '..';
import { numberWithCommas, getPercent } from '../../utils/utils';
import { Dashboard, ErrorHandler } from '../../api';
import { widgetClick } from '../../utils/analytics';

import './WidgetIncidentTypes.scss';

const getChartData = ({ ticketTypes, totalCount }) => {
  if (!ticketTypes || typeof ticketTypes !== 'object') {
    return [];
  }
  const titles = Object.keys(ticketTypes);

  const colors = [
    '#577590',
    '#43AA8B',
    '#90BE6D',
    '#F9C74F',
    '#F8961E',
    '#F3722C',
  ];

  return titles.map((key, index) => ({
    name: key,
    link: '/login',
    value: ticketTypes[key],
    color: colors[index],
    key: `${key.toLowerCase()}-count`,
  }));
};

function fixPercent(percent, dividend, divisor) {
  let result = percent;
  if (isNaN(dividend) || isNaN(divisor)) {
    return percent;
  }

  let actualPercent = dividend / divisor;

  if (percent === 100) {
    result = actualPercent === 100 ? 100 : '>99';
  } else if (percent === 0) {
    result = actualPercent === 0 ? 0 : '<1';
  }

  return result;
}

const centerTitle = 'Incidents';
const size = 170;
const innerRadius = '80%';
const startAngle = 90;
const endAngle = -270;
const legendBackground = '#FFFFFF';
const legendTitle = '';
const legendPosition = 'right';

const WidgetIncidentTypes = ({ className, subtitle }) => {
  const [total, setTotal] = useState();
  const [chartData, setChartData] = useState([]);
  const [otherTypes, setOtherTypes] = useState(['none']);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // need to prevent attempting to set state on unmounted components
    let unmounted = false;

    const fetchData = async () => {
      let res;
      try {
        res = await Dashboard.incidentTypeDistribution();
      } catch (err) {
        ErrorHandler.error(err);
      }

      if (res?.body?.data && !unmounted) {
        const { data } = res.body;

        setTotal(data.total_count);
        setOtherTypes([...(data.other_types || []), 'none']);
        setChartData(
          getChartData({
            ticketTypes: data.type_counts,
            totalCount: data.total_count,
          })
        );
      }
      if (!unmounted) {
        setLoading(false);
      }
    };
    fetchData();

    // on dismount this return callback gets called
    return () => {
      unmounted = true;
    };
  }, []);

  function goToTickets(name) {
    let link = false;
    if (name === 'Other') {
      link = `/my-organization/tickets?tab=resolved&ticket_type=${escape(
        otherTypes.join('|')
      )}&ticket_category=Incident`;
    } else {
      link = `/my-organization/tickets?tab=resolved&ticket_type=${escape(
        name
      )}&ticket_category=Incident`;
    }
    if (link) {
      widgetClick({ link, name: 'WidgetIncidentTypes' });
    }
  }

  return (
    <DonutWidget
      title="Incident Types"
      subtitle="resolved in last 90 days"
      className={['WidgetIncidentTypes', className].filter(f => !!f).join(' ')}
      style={{ width: '100%' }}
      isLoading={loading}
      legendPosition={legendPosition}
      data={chartData}
      dataKey="value"
      centerTitle={centerTitle}
      centerCount={numberWithCommas(total)}
      size={size}
      innerRadius={innerRadius}
      startAngle={startAngle}
      endAngle={endAngle}
      legendTitle={legendTitle}
      legendBackground={legendBackground}
      legendFormatter={({ name, value }) => (
        <button
          className="link primary"
          onClick={() => goToTickets(name)}>{`${name} (${fixPercent(
          getPercent(value, total),
          value,
          total
        )}%)`}</button>
      )}
    />
  );
};

export default WidgetIncidentTypes;
