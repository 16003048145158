import React from 'react';
import { ellipsize } from '../utils';

export const DeviceTick = props => {
  return (
    <text
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.height}
      textAnchor={props.textAnchor}
      style={{ fontSize: '0.75rem' }}
      fill={props.fill}
      stroke={props.stroke}>
      <tspan y={props.payload.tickCoord} dy={3}>
        <title>{props.payload.value}</title>
        {props.payload.value && ellipsize(props.payload.value, 20)}
      </tspan>
    </text>
  );
};
