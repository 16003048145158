export const UserRoles = {
  admin: 'Admin',
  user: 'User',
  it_operations: 'IT Operations',
  management: 'Management',
};

export const UserStatuses = {
  invited: 'Invited',
  active: 'Active',
  inactive: 'Inactive',
};
