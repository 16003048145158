import bugsnag from '@bugsnag/js'; // initialize bugsnag ASAP, before other imports
import { environment } from '../environment';

let bugsnagClient;

if (!environment.isTesting) {
  bugsnagClient = bugsnag({
    apiKey: 'b9bf2cf780c1a51e59d636380e77a990',
    notifyReleaseStages: ['development', 'staging', 'production'],
    releaseStage: environment.current.name,
    logger: null,
  });
} else {
  // stub out a fake bugsnag that wont break tests
  bugsnagClient = {
    getPlugin() {
      return {
        requestHandler(req, res, next) {
          req.bugsnag = { notify() {} };
          return next();
        },
        errorHandler(req, res, next) {
          return next();
        },
      };
    },
  };
}

export { bugsnagClient };

export default bugsnagClient;
