import React from 'react';
import { BaseWarningPage } from '../';

import './IEWarning.scss';

const IEWarning = () => {
  return (
    <BaseWarningPage
      className="IEWarning"
      title="Browser Not Supported"
      body={
        <>
          Unfortunately, Internet Explorer does not support the web technology
          that powers the Corsica Client Portal.
          <br />
          <br />
          We recommend using{' '}
          <a
            className="vendor-link"
            href="https://google.com/chrome"
            rel="noopener noreferrer">
            Google Chrome
          </a>
          {', '}
          <a
            className="vendor-link"
            href="https://getfirefox.com"
            rel="noopener noreferrer">
            Firefox
          </a>
          {' or '}
          <a
            className="vendor-link"
            href="https://www.microsoft.com/en-us/edge"
            rel="noopener noreferrer">
            Microsoft Edge
          </a>
          {'.'}
        </>
      }
    />
  );
};
export default IEWarning;
