import React from 'react';

import './DetailRowItem.scss';

const DetailRowItem = ({ title, value }) => (
  <div className="DetailRowItem">
    <span className="title">{title}:</span>
    <span className="value">{value}</span>
  </div>
);

export default DetailRowItem;
