import React, { useEffect } from 'react';
import { AppContext } from '../../AppContext';

const HubSpotCTA = ({ portalId, ctaId }) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = '//js.hs-scripts.com/8677164.js';
    script.async = true;
    script.defer = true;
    script.id = 'hs-script-loader';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hscta.net/cta/current.js'; // This is the HubSpot CTA embed script
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.cta.load(portalId, ctaId, {});
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [portalId, ctaId]);

  let idForCTA = `hs-cta-${ctaId}`;

  return (
    <div className="hs-cta-wrapper" id={idForCTA}>
      {/* This div will be replaced by your CTA */}
    </div>
  );
};

const WrappedHubSpotCTA = props => (
  <AppContext.Consumer>
    {({ userProfile, imitateClientId }) => (
      <HubSpotCTA
        {...props}
        imitateClientId={imitateClientId}
        userProfile={userProfile}
      />
    )}
  </AppContext.Consumer>
);

export default WrappedHubSpotCTA;
