import React from 'react';
import { Transition } from 'react-transition-group';

const duration = 400;

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  opacity: 0,
  transform: 'translateX(10%)',
};

const transitionStyles = {
  entering: {
    opacity: 0,
    transform: 'translateX(10%)',
  },
  entered: {
    opacity: 1,
    transform: 'translateX(0)',
  },
  exiting: {
    opacity: 1,
    transform: 'translateX(0)',
  },
  exited: {
    opacity: 0,
    transform: 'translateX(10%)',
  },
};

const TransitionInOut = ({ in: isVisible, children }) => (
  <Transition in={isVisible} timeout={400} appear={true} exit={false}>
    {state => {
      return (
        <div style={{ ...defaultStyle, ...transitionStyles[state] }}>
          {children}
        </div>
      );
    }}
  </Transition>
);

export default TransitionInOut;
