import React from 'react';
import { environment } from '../environment';
import { Badge } from './';
import { AppContext } from '../AppContext';

const FeatureBadge = ({ feature, isCorsicaUser, selectedCustomer, style }) => {
  const { active_features } = selectedCustomer;
  const featureDetails = active_features.find(
    _feature => _feature.name === feature
  );
  // is symplexity the currently selected customer account
  const isSplxSelected =
    environment.current.corsicaCustomerId === selectedCustomer.id;

  const isUpsellFeature = featureDetails && featureDetails.is_upsell;
  const isInactiveFeature =
    // was a feature even passed.
    feature &&
    // is a customer account other than symplexity selected
    !isSplxSelected &&
    // is the current user a symplexity user
    isCorsicaUser &&
    // is the feature NOT listed in features
    !featureDetails;

  const showNotVisibleBadge = isInactiveFeature && !isUpsellFeature;
  const showUpsellVisibleBadge = isInactiveFeature && isUpsellFeature;

  return showNotVisibleBadge ? (
    <Badge
      color="orange"
      className="FeatureBadge"
      style={{
        fontSize: '.75rem',
        verticalAlign: 'middle',
        marginLeft: '.5rem',
        ...style,
      }}>
      Not visible to client
    </Badge>
  ) : showUpsellVisibleBadge ? (
    <Badge
      color="fuchsia"
      className="FeatureBadge"
      style={{
        fontSize: '.75rem',
        verticalAlign: 'middle',
        marginLeft: '.5rem',
        ...style,
      }}>
      Feature description visible to client
    </Badge>
  ) : null;
};

const WrappedFeatureBadge = props => (
  <AppContext.Consumer>
    {({ selectedCustomer, isCorsicaUser }) => (
      <FeatureBadge
        {...props}
        isCorsicaUser={isCorsicaUser}
        selectedCustomer={selectedCustomer}
      />
    )}
  </AppContext.Consumer>
);

export default WrappedFeatureBadge;
