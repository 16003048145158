import React from 'react';

import { Dashboard, ErrorHandler } from '../../api';
import { Widget } from '../Widget/Widget';
import ShortDashWidget from '../ShortDashWidget/ShortDashWidget';

import './WidgetEmailSecurity.scss';

export default class WidgetEmailSecurity extends React.Component {
  _isMounted = false;

  constructor() {
    super();

    this.state = {
      isLoading: true,
      failedTestCount: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });

    let res;
    try {
      res = await Dashboard.emailSecurity();
    } catch (err) {
      ErrorHandler.error(err);
    }

    let failedTestCount = null;
    if (res && res.body && res.body.data && res.body.data.failed_count) {
      failedTestCount = res.body.data.failed_count;
    }
    this.safeSetState({
      isLoading: false,
      failedTestCount,
    });
  };

  render() {
    const { failedTestCount, isLoading } = this.state;
    return (
      <Widget
        isLoading={isLoading}
        link="/my-organization/email-security"
        widgetName="WidgetEmailSecurity"
        title="Phish Tests Failed"
        subtitle="in current month"
        isEmpty={!failedTestCount && failedTestCount !== 0}
        emptyStateMessage="No Failures"
        className="email-security-widget widget-loader-right height-1">
        <ShortDashWidget number={failedTestCount} color="red" />
      </Widget>
    );
  }
}
