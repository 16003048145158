import React from 'react';

import { Progress } from 'semantic-ui-react';

import './ProgressBar.scss';

export const colors = {
  red: 'red',
  orange: 'orange',
  blue: 'blue',
  'red-2': 'red-2',
  'red-3': 'red-3',
  'red-4': 'red-4',
  'red-5': 'red-5',
};

export const ProgressBar = ({
  labelStyles,
  labelLeft,
  labelRight,
  subLabel,
  className,
  percent,
  color,
  onClick,
}) => (
  <div
    className={[
      'ProgressBar',
      className,
      colors[color],
      typeof onClick === 'function' && 'clickable',
    ]
      .filter(f => !!f)
      .join(' ')}
    onClick={onClick}>
    <div className="labels" style={labelStyles || null}>
      <div className="label-left">{labelLeft}</div>
      <div className="label-right">{labelRight}</div>
    </div>
    <Progress percent={percent} size="tiny" />
    <div className="sub-label">{subLabel}</div>
  </div>
);

export default ProgressBar;
