export const jwtDecode = token => {
  const base64Url = token.split('.')[1];

  if (!base64Url) {
    return false;
  }

  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};
