import React from 'react';

import { HeaderWidget } from '../';

export const SatisfactionWidget = ({ percent, ...otherProps }) => (
  <HeaderWidget
    {...otherProps}
    title="Overall Satisfaction"
    subtitle="in last 90 days"
    value={`${percent || 0}%`}
    color="green"
    className="satisfaction"
  />
);

export default SatisfactionWidget;
