import { BANNER_HIDE, BANNER_SHOW } from '../constants/actionTypes';

const initialState = {
  bannerIsVisible: false,
};

export default function banner(state = initialState, action) {
  switch (action.type) {
    case BANNER_HIDE:
      return { ...state, bannerIsVisible: false };

    case BANNER_SHOW:
      return { ...state, bannerIsVisible: true };

    default:
      return state;
  }
}
