import React from 'react';
import { Card, TabularList } from '../../components';
import { numberWithCommas } from '../../utils/utils';
import { Dashboard, usePromise } from '../../api';
import { widgetClick } from '../../utils/analytics';

import './WidgetRequests.scss';

function WidgetRequests() {
  const { response, isLoading } = usePromise(Dashboard.requestsCount);

  const data = response?.body?.data;
  const count = data?.total_count;
  const openCount = data?.open_count;
  const resolvedCount = data?.resolved_count;

  const rows = [
    {
      onClick: () => {
        widgetClick({
          link: '/my-organization/tickets?tab=open&ticket_category=Request',
          name: 'WidgetRequests',
        });
      },
      title: 'Open',
      value: openCount,
    },
    {
      onClick: () => {
        widgetClick({
          link: '/my-organization/tickets?tab=resolved&ticket_category=Request',
          name: 'WidgetRequests',
        });
      },
      title: 'Resolved',
      value: resolvedCount,
    },
  ];

  return (
    <Card
      isLoading={isLoading}
      className="WidgetRequests"
      title="Requests"
      subtitle="in last 90 days">
      <div className="count">{numberWithCommas(count)}</div>
      <TabularList rows={rows} />
    </Card>
  );
}

export default WidgetRequests;
