export const localKeys = {
  jwt_token: 'jwtToken',
  e2e_token: 'e2eToken',
  user_email: 'userEmail',
  has_requested_eol_contact: 'hasRequestedEOLContact',
  forced_reload: 'forceReload',
  hide_windows_11_notification: 'hideWin11Notification',
  hide_rdp_notification: 'hideRDPNotification',
};

export const sessionKeys = {
  session_ip: 'sessionIP',
  hide_eol_notification: 'hideEOLNotification',
  session_start: 'sessionStart',
  session_id: 'sessionId',
};

// To be cleared on auth logout

const localAuthKeys = [localKeys.jwt_token, localKeys.e2e_token];

const sessionAuthKeys = [sessionKeys.session_id, sessionKeys.session_ip];

export const clearAuthCache = () => {
  localAuthKeys.forEach(key => localStorage?.removeItem(key));
  sessionAuthKeys.forEach(key => sessionStorage?.removeItem(key));
};

export const setLocalKey = (key, value) => {
  localStorage?.setItem(`${key}`, value);
};

export const removeLocalKey = key => {
  localStorage?.removeItem(`${key}`);
};

export const getLocalKey = key => {
  return localStorage?.getItem(`${key}`) || null;
};

export const getLocalStorage = () => {
  return localStorage;
};

export const setSessionKey = (key, value) => {
  sessionStorage?.setItem(`${key}`, value);
};

export const removeSessionKey = key => {
  sessionStorage?.removeItem(`${key}`);
};

export const getSessionKey = key => {
  return sessionStorage?.getItem(`${key}`) || null;
};

export const getSession = () => {
  return sessionStorage;
};
