import React from 'react';
import { DonutWidget } from '../../components';
import { numberWithCommas } from '../../utils/utils';
import { Dashboard, usePromise } from '../../api';
import { widgetClick } from '../../utils/analytics';

import './WidgetTicketCategories.scss';

const goToTickets = category => {
  widgetClick({
    link: `/my-organization/tickets?tab=resolved&ticket_category=${category}`,
    name: 'WidgetTicketCategories',
  });
};

const getChartData = ({ ticketCategories, totalCount }) => {
  if (!ticketCategories || typeof ticketCategories !== 'object') {
    return [];
  }
  const titles = Object.keys(ticketCategories);

  const colors = [
    '#577590',
    '#43AA8B',
    '#90BE6D',
    '#F9C74F',
    '#F8961E',
    '#F3722C',
  ];

  return titles.map((key, index) => ({
    name: key,
    value: ticketCategories[key],
    color: colors[index],
    key: `${key.toLowerCase()}-count`,
  }));
};

const centerTitle = 'Tickets';
const size = 170;
const innerRadius = '80%';
const startAngle = 90;
const endAngle = -270;
const legendBackground = '#FFFFFF';
const legendTitle = '';
const legendPosition = 'right';

const WidgetTicketCategories = ({ className, subtitle }) => {
  // fetch the data with the `usePromise` custom hook
  const { response, isLoading } = usePromise(Dashboard.ticketCategories);

  // parse out the data we want, if it has resolved
  const data = response?.body?.data;
  const total = data?.total_count;

  const chartData = data
    ? getChartData({
        ticketCategories: data.category_counts,
        totalCount: data.total_count,
      })
    : [];

  return (
    <DonutWidget
      title="Ticket Categories"
      subtitle="resolved in last 90 days"
      className="WidgetTicketCategories"
      style={{ width: '100%' }}
      isLoading={isLoading}
      legendPosition={legendPosition}
      data={chartData}
      dataKey="value"
      centerTitle={centerTitle}
      centerCount={numberWithCommas(total)}
      size={size}
      innerRadius={innerRadius}
      startAngle={startAngle}
      endAngle={endAngle}
      legendTitle={legendTitle}
      legendBackground={legendBackground}
      legendFormatter={({ name, value }) => (
        <button
          className="link primary"
          onClick={() => goToTickets(name)}>{`${name} (${numberWithCommas(
          value
        )})`}</button>
      )}
    />
  );
};

export default WidgetTicketCategories;
