import React from 'react';
import 'react-day-picker/lib/style.css';
import { BaseDateSelectorWithDropdowns } from '../';
import { format, parse, isAfter, isBefore } from 'date-fns';
import PropTypes from 'prop-types';
import params from '../../utils/queryParams';

class DateRangeSelectors extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: '',
      endDate: '',
      starProps: null,
      endProps: null,
    };
  }

  componentDidMount() {
    this.init();
    /*
    // If the component mounts and Redux has a date for it, add it back
    // to query params, otherwise it'll get wiped out.
    if (params.get('start')) {
      params.setSingle({
        start: format(params.get('start'), 'YYYY-MM-DD'),
      });
    } else {
      // if the redux has been cleared, make sure we dont have a qp hanging out still
      params.setSingle({ start: undefined });
    }

    if (params.get('end')) {
      params.setSingle({
        end: format(params.get('end'), 'YYYY-MM-DD'),
      });
    } else {
      params.setSingle({ end: undefined });
    }
  */
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.startCutoff !== this.props.startCutoff ||
      prevProps.endCutoff !== this.props.endCutoff
    ) {
      this.init();
    }
  }

  ensureDateIsWithinCutoffs = date => {
    let newDate = date;
    const { startCutoff, endCutoff } = this.props;
    // if the date is before the start cutoff, then set it equal to the cutoff
    if (startCutoff && isBefore(newDate, startCutoff)) {
      newDate = startCutoff;
    }
    // if the start date is after the end cutoff, then set it equal to the cutoff
    if (endCutoff && isAfter(newDate, endCutoff)) {
      newDate = endCutoff;
    }
    return newDate;
  };

  init = () => {
    const {
      defaultStartDate,
      defaultEndDate,
      startCutoff,
      endCutoff,
    } = this.props;

    // get the dates from params first, else from the default dates provided
    let startDate = params.get('start') || defaultStartDate;
    let endDate = params.get('end') || defaultEndDate;

    startDate = this.ensureDateIsWithinCutoffs(startDate);
    endDate = this.ensureDateIsWithinCutoffs(endDate);

    if (isBefore(endDate, startDate)) {
      const temp = startDate;
      startDate = endDate;
      endDate = temp;
    }

    // format dates to the correct format
    startDate = format(startDate, 'YYYY-MM-DD');
    endDate = format(endDate, 'YYYY-MM-DD');

    // set the url query params to match current selection
    params.setSingle({ start: startDate });
    params.setSingle({ end: endDate });

    // set the config for the date pickers
    const startProps = { disabledDays: { before: null, after: null } };
    const endProps = { disabledDays: { before: null, after: null } };

    // the start picker should be disabled before the start cutoff
    startProps.disabledDays.before =
      (startCutoff && parse(startCutoff)) || null;
    // start picker should be disabled after end cutoff
    startProps.disabledDays.after = (endCutoff && parse(endCutoff)) || null;

    // end picker should be disabled before the start cutoff
    endProps.disabledDays.before = (startCutoff && parse(startCutoff)) || null;
    // the end picker should be disabled after the end cutoff
    endProps.disabledDays.after = (endCutoff && parse(endCutoff)) || null;

    this.setState({
      startDate,
      endDate,
      startProps,
      endProps,
    });
  };

  handleStartDateChange = newDate => {
    const { endDate } = this.state;
    const newState = { startDate: newDate };
    // if the selected start date is after the current end date, update the end date to equal the new start date
    if (isAfter(newDate, endDate)) {
      newState.endDate = newDate;
    }
    this.setState(newState, () => {
      this.props.onDateChange('startDate', newDate);
      params.setSingle({ start: format(newDate, 'YYYY-MM-DD') });
      if (newState.endDate) {
        // if we updated end date, also update that query param
        params.setSingle({ end: format(newDate, 'YYYY-MM-DD') });
      }
    });
  };

  handleEndDateChange = newDate => {
    const { startDate } = this.state;
    const newState = { endDate: newDate };
    // if the selected end date is before the currently selected start date, update the start date to equal the end date
    if (isBefore(newDate, startDate)) {
      newState.startDate = newDate;
    }
    this.setState(newState, () => {
      this.props.onDateChange('endDate', newDate);
      params.setSingle({ end: format(newDate, 'YYYY-MM-DD') });
      if (newState.startDate) {
        // if we updated start date, also update that query param
        params.setSingle({ start: format(newDate, 'YYYY-MM-DD') });
      }
    });
  };

  render() {
    const { startDate, endDate } = this.state;
    return (
      <div className="DateRangeSelectors">
        {!!startDate && (
          <BaseDateSelectorWithDropdowns
            date={!!startDate ? startDate : new Date()}
            onDateChanged={this.handleStartDateChange}
            label="Choose a start date"
            className="start-date"
            disabled={this.props.disabled}
            hideOnDayClick={true}
          />
        )}
        {!!endDate && (
          <BaseDateSelectorWithDropdowns
            date={!!endDate ? endDate : new Date()}
            onDateChanged={this.handleEndDateChange}
            label="Choose an end date"
            className="end-date"
            disabled={this.props.disabled}
            hideOnDayClick={true}
          />
        )}
      </div>
    );
  }
}

DateRangeSelectors.propTypes = {
  defaultStartDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  defaultEndDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  startCutoff: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  endCutoff: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  disabled: PropTypes.bool,
};

export default DateRangeSelectors;
