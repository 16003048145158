import React from 'react';

import { NetworkEvents, ErrorHandler } from '../../api';
import { Widget } from '../Widget/Widget';

import { Interface } from '../Interfaces/Interfaces';

import './WidgetInternetUtilization.scss';

const colors = ['blue', 'green', 'red', 'orange', 'teal'];

export default class WidgetInternetUtilization extends React.Component {
  _isMounted = false;
  constructor() {
    super();

    this.state = {
      isLoading: false,
      interfaces: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });

    let res = {};
    try {
      res = await NetworkEvents.internetUtilizationDeviceInterface();
    } catch (err) {
      ErrorHandler.error(err);
    }

    let data = [];
    if (res && res.body) {
      data = res.body.data.map(d => ({
        device_id: d.device_id,
        interface_id: d.interface_id,
        interface_name: `${d.device_name} / ${d.interface_name}`,
        average_downstream_utilization_percentage:
          d.average_downstream_past_hour,
        average_upstream_utilization_percentage: d.average_upstream_past_hour,
        downstream_speed_mbps: d.downstream_speed_mbps,
        upstream_speed_mbps: d.upstream_speed_mbps,
      }));
    }
    // Sort by downstream DESC
    data = data.sort((a, b) => {
      return a.average_downstream_utilization_percentage >
        b.average_downstream_utilization_percentage
        ? -1
        : a.average_downstream_utilization_percentage <
          b.average_downstream_utilization_percentage
        ? 1
        : 0;
    });

    this.safeSetState({
      isLoading: false,
      interfaces: data.slice(0, 3),
    });
  };

  render() {
    const { isLoading, interfaces } = this.state;
    return (
      <Widget
        title="Internet Utilization"
        subtitle="in last hour"
        link="/network-events/internet-utilization"
        widgetName="WidgetInternetUtilization"
        isLoading={isLoading}
        isEmpty={!interfaces || !interfaces.length > 0}
        emptyStateMessage="No internet utilization data found."
        className="internet-utilization-widget height-2">
        <div className="interface-container">
          {interfaces.map((device, i) => (
            <Interface
              device={device}
              key={i}
              color={colors[i]}
              withHover={false}
            />
          ))}
        </div>
      </Widget>
    );
  }
}
