import React from 'react';
import ProgressBar from '../ProgressBar/ProgressBar';
import { Card } from '../';

import './WidgetProgressRows.scss';

export const WidgetProgressRows = ({
  className,
  mainValue,
  mainValueOnClick,
  mainLabel,
  mainValueColor,
  subLabel,
  rows,
  ...otherProps
}) => {
  return (
    <Card
      className={['WidgetProgressRows', className].filter(f => !!f).join(' ')}
      {...otherProps}>
      <div
        className={[
          'main-section',
          typeof mainValueOnClick === 'function' && 'clickable',
        ]
          .filter(f => !!f)
          .join(' ')}
        onClick={mainValueOnClick || null}>
        <span className={mainValueColor || ''}>{mainValue}</span>
        <h2>
          {mainLabel}
          <small>{subLabel}</small>
        </h2>
      </div>
      <div className="progress-bar-container">
        {rows?.map?.((row, i) => (
          <ProgressBar key={i} {...row} />
        ))}
      </div>
    </Card>
  );
};

export default WidgetProgressRows;
